import React, {useEffect, useMemo} from "react";

import {ActionResult, EducationSessionResult, Lesson, LessonInputMode, LessonMode, Maybe, Scalars} from "../../schema";
import {PlayableAction} from "../../redux/player/types";
import {Scenario} from "../../types";

import SessionUserInputActionDisplay from "./session-events/SessionUserInputActionDisplay";
import SessionAvatarActionDisplay from "./session-events/SessionAvatarActionDisplay";
import SessionSystemActionDisplay from "./session-events/SessionSystemActionDisplay";
import {gql} from "@apollo/client";
import {useAudio} from "../../providers/audio";
import {AudioPlayback} from "../../libs/audio";
import SessionRandomAvatarActionDisplay from "./session-events/SessionRandomAvatarActionDisplay";
import {useFormState} from "../../hooks/useFormState";
import classes from "./SessionActionsList.module.css";
import WithTooltip from "../../ui/tooltip";
import Select from "../../ui/select";
import {useTranslation} from "react-i18next";

type props = {
  actionsResults: EducationSessionResult["actionResults"],
  lesson: Pick<Lesson, "avatar" | "inputMode" | "mode">,
  actions: PlayableAction[],
  additionalScenarios?: Scenario[],
  finishedScenarios?: Maybe<Scalars["ID"]>[],
}

type formState = {
  scenarioId: string | undefined
}

const SessionActionsList = function (
  {actionsResults, lesson, actions, additionalScenarios, finishedScenarios}: props
) {
  const {t} = useTranslation();

  const indexedResults = useMemo(
    () => new Map(actionsResults.map(results => [results.actionId, results])),
    [actionsResults]
  )

  const indexedScenarios = useMemo(
    () => new Map(additionalScenarios?.map(scenario => [scenario.id, scenario.actions])),
    [additionalScenarios]
  )

  const mainScenario = useMemo(() => {
    return (
      SessionActionsListScenario(actions, indexedResults, lesson)
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, indexedResults, finishedScenarios, lesson])

  const {audioContext, audioManager} = useAudio();

  useEffect(() => {
    audioManager.installPlayback("results", new AudioPlayback(audioContext));
    return () => audioManager.uninstallPlayback("results");
  }, [audioContext, audioManager]);

  useEffect(() => () => {
    audioManager.getPlayback("results")?.abortPlay();
  }, [audioManager]);

  const formState = useFormState<formState>({
    initialValues: {
      scenarioId: ""
    },
    preventDefault: true,
  });

  return (
    <>
      {finishedScenarios && finishedScenarios.length > 0 && (
        <form className={classes.form}>
          <WithTooltip className={classes.help} as='span'
                        helpText={t("components.SessionActionsList.helpText")}>
            <Select name="scenarioId" value={formState.values.scenarioId ?? undefined}
                    onChange={formState.changeHandler}>
              <option key={"main"} value={""}>{t("components.SessionActionsList.mainScenario")}</option>

              {finishedScenarios.map((id) => (
                <option key={id} value={additionalScenarios?.find(scenario => scenario.id === id)!.id} translate="no">
                  {additionalScenarios?.find(scenario => scenario.id === id)!.name}
                </option>
              ))}
            </Select>
          </WithTooltip>
        </form>
      )}

      {(!finishedScenarios || formState.values.scenarioId === "") ? (
        <>{mainScenario}</>
      ) : (
        <>
          {SessionActionsListScenario(
            indexedScenarios.get(formState.values.scenarioId!)!,
            indexedResults, lesson
          )}
        </>
      )}
    </>
  )
}

const SessionActionsListScenario = function (
  actions: PlayableAction[],
  indexedResults: Map<string, ActionResult>,
  lesson: Pick<Lesson, "avatar" | "inputMode" | "mode">
) {
  return (
    <>
    {actions.map(action => {
      switch (action.__typename) {
        case "UserInputAction":
        case "ChoiceUserInputAction":
          if (!indexedResults.has(action.id)) {
            //throw new Error(`User input action (id: ${action.id}) has no corresponding result`)
            return <></>;
          }

          return (
            <SessionUserInputActionDisplay
              key={action.id}
              action={action}
              result={indexedResults.get(action.id)!}
              noSpeech={lesson.inputMode === LessonInputMode.TEXT}
              customScore={lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES}
            />
          )
        case "AvatarAction":
          if (indexedResults.has(action.id)) {
            throw new Error(`Avatar action (id: ${action.id}) has corresponding result`)
          }
          return (
            <SessionAvatarActionDisplay
              key={action.id}
              action={action}
              avatar={lesson.avatar}
            />
          )
        case "SystemAction":
          if (indexedResults.has(action.id)) {
            throw new Error(`System action (id: ${action.id}) has corresponding result`)
          }
          return (
            <SessionSystemActionDisplay
              key={action.id}
              action={action}
            />
          )
        case "RandomAvatarAction":
          if (!indexedResults.has(action.id)) {
            //throw new Error(`Random avatar action (id: ${action.id}) has no corresponding result`)
            return <></>;
          }
          return (
            <SessionRandomAvatarActionDisplay
              key={action.id}
              action={action}
              avatar={lesson.avatar}
              result={indexedResults.get(action.id)!}
            />
          )
        default:
          throw new Error(`Trying to show result for unsupported Action type: "${action.__typename}"`)
      }
    })}
  </>
  )
}

SessionActionsList.fragments = {
  root: gql`
    fragment SessionActionsList on Action {
      id

      ...SessionUserInputActionDisplay
      ...SessionRandomAvatarActionDisplay
      ...SessionAvatarActionDisplay
      ...SessionSystemActionDisplay
    }

    ${SessionUserInputActionDisplay.fragments.root}
    ${SessionRandomAvatarActionDisplay.fragments.root}
    ${SessionAvatarActionDisplay.fragments.root}
    ${SessionSystemActionDisplay.fragments.root}
  `,

  actionsResults: gql`
    fragment SessionActionsListActionResult on ActionResult {
      actionId
      ...SessionUserInputActionDisplayActionResult
      ...SessionRandomAvatarDisplayActionResult
    }

    ${SessionUserInputActionDisplay.fragments.actionResult}
    ${SessionRandomAvatarActionDisplay.fragments.actionResult}
  `
}

export default SessionActionsList;
