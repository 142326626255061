/* eslint-disable max-lines */
import React, {ChangeEventHandler, ComponentProps, useCallback, useMemo} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../hooks/useFormState";
import useVisibility from "../../hooks/useVisibility";

import {Course, EducationMemberFilterInput, Group, Lesson} from "../../schema";
import {BooleanForm, booleanFormEnumToValue, booleanFormValueToEnum} from "../../utils";
import without from "lodash/without";

import {HelpCircleIcon} from "../../ui/icons";
import Input from "../../ui/input";
import Select from "../../ui/select";
import WithTooltip from "../../ui/tooltip";
import Checkbox from "../../ui/checkbox";
import Link from "../../ui/link";

import classes from "./MembersFilterForm.module.css";

export type QueryData = {
  lessons: Pick<Lesson, "id" | "name">[],
  courses: Pick<Course, "id" | "name">[],
  groups: Pick<Group, "id" | "name">[]
}

type Props = Omit<ComponentProps<"form">, "children" | "onSubmit"> & {
  lessons?: QueryData["lessons"],
  courses?: QueryData["courses"],
  groups?: QueryData["groups"],
  initialValues?: EducationMemberFilterInput,
  onSave: (data: EducationMemberFilterInput) => void,
}

type formState = {
  email: EducationMemberFilterInput["email"],
  username: EducationMemberFilterInput["username"],
  active: BooleanForm,
  lessonId: EducationMemberFilterInput["lessonId"],
  courseId: EducationMemberFilterInput["courseId"],
  groupsIds: NonNullable<EducationMemberFilterInput["groupsIds"]>,
  completed: BooleanForm,
}

export default function MembersFilterForm({initialValues, lessons, courses, groups, onSave, ...props}: Props) {
  const {t} = useTranslation();

  const [allGroupsVisible, showAllGroups, showLessGroups] = useVisibility();

  const formState = useFormState<formState>({
    initialValues: {
      email: initialValues?.email,
      username: initialValues?.username,
      active: booleanFormValueToEnum(initialValues?.active ?? undefined),
      lessonId: initialValues?.lessonId,
      courseId: initialValues?.courseId,
      groupsIds: initialValues?.groupsIds ?? [],
      completed: booleanFormValueToEnum(initialValues?.completed ?? undefined),
    },
    preventDefault: true,
    onChange: (data) => {
      onSave && onSave({
        ...data,
        active: booleanFormEnumToValue(data.active),
        completed: booleanFormEnumToValue(data.completed),
        lessonId: data.lessonId === "0" ? undefined : data.lessonId,
        courseId: data.courseId === "0" ? undefined : data.courseId,
      })
    }
  });

  const onGroupSelect = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const groupId = e.currentTarget.getAttribute("data-group-id")!;
    const checked = e.currentTarget.checked;
    formState.setValues((values) => ({
      groupsIds: checked
        ? values.groupsIds.concat(groupId)
        : without(values.groupsIds, groupId)
    }))
  }, [formState])

  const selectedGroups = useMemo(() => {
    if (!groups) {
      return;
    }
    if (!allGroupsVisible && groups.length > 5) {
      return groups.slice(0, 5)
    }
    return groups;
  }, [allGroupsVisible, groups])

  return (
    <form method="post" onSubmit={formState.submitHandler} {...props}>
      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.email.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.email.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.email.helpText")}>
          <Input
            name="email"
            displaySize="sm"
            placeholder='example@gmail.com'
            value={formState.values.email ?? undefined}
            onChange={formState.changeHandler}
          />
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.username.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.username.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.username.helpText")}>
          <Input
            name="username"
            displaySize="sm"
            placeholder='John Smith'
            value={formState.values.username ?? undefined}
            onChange={formState.changeHandler}
          />
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.active.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.active.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.active.helpText")}>
          <Select name="active" value={formState.values.active ?? BooleanForm.UNDEFINED}
                  onChange={formState.changeHandler}>
            <option value={BooleanForm.UNDEFINED}>
              {t("components.MemberRowFilterForm.active.no")}
            </option>
            <option value={BooleanForm.TRUE}>
              {t("components.MemberRowFilterForm.active.true")}
            </option>
            <option value={BooleanForm.FALSE}>
              {t("components.MemberRowFilterForm.active.false")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.lesson.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.lesson.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.lesson.helpText")}>
          <Select name="lessonId" value={formState.values.lessonId ?? undefined}
                  onChange={formState.changeHandler}>
            <option value={0}>{t("components.MemberRowFilterForm.lesson.no")}</option>

            {lessons && lessons.map((lesson) => (
              <option key={lesson.id} value={lesson.id} translate="no">
                {lesson.name}
              </option>
            ))}
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.course.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.MemberRowFilterForm.course.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                      helpText={t("components.MemberRowFilterForm.course.helpText")}>
          <Select name="courseId" value={formState.values.courseId ?? undefined}
                  onChange={formState.changeHandler}>
            <option value={0}>{t("components.MemberRowFilterForm.course.no")}</option>

            {courses && courses.map((course) => (
              <option key={course.id} value={course.id} translate="no">
                {course.name}
              </option>
            ))}
          </Select>
        </WithTooltip>
      </div>

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.MemberRowFilterForm.completed.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.completed.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.MemberRowFilterForm.completed.helpText")}>
          <Select name="completed" value={formState.values.completed ?? BooleanForm.UNDEFINED}
                  onChange={formState.changeHandler}>
            <option value={BooleanForm.UNDEFINED}>
              {t("components.MemberRowFilterForm.completed.no")}
            </option>
            <option value={BooleanForm.TRUE}>
              {t("components.MemberRowFilterForm.completed.true")}
            </option>
            <option value={BooleanForm.FALSE}>
              {t("components.MemberRowFilterForm.completed.false")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      {groups && groups?.length > 0 && (
        <div className={classes.formItem}>
          <label className={classes.label}>
            {t("components.MemberRowFilterForm.groups.label")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.MemberRowFilterForm.groups.helpText")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>
          {selectedGroups?.map(({name, id}) => (
            <label key={id} className={classes.checkboxLabel}>
              <Checkbox
                checked={formState.values.groupsIds.includes(id)}
                onChange={onGroupSelect}
                data-group-id={id}
              />
              <span className={classes.checkboxText}>{name}</span>
            </label>
          ))}
          {groups && groups.length > 5 && (
            allGroupsVisible ? (
              <Link onClick={showLessGroups}>{t("components.MemberRowFilterForm.groups.showLess")}</Link>
            ) : (
              <Link onClick={showAllGroups}>{t("components.MemberRowFilterForm.groups.showAll")}</Link>
            )
          )}
        </div>
      )}
    </form>
  )
}

MembersFilterForm.fragments = {
  lessons: gql`
    fragment MembersFilterFormLessons on Lesson {
      id
      name
    }
  `,
  courses: gql`
    fragment MembersFilterFormCourses on Course {
      id
      name
    }
  `,
  groups: gql`
    fragment MembersFilterFormGroups on Group {
      id
      name
    }
  `
}
