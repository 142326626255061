/* eslint-disable max-lines */
import {gql, useMutation} from "@apollo/client";
import AccountInfo, {QueryData as AccountInfoQueryData} from "../../components/account/AccountInfo";
import MembersFilterForm from "../../components/account/MembersFilterForm";
import MembersTableTable from "../../components/account/members-table/MembersTable.table";

import {
  Course,
  EditorAccessType,
  EducationMembersPaginatedList,
  Group,
  Lesson,
  LessonParticipationSummary,
  MembershipInput,
  MutationAddParticipantsArgs,
  MutationAddUsersArgs,
  MutationArchiveParticipationArgs,
  MutationImportMembersCsvArgs,
  MutationSetExtraEmailArgs,
  MutationSetReportsReceiveArgs,
  MutationSetUsersActivityArgs,
  MutationUpdateParticipationArgs,
  Participation,
  QueryGetAccountMembersArgs,
  Scalars,
  User
} from "../../schema";


export type PickedLesson = Pick<Lesson, "__typename" | "id" | "name">
export type PickedCourse = Pick<Course, "__typename" | "id" | "name"> & {
  lessons: Pick<Lesson, "id">[]
}

export type QueryData = AccountInfoQueryData & {
  users: User[] | undefined,
  educationItems: (PickedLesson | PickedCourse)[],
  flatLessons: (PickedLesson | PickedCourse)[],
  groups: (Pick<Group, "id" | "name"> & {
    users: Pick<User, "id">[]
  })[]
  participationsExportUrl: string,
  usersExportUrl: string,
  editorAccessType: EditorAccessType,
}

export type PaginatedListQueryVars = Omit<QueryGetAccountMembersArgs, "skip" | "first"> & {
  offset: QueryGetAccountMembersArgs["skip"],
  limit: QueryGetAccountMembersArgs["first"],
}

export type PaginatedListQueryData = {
  paginatedList: EducationMembersPaginatedList
}

export type ParticipationsSummaryQueryData = {
  participationsSummary: LessonParticipationSummary
}

export type UsersExportUrlWithFiltersQueryData = {
  usersExportUrlWithFilters: string
}

/* eslint-disable max-len */
const query = gql`
  query GetAccountMembersDataQuery {
    membersSummary: getAccountMembersSummary {
      __typename
      ...AccountInfoEducationMembersSummary
    }

    educationItems: getLessons {
      __typename

      ... on Lesson {
        ...MembersTableLessons
      }

      ... on Course {
        ...MembersTableCourses
        ...MembersFilterFormCourses
      }
    }

    flatLessons: getLessons (flatMode: true) {
      __typename

      ... on Lesson {
        ...MembersFilterFormLessons
      }
    }
    
    contracts: getAccountContracts {
      __typename
      ...AccountInfoContract
    }
    
    account: getCurrentAccount {
      __typename
      id
      ...AccountInfoAccount
    }

    editorAccessType: getEditorAccessType

    users: getCurrentAccountUsers {
        id
        fullName
        name
        email
        groups {
          id
          name
        }
    }
    
    groups: getGroups {
      id
      name
      users {
        id
      }
      ...MembersFilterFormGroups
    }

    participationsExportUrl: getAccountParticipationsExportUrl

    usersExportUrl: getAccountUsersExportUrl
  }

  ${AccountInfo.fragments.membersSummary}
  ${MembersTableTable.fragments.courses}
  ${MembersTableTable.fragments.lessons}
  ${MembersFilterForm.fragments.courses}
  ${MembersFilterForm.fragments.lessons}
  ${MembersFilterForm.fragments.groups}
  ${AccountInfo.fragments.contract}
  ${AccountInfo.fragments.account}
`;
/* eslint-enable max-len */

export const paginatedListQuery = gql`
  query GetAccountMembersQuery($limit: Int!, $offset: Int, $filter: EducationMemberFilterInput) {
    paginatedList: getAccountMembers(first: $limit, skip: $offset, filter: $filter) {
      items {
        id
        __typename

        ...MembersTable
      }

      countItems
    }
  }

  ${MembersTableTable.fragments.root}
`;

export const participationsSummaryQuery = gql`
  query GetParticipationsSummaryQuery($lessonId: ID, $courseId: ID, $groupsIds: [ID!]) {
    participationsSummary: getParticipationsSummary(lessonId: $lessonId, courseId: $courseId, groupsIds: $groupsIds) {
      __typename
      assignments
      started
      finished
      passed
    }
  }
`;

export const usersExportUrlWithFiltersQuery = gql`
  query GetUsersExportUrlWithFiltersQuery($filter: EducationMemberFilterInput) {
    usersExportUrlWithFilters: getAccountUsersExportUrl(filter: $filter)
  }
`;

export function useUpdateMembershipMutation() {
  return useMutation<{
    user: User
  }, {
    data: MembershipInput;
    userId: Scalars["ID"];
  }>(updateMembershipMutation);
}

export function useUpdateParticipationMutation() {
  return useMutation<{
    participation: Participation
  }, MutationUpdateParticipationArgs>(updateParticipationMutation);
}

export function useArchiveParticipationMutation() {
  return useMutation<{
    participation: Participation
  }, MutationArchiveParticipationArgs>(archiveParticipationMutation);
}

export function useSetReportsReceiveMutation() {
  return useMutation<{success: boolean}, MutationSetReportsReceiveArgs>(setReportsReceiveMutation);
}

export function useSetExtraEmailMutation() {
  return useMutation<{success: boolean}, MutationSetExtraEmailArgs>(setExtraEmailMutation);
}

const setReportsReceiveMutation = gql`
  mutation SetReportsReceiveMutation($status: Boolean!) {
    success: setReportsReceive(status: $status)
  }
`;

const setExtraEmailMutation = gql`
  mutation SetExtraEmailMutation($email: String!) {
    success: setExtraEmail(email: $email)
  }
`;

const updateMembershipMutation = gql`
  mutation UpdateMembershipMutation($userId: ID!, $data: MembershipInput!) {
    user: updateMembership(userId: $userId, data: $data) {
      id
      hasActiveMembership
    }
  }
`;


const updateParticipationMutation = gql`
  mutation UpdateParticipationMutation(
    $userId: ID, $emails: [String!], $status: Boolean!,$lessonId: ID, $courseId: ID) {
    participations: updateParticipation(
      userId: $userId, emails: $emails, lessonId: $lessonId, courseId: $courseId, status: $status) {
        id
        isActive
      }
    }
`;

const archiveParticipationMutation = gql`
  mutation ArchiveParticipationMutation($userId: ID, $emails: [String!], $lessonId: ID, $courseId: ID) {
    participations: archiveParticipation(userId: $userId, emails: $emails, lessonId: $lessonId, courseId: $courseId) {
      id
    }
  }
`;

export function useAddUsersMutation() {
  return useMutation<{
    users: User[]
  }, MutationAddUsersArgs>(addUsersMutation);
}

export function useSetUsersActivityMutation() {
  return useMutation<{
    users: User[]
  }, MutationSetUsersActivityArgs>(setUsersActivityMutation);
}

export function useInviteParticipantsMutation() {
  return useMutation<{
    participations: Participation[]
  }, MutationAddParticipantsArgs>(inviteParticipantsMutation);
}

export function useImportMembersCsvMutation() {
  return useMutation<{result: string}, MutationImportMembersCsvArgs>(importMembersCsvMutation);
}

const inviteParticipantsMutation = gql`
  mutation InviteParticipantsMutation($lessonId: ID, $courseId: ID, $usersIds: [ID!], $emails: [String!]) {
    participations: addParticipants(lessonId: $lessonId, courseId: $courseId, usersIds: $usersIds, emails: $emails) {
      id

      student {
        id
        name
        fullName
        email
      }
    }
  }
`;

const addUsersMutation = gql`
  mutation AddUsersMutation($emails: [String!]!, $names: [String!]!, $languageCode: LanguageCode) {
    members: addUsers(emails: $emails, names: $names, languageCode: $languageCode) {
      id
      name
      fullName
      email
    }
  }
`;

const setUsersActivityMutation = gql`
  mutation SetUsersActivityMutation($emails: [String!]! $isActive: Boolean!) {
    members: setUsersActivity(emails: $emails, isActive: $isActive) {
      id
      name
      fullName
      email
      hasActiveMembership
    }
  }
`;

const importMembersCsvMutation= gql`
  mutation ImportMembersCsvMutation(
    $attachment: Upload!, $sendWelcomeEmails: Boolean!, $overrideActive: Boolean!, $emailLanguage: LanguageCode!) {
    result: importMembersCsv(
      attachment: $attachment, sendWelcomeEmails: $sendWelcomeEmails,
      overrideActive: $overrideActive, emailLanguage: $emailLanguage
    )
  }
`

export default query;