import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {reverse} from "../../routing";
import {useQuery} from "@apollo/client";
import {useModal} from "../../components/ModalProvider";
import {useCurrentUser} from "../../App.context";
import query, {lessonQuery, useRecalculateScoreMutation} from "./results.graphql";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import ReactLink from "../../components/ReactLink";
import {EducationSessionRecordState, EducationSessionResult, Lesson, LessonMode, User} from "../../schema";
import {Scenario} from "../../types";
import SessionActionsList from "../../components/results/SessionActionsList";
import {CheckmarkIcon, CrossIcon, PlayIcon} from "../../ui/icons";
import FullscreenVideo from "../../ui/fullscreenvideo";
import RecordLink from "../../components/RecordLink";
import Link from "../../ui/link";

import {formatDuration} from "../../time";
import {PlayableAction} from "../../redux/player/types";

import classes from "./results.module.css";
import cn from "classnames";

export default function ResultsScene() {
  const {t} = useTranslation();
  const isDeveloper = !!useCurrentUser()?.isDeveloper;

  const [videoSrc, setVideoSrc] = useState<string>()

  const resetVideoSrc = useCallback(() => setVideoSrc(undefined), [])

  const onPlayButtonClick = useCallback((e: React.MouseEvent) => {
    const src = e.currentTarget.getAttribute("data-id");

    setVideoSrc(src ?? undefined)
  }, [])

  const {lessonId} = useParams<{lessonId: Lesson["id"]}>();
  const {userId} = useParams<{userId: User["id"]}>();

  const {data: lessonData} = useQuery<{ lesson: Lesson, actions: PlayableAction[], additionalScenarios: Scenario[] }>(
    lessonQuery,
    {
      variables: {
        lessonId
      }
    }
  );

  const {data} = useQuery<{ results: EducationSessionResult[] }>(
    query,
    {
      variables: {
        lessonId,
        userId
      },
      pollInterval: 2000,
    }
  );

  const {lesson, actions, additionalScenarios} = lessonData ?? {};
  const results = data?.results;

  const {add: addModal} = useModal();
  const [recalculateScoreMutation] = useRecalculateScoreMutation();

  const recalculateScore = useCallback((e: React.MouseEvent) => {
    const sessionId = e.currentTarget.getAttribute("data-id");
    e.preventDefault();
    recalculateScoreMutation({
      variables: {
        sessionId: sessionId!
      }
    })
  }, [recalculateScoreMutation]);

  const showDetailedResults = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    const index = e.currentTarget.getAttribute("data-id");
    if (!index || !results) {
      return
    }
    const result = results[parseInt(index)]

    if (!lesson || !actions || !lesson.showDetailedResults) {
      return;
    }

    addModal({
      className: cn(classes.modal, classes.textSelectable),
      size: "xl",
      header: <span>{t("player.results.detailed")}</span>,
      content: (
        <div className={classes.actionsListContainer}>
          {lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST && (
            <div className={classes.customParams}>
              <b className={classes.customParameter}>{t("player.results.customParameters")}</b>
              {result.parametricResults.map((pr) => (
                <span className={classes.customParameter}>{pr.parameterName}: {pr.score}</span>
              ))}
            </div>
            )}

          <SessionActionsList
            lesson={lesson}
            actionsResults={result.actionResults}
            actions={actions}
            additionalScenarios={additionalScenarios}
            finishedScenarios={result.finishedScenariosIds}
          />
        </div>
      )
    })
  }, [lesson, results, actions, additionalScenarios, addModal, t]);

  return (
    <>
      <ReactDocumentTitle title={t("results.title")}/>

      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <ReactLink href={reverse("player")}>{t("breadcrumbs.playerIndex")}</ReactLink>
              <span>{lesson?.name ?? t("breadcrumbs.lesson")}</span>
              <span>{t("breadcrumbs.results")}</span>
            </Breadcrumbs>
            <h1 className={classes.h1}>{t("results.results")}</h1>
            <table className={classes.tableRoot} cellPadding={0} cellSpacing={0}>
              <thead>
              <tr>
                <td className={classes.value}>{t("results.result")}</td>
                <td className={classes.value}>{t("results.time")}</td>
                <td className={classes.value}>{t("results.hints")}</td>
                <td className={classes.value}>{t("results.detailed")}</td>
                <td className={cn(classes.value, classes.centerAlign)}>{t("results.record")}</td>
              </tr>
              </thead>
              <tbody>
              {results && results.map((item, index) => (
                <tr key={item.sessionId}>
                  <td className={classes.value}>
                    <div className={classes.valueInner}>
                      {item.passed ? (
                        <CheckmarkIcon className={cn(classes.icon, classes.passed)}/>
                      ) : (
                        <CrossIcon className={cn(classes.icon, classes.failed)}/>
                      )}

                      <span className={classes.score}>
                      {item.score}
                      </span>
                    </div>
                  </td>
                  <td className={classes.value}>{formatDuration(item.time)}</td>
                  <td className={classes.value}>{item.usedHints}</td>
                  <td className={cn(classes.value, classes.extraWide)}>
                    {item.isContentMatches && lesson?.showDetailedResults ? (
                      <Link
                        data-id={index}
                        disabled={!item.isContentMatches}
                        onClick={showDetailedResults}
                      >
                        {t("results.showDetailed")}
                      </Link>
                    ) : (
                      <span className={classes.noDetails}>
                        {!lesson?.showDetailedResults ? t("results.noDetailedResult") : t("results.lessonChanged")}
                      </span>
                    )}
                  </td>
                  <td className={cn(classes.value, classes.centerAlign)}>
                    <RecordLink
                      className={classes.recordButton}
                      as={Link}
                      data-id={item.record}
                      disabled={item.recordState !== EducationSessionRecordState.READY}
                      onClick={onPlayButtonClick}
                      state={item.recordState}
                      textVariant="short"
                    >
                      {(item.recordState === EducationSessionRecordState.READY) && (
                        <PlayIcon className={classes.playIcon}/>
                      )}
                    </RecordLink>
                  </td>
                  {isDeveloper && lesson?.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES && (
                    <td className={classes.value}>
                      <Link
                        data-id={item.sessionId}
                        onClick={recalculateScore}
                      >
                        {"Recalculate"}
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
              {!results?.length && (
                <tr>
                  <td colSpan={10}>{t("results.empty")}</td>
                </tr>
              )}
              </tbody>
            </table>
            {videoSrc && <FullscreenVideo onClose={resetVideoSrc} src={videoSrc}/>}
          </Container>
        </Main>
      </Layout>
    </>
  )
}
