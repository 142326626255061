import React, {ComponentProps, useMemo} from "react";
import {useFormState} from "../../../hooks/useFormState";
import {useTranslation} from "react-i18next";
import {Group} from "../../../schema";

import Input from "../../../ui/input";
import Button from "../../../ui/button";

import classes from "./RenameGroupModal.module.css";

export type QueryData = {
  group: Pick<Group, "name" | "code">
  groups?: Pick<Group, "name" | "code">[]
}

export type ContentProps = Omit<ComponentProps<"form">, "children" | "onSubmit"> & QueryData & {
  onRenameGroup?: (name: string, code: string) => void
}

function RenameGroupModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.RenameGroupModal.title")}</span>
}

 function RenameGroupModalContent({group, groups, onRenameGroup, ...props}: ContentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    groupName: string,
    groupCode: string,
  }>({
    initialValues: {
      groupName: group.name,
      groupCode: group.code ?? "",
    },

    preventDefault: true,
    onSubmit: ({groupName, groupCode}) => {
      onRenameGroup && onRenameGroup(groupName, groupCode);
    }
  });

  const nameChanged = formState.values.groupName !== group.name;
  const codeChanged = formState.values.groupCode !== group.code;

  const haveGroupWithSameName = useMemo(() => {
    if (!groups) {
      return undefined;
    }
    return groups.some(({name}) => formState.values.groupName === name)
  }, [groups, formState.values.groupName]);

  const haveGroupWithSameCode = useMemo(() => {
    if (!groups) {
      return undefined;
    }
    return groups.some(({code}) => formState.values.groupCode === code)
  }, [groups, formState.values.groupCode]);

  const submitDisabledByName = haveGroupWithSameName || !nameChanged;
  const submitDisabledByCode = haveGroupWithSameCode || !codeChanged;

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler} {...props}>
      <p className={classes.helpText}>{t("components.RenameGroupModal.helpText", {name: group.name})}</p>
      <Input
        className={classes.input}
        name="groupName"
        required
        value={formState.values.groupName}
        placeholder={t("components.RenameGroupModal.placeholder")}
        onChange={formState.changeHandler}
      />

      <p className={classes.helpText}>{t("components.RenameGroupModal.codeHelpText")}</p>
      <Input
        className={classes.input}
        name="groupCode"
        value={formState.values.groupCode}
        placeholder={t("components.RenameGroupModal.codePlaceholder")}
        onChange={formState.changeHandler}
      />

      {nameChanged && haveGroupWithSameName && (
        <p className={classes.warning}>{t("components.RenameGroupModal.warning.sameName")}</p>
      )}

      {codeChanged && haveGroupWithSameCode && (
        <p className={classes.warning}>{t("components.RenameGroupModal.warning.sameCodename")}</p>
      )}

      <div className={classes.bottom}>
        <Button type="submit" color="success" disabledColor="secondary"
          disabled={submitDisabledByName && submitDisabledByCode}>
          {t("components.RenameGroupModal.submit")}
        </Button>
      </div>
    </form>
  )
}

const RenameGroupModal = {
  Header: RenameGroupModalHeader,
  Content: RenameGroupModalContent,
}

export default RenameGroupModal;
