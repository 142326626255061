import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router";
import {useCurrentUser} from "../../App.context";
import {reverse} from "../../routing";

import classes from "./NavigationMenu.module.css";
import Button from "../../ui/button";
import ReactLink from "../ReactLink";
import {useEffect, useState} from "react";
import cn from "classnames"

type props = {
  className?: string
}


export default function NavigationMenu({className}: props) {
  const {t} = useTranslation();
  const dev = useCurrentUser()?.isDeveloper;
  const path = useRouteMatch().path;

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (path.startsWith(reverse("serviceConversationsList"))) {
      setActiveTab("conversation");
    } else if (path.startsWith(reverse("serviceAudios"))) {
      setActiveTab("audios");
    } else if (path.startsWith(reverse("serviceDictionaries"))) {
      setActiveTab("dicts");
    } else if (path.startsWith(reverse("serviceDivisions"))) {
      setActiveTab("divisions");
    } else if (path.startsWith(reverse("serviceDevices"))) {
      setActiveTab("devices");
    } else if (path.startsWith(reverse("serviceAnalytics"))) {
      setActiveTab("analytics");
    } else if (path.startsWith(reverse("serviceDevicesDev"))) {
      setActiveTab("devicesDev");
    } else if (path.startsWith(reverse("service"))) {
      setActiveTab("service");
    }
  }, [path])


  return (
    <div className={cn(classes.contentAside, className)}>
      <Button
        className={classes.menuButton}
        color={activeTab === "service" ? "brand" : "secondary"}
        as={ReactLink}
        href={reverse("service")}
      >
        {t("service.main.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "conversation" ? "brand" : "secondary"}
        as={ReactLink}
        href={reverse("serviceConversationsList")}
      >
        {t("service.conversations.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "audios" ? "brand" : "secondary"}
        as={ReactLink}
        href={reverse("serviceAudios")}
      >
        {t("service.audios.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "dicts" ? "brand" : "secondary"}
        as={ReactLink}
        href={reverse("serviceDictionaries")}
      >
        {t("service.dictionaries.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "devices" ? "brand" : "secondary"}
        as={ReactLink}
        href={reverse("serviceDevices")}
      >
        {t("service.devices.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "divisions" ? "brand" : "secondary"}
        disabled
        href={reverse("serviceDivisions")}
      >
        {t("service.divisions.title")}
      </Button>
      <Button
        className={classes.menuButton}
        color={activeTab === "analytics" ? "brand" : "secondary"}
        disabled
        href={reverse("serviceAnalytics")}
      >
        {t("service.analytics.title")}
      </Button>
      {dev && (
        <Button
          className={classes.menuButton}
          color={activeTab === "devicesDev" ? "brand" : "secondary"}
          as={ReactLink}
          href={reverse("serviceDevicesDev")}
        >
          {t("service.devicesDev.title")}
        </Button>
      )}
    </div>
  )
}
