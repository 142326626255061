import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router";
import {path, reverse} from "../routing";

import PageNotFoundScene from "./system/page-not-found";
import {useCurrentAccount, useCurrentUser} from "../App.context";
import ServiceScene from "./service/index";
import AudiosScene from "./service/audios";
import DivisionsScene from "./service/divisions";
import DevicesScene from "./service/devices";
import AnalyticsScene from "./service/analytics";
import DevicesDevScene from "./service/devices-dev";
import ConversationsListScene from "./service/conversations-list";
import ConversationScene from "./service/conversation";
import DictionariesScene from "./service/dictionaries";


const ServiceBundle = React.memo(function () {
  const history = useHistory();
  const userId = useCurrentUser()?.id;
  const accountId = useCurrentAccount()?.id;

  useEffect(() => {
    if (!userId || !accountId) {
      history.push(reverse("login") + "?_next=" + encodeURIComponent(history.location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  if (!userId || !accountId) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path("service")} component={ServiceScene}/>
      <Route exact path={path("serviceConversationsList")} component={ConversationsListScene}/>
      <Route exact path={path("serviceConversation")} component={ConversationScene}/>
      <Route exact path={path("serviceAudios")} component={AudiosScene}/>
      <Route exact path={path("serviceDictionaries")} component={DictionariesScene}/>
      <Route exact path={path("serviceDivisions")} component={DivisionsScene}/>
      <Route exact path={path("serviceDevices")} component={DevicesScene}/>
      <Route exact path={path("serviceAnalytics")} component={AnalyticsScene}/>
      <Route exact path={path("serviceDevicesDev")} component={DevicesDevScene}/>

      <Route component={PageNotFoundScene}/>
    </Switch>
  )
})

export default ServiceBundle;
