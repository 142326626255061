import React, {ComponentProps, ComponentType, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useCurrentAccount, useCurrentUser} from "../App.context";
import {useRouteMatch} from "react-router";
import {useTour} from "../providers/tour";
import {Editor, Student} from "../schema";
import {runTourOnFirstOpen} from "../tours";

import Logo from "../ui/logo";
import Container from "../ui/container";
import AContainer from "../ui/adaptivecontainer";

import {reverse} from "../routing";
import ReactLink from "./ReactLink";
import LangSelector from "./LangSelector";
import ProfileDropdown from "./ProfileDropdown";

import cn from "classnames";
import classes from "./Layout.module.css";
import LayoutTour, {
  classes as tourClasses,
  NAME as tourName,
  SUBNAMES as subToursNames
} from "./Layout.tour";

export default function Layout(props: ComponentProps<"div"> & {player?: boolean}) {
  const {runningTourName} = useTour();

  const tourMode = !!runningTourName && [tourName, ...Object.values(subToursNames)].includes(runningTourName);

  return (
    <>
      {tourMode && <LayoutTour/>}
      <LayoutDisplay {...props}/>
    </>
  )
}

function LayoutDisplay({className, player, ...props}: ComponentProps<"div"> & {player?: boolean}) {
  return (
    <div className={cn(classes.root, {[classes.player]: player}, className)} {...props}/>
  )
}

Layout.Display = LayoutDisplay;

type HeaderCommonProps = ComponentProps<"header"> & {
  type?: "transparent" | "shaded" | "filled",
  adaptive?: boolean,
  extra?: React.ReactNode,
  disableHomeButton?: boolean,
}

type HeaderProps = HeaderCommonProps & {
  hideLinks?: boolean,
}

type HeaderExProps = HeaderProps & {
  userType?: (Editor | Student)["__typename"],
  isDeveloper?: boolean,
  isStaff?: boolean,
  authenticated?: boolean,
  activeTab?: "editor" | "player" | "accountMembers" | "analytics" | "service" | null
  onLogout?: () => void
}

type HeaderType = ComponentType<HeaderProps> & {Display: ComponentType<HeaderCommonProps>}

const Header: HeaderType = React.memo(function (props: HeaderProps) {
  const user = useCurrentUser();
  const path = useRouteMatch().path;

  let activeTab: HeaderExProps["activeTab"] = null;

  if (path.startsWith(reverse("editor"))) {
    activeTab = "editor";
  } else if (path.startsWith(reverse("player"))) {
    activeTab = "player";
  } else if (path.startsWith(reverse("accountMembers"))) {
    activeTab = "accountMembers";
  } else if (path.startsWith(reverse("analytics"))) {
    activeTab = "analytics";
  } else if (path.startsWith(reverse("service"))) {
    activeTab = "service";
  }
  return (
    <HeaderEx
      userType={user?.__typename}
      isDeveloper={user?.isDeveloper}
      isStaff={user?.isStaff}
      authenticated={!!user}
      activeTab={activeTab}
      {...props}
    />
  )
}) as unknown as HeaderType;

export const HeaderEx: React.ComponentType<HeaderExProps> = React.memo(function ({
  userType, isStaff, isDeveloper, authenticated, activeTab, extra,
  type, adaptive, hideLinks, disableHomeButton,
  className, ...props
}: HeaderExProps) {
  const {t} = useTranslation();
  const serviceSupport = useCurrentAccount()?.serviceSupport;

  const HeaderContainer = adaptive ? AContainer : Container;

  const {bindToPage: bindTourToPage, run: runTour} = useTour();

  useEffect(() => bindTourToPage(undefined, subToursNames.header), [bindTourToPage]);

  useEffect(() => {
    if (userType === "Editor") {
      runTourOnFirstOpen(subToursNames.header, runTour)
    }
  }, [userType, runTour])

  return (
    <header className={cn(classes.header, {
      [classes.headerTransparent]: type === "transparent",
      [classes.headerShaded]: type === "shaded",
      [classes.adaptive]: adaptive,
    }, tourClasses.header, className)} {...props}>
      <HeaderContainer className={classes.headerContainer}>
        <div className={classes.headerLeft}>
          <ReactLink
            className={cn(classes.logoLink, {[classes.logoMobile]: !hideLinks}, tourClasses.home)}
            href={!disableHomeButton ? reverse("home") : undefined}
          >
            <Logo className={cn(classes.logo)} variant={"white"}/>
          </ReactLink>

          {userType === "Editor" && !hideLinks && (
            <nav className={classes.headerLeftMenu}>
              <ReactLink
                href={reverse("editor")}
                className={cn(tourClasses.editor, {[classes.active]: activeTab === "editor"})}
              >{t("components.Header.editor")}</ReactLink>
              <ReactLink
                href={reverse("player")}
                className={cn(tourClasses.player, {[classes.active]: activeTab === "player"})}
              >{t("components.Header.player")}</ReactLink>
              <ReactLink
                href={reverse("accountMembers")}
                className={cn(tourClasses.members, {[classes.active]: activeTab === "accountMembers"})}
              >{t("components.Header.users")}</ReactLink>
              <ReactLink
                href={reverse("analytics")}
                className={activeTab === "analytics" ? classes.active : undefined}
              >{t("components.Header.analytics")}</ReactLink>
              {serviceSupport && (
                <ReactLink
                  href={reverse("service")}
                  className={activeTab === "service" ? classes.active : undefined}
                >{t("components.Header.service")}</ReactLink>
              )}
            </nav>
          )}
        </div>
        <div className={classes.headerRightMenu}>
          {extra}

          <LangSelector className={cn(classes.headerRightLangSelector, tourClasses.langSelector)}/>
          <ProfileDropdown adaptive={adaptive}/>
        </div>
      </HeaderContainer>
    </header>
  )
});

const HeaderDisplay = React.memo(function ({
  extra, type, adaptive, disableHomeButton, className, ...props
}: HeaderCommonProps) {
  const HeaderContainer = adaptive ? AContainer : Container;

  return (
    <header className={cn(classes.header, {
      [classes.headerTransparent]: type === "transparent",
      [classes.headerShaded]: type === "shaded",
      [classes.adaptive]: adaptive,
    }, className)} {...props}>
      <HeaderContainer className={classes.headerContainer}>
        <div className={classes.headerLeft}>
          <ReactLink
            className={cn(classes.logoLink)}
            href={!disableHomeButton ? reverse("home") : undefined}
          >
            <Logo className={cn(classes.logo)} variant={"white"}/>
          </ReactLink>
        </div>
        <div className={classes.headerRightMenu}>
          {extra}

          <LangSelector className={classes.headerRightLangSelector}/>
        </div>
      </HeaderContainer>
    </header>
  )
});

Header.Display = HeaderDisplay;

const Main = function ({className, ...props}: ComponentProps<"div">) {
  return <div className={cn(classes.content, className)} {...props}/>
}

export {Header, Main};
