// Hooks, libs and types
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {getExternalConnectionType, mapObject} from "../../utils";
import {analyticsSendEvent} from "../../libs/analytics";
import {
  DetailedSessionFeedbackInput, EducationSessionErrorReason,
  EducationSessionResult, Lesson, LessonMode, ResultDescription
} from "../../schema";
import {gql} from "@apollo/client";

// Redux
import {useDispatch, useSelector} from "react-redux";
import isEqual from "lodash/isEqual";
import {start, submitFeedback} from "../../redux/player/actions";
import {PlayerReduxState} from "../../redux/player/types";
import {canRestart, canDisplayResults} from "../../redux/player/selectors";

// Components
import Button from "../../ui/button";
import FeedbackForm from "../results/FeedbackForm";
import ResultsDisplay from "../results/ResultsDisplay";

import classes from "./Results.module.css";

export type QueryData = {
  results: Pick<EducationSessionResult,
    "passed" | "score" | "time" | "usedHints" | "record" | "recordState" | "requestFeedback" |
    "generalSentimentNeutral" | "generalSentimentPositive" | "generalSentimentNegative" |
    "finishedScenariosIds" | "parametricResults"
  >,
  nextLesson: Pick<Lesson, "id" | "accessToken"> | null,
}

type props = Omit<React.ComponentProps<"div">, "results"> & {
  lesson: Pick<Lesson, "id" | "mode" | "languageCode" | "showDetailedResults" | "resultMessage">,
  results: QueryData["results"],
  nextLesson?: QueryData["nextLesson"],
  courseId?: string,
  descriptions?: ResultDescription[],
  onFinish: () => void
}

const Results = function ({lesson, results, nextLesson, descriptions, courseId, onFinish, ...props}: props) {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {
    canRestart,
    canDisplayResults,
    isInterrupted,
    interruptReason,
  } = useSelector(Results.selector, isEqual)

  const onFeedback = useCallback((
    score?: number, data?: DetailedSessionFeedbackInput, message?: string, contacts?: string) => {
    analyticsSendEvent("leaveFeedback", {
      from: "player",
      score,
      message,
      contacts,
      ...mapObject(data ?? {}, item => !!item)
    });

    dispatch(submitFeedback({
      data: data ?? null,
      score: score ?? null,
      message: message ?? null,
      contacts: contacts ?? null
    }))
  }, [dispatch]);
  const onRestart = useCallback(() => dispatch(start()), [dispatch]);

  const canFinish = !!getExternalConnectionType()
  const isTestWithCustomScores = lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES;

  const matchedDescription = useMemo(() => {
    if (!isTestWithCustomScores || !descriptions) {
      return null;
    }
    return descriptions.find(desc => desc.minScore <= results.score && desc.maxScore >= results.score)?.message
  }, [descriptions, results, isTestWithCustomScores])

  // const nextLessonHref = useMemo(() => {
  //   if (!nextLesson) {
  //     return undefined;
  //   }
  //   const url = new URL(reverse("playerPlay", {
  //     id: isShared ? getSharedId(nextLesson) : nextLesson.id
  //   }), document.baseURI);
  //   if (isShared && courseId) {
  //     url.searchParams.set("courseId", courseId);
  //   }
  //   // Making relative URL because react-router don't accept absolute
  //   return url.pathname + url.search;
  // }, [nextLesson, isShared, courseId])

  if (isInterrupted) {

    const isQuizMode = lesson.mode === LessonMode.QUIZ;
    let interruptText = isQuizMode
      ? t("components.Results.interrupt.defaultAlt")
      : t("components.Results.interrupt.default");

    switch (interruptReason) {
      case EducationSessionErrorReason.BAD_WORD:
        interruptText = isQuizMode
          ? t("components.Results.interrupt.badWordsAlt")
          : t("components.Results.interrupt.badWords");
        break;
      case EducationSessionErrorReason.LESSON_TIME_LIMIT_EXPIRED:
        interruptText = t("components.Results.interrupt.timeLimitExpired");
        break;
      default:
        console.error(`No special text for interrupt reason: ${interruptReason}`)
        break;
    }
    return (
      <div className={classes.root} {...props}>
        <h2 className={classes.title}>{t("components.Results.title")}</h2>
        <span className={classes.interruptText}>{interruptText}</span>
        {canRestart ? (
          <Button size='l' color="primary" onClick={onRestart}>{t("components.Results.restart")}</Button>
        ) : (
          <Button size='l' color="primary" onClick={onFinish}>{t("components.Results.finish")}</Button>
        )}
      </div>
    )
  }

  return (
    <ResultsDisplay adaptive
      className={lesson.showDetailedResults ? classes.resultsDisplay : classes.ResultsDisplayCenter}
      lesson={lesson}
      results={results}
      canDisplayResults={canDisplayResults}
      {...props}
    >
      <div className={lesson.showDetailedResults ? classes.buttonContainer : classes.buttonContainerCenter}>
        {canRestart
          ? (
            <Button color="primary" className={classes.btn}
                    onClick={onRestart}>{t("components.Results.restart")}</Button>
          )
          : canFinish ? (
            <Button color="primary" className={classes.btn}
                    onClick={onFinish}>{t("components.Results.finish")}</Button>
          ) : (
            <span>{t("components.Results.returnToLms")}</span>
          )}
      </div>

      {lesson.resultMessage && (
        <div
          className={lesson.showDetailedResults
            ? classes.resultMessageContainer
            : classes.resultMessageContainerCenter}
        >
          {lesson.resultMessage}
        </div>
      )}
      {isTestWithCustomScores && (
        <div
          className={lesson.showDetailedResults
          ? classes.resultMessageContainer
          : classes.resultMessageContainerCenter}
        >
          {matchedDescription}
        </div>
      )}

      <FeedbackForm adaptive
        modalVisibleByDefault
        isQuizMode={lesson.mode === LessonMode.QUIZ}
        className={classes.feedback}
        onFeedback={onFeedback}
      />
    </ResultsDisplay>
  )
}

Results.selector = (state: PlayerReduxState) => ({
  canRestart: canRestart(state),
  canDisplayResults: canDisplayResults(state),
  isInterrupted: state.interrupted,
  interruptReason: state.interruptReason,
})

Results.fragments = {
  root: gql`
    fragment Results on EducationSessionResult {
      passed
      score
      time
      usedHints

      record
      recordState

      generalSentimentNeutral
      generalSentimentPositive
      generalSentimentNegative

      requestFeedback
      finishedScenariosIds
      parametricResults {
        parameterName
        score
      }
    }
  `,
  nextLesson: gql`
    fragment NextLesson on Lesson {
      id
      accessToken
    }
  `
}


export default Results;
