import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import {parseMimeType, PlayableMediaType} from "../../../utils";
import {AvatarAction} from "../../../schema";
import {getTextWithStressMarks} from "../../Phrase";

import Bubble from "../Bubble";
import {OneOfThreeLevelsIcon} from "../../../ui/icons";

import cn from "classnames";
import classes from "./AvatarBubble.module.css";

type QueryData = {
  action: Pick<AvatarAction, "audio" | "text" | "media" | "mediaType" | "onlyFirstLevel">
}

type props = React.ComponentProps<typeof Bubble> & {
  action: QueryData["action"],
  onPlayClick?: React.MouseEventHandler,
}

export default function AvatarBubble({action, className, onPlayClick, ...props}: props) {
  const {t} = useTranslation();

  const text = useMemo(() => getTextWithStressMarks(action.text), [action.text])

  const {media, mediaType, onlyFirstLevel} = action;
  const mediaPreview = useMemo(() => {
    if (media && mediaType) {
      switch (parseMimeType(mediaType).type) {
        case PlayableMediaType.IMAGE:
          return (
            <img
              className={classes.mediaPreview}
              src={media}
              alt={t("components.AvatarBubble.imageAlt")}
            />
          )
        case PlayableMediaType.VIDEO:
          return (
            <video
              className={classes.mediaPreview}
              src={media}
            />
          )
        default:
          console.error(`Unsupported media type in avatar bubble: ${mediaType}`)
      }
    }
  }, [media, mediaType, t]);

  return (
    <Bubble className={cn(classes.root, className)} {...props}>
      <Bubble.Row>
        <Bubble.Content>
        {text || (
          <Bubble.Placeholder>{t("components.AvatarBubble.placeholder")}</Bubble.Placeholder>
        )}
        {mediaPreview}
        </Bubble.Content>

        {!!action.audio && (
          <Bubble.PlayButton onClick={onPlayClick}/>
        )}
      </Bubble.Row>
      {onlyFirstLevel && (
        <Bubble.ExtraIconsWrapper>
          <OneOfThreeLevelsIcon title={t("components.AvatarBubble.onlyFirstLevelHelpText")}/>
        </Bubble.ExtraIconsWrapper>
      )}
    </Bubble>
  )
}


AvatarBubble.fragments = {
  root: gql`
    fragment AvatarBubble on AvatarAction {
      id
      text
      media
      mediaType
      audio
      onlyFirstLevel
    }
  `,
}
