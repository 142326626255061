import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Device} from "../../../schema";

import EditItemDropdown from "../../EditItemDropdown";

export type Props =  {
  device: Device | undefined,
  onRenameDevice: (device: Device) => void,
  onSetStatus: (device: Device) => void,
  onGenerateQR?: (device: Device) => void,
}

export default function DeviceEditDropdown({device, onRenameDevice, onSetStatus, onGenerateQR}: Props) {
  const {t} = useTranslation();

  const renameDevice = useCallback(() => {
    onRenameDevice(device!)
  }, [onRenameDevice, device])

  const setStatus = useCallback(() => {
    onSetStatus(device!)
  }, [onSetStatus, device])

  const generateQR = useCallback(() => {
    onGenerateQR && onGenerateQR(device!)
  }, [onGenerateQR, device])

  return (
    <EditItemDropdown>
      <EditItemDropdown.Action title={t("components.DeviceEditDropdown.rename")} onClick={renameDevice}/>
      {device?.active ? (
        <EditItemDropdown.Action title={t("components.DeviceEditDropdown.deactivate")} onClick={setStatus}/>
      ) : (
        <EditItemDropdown.Action title={t("components.DeviceEditDropdown.activate")} onClick={setStatus}/>
      )}
      {onGenerateQR && (
        <EditItemDropdown.Action title={t("components.DeviceEditDropdown.generateQR")} onClick={generateQR}/>
      )}

    </EditItemDropdown>
  )
}
