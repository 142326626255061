import compact from "lodash/compact";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
  getLessonScenariosLength, getMainScenario
} from "../../../scenes/editor/lesson-edit.utils";

import {MAX_LESSON_LENGTH, MAX_MAIN_SCENARIO_LENGTH} from "../../../settings";
import {Action, Scenario} from "../../../types";
import {AsSpecificComponent} from "../../../ui";

import {AlertCircleIcon} from "../../../ui/icons";
import WithTooltip from "../../../ui/tooltip";

import classes from "./LessonWarnings.module.css";

export type LessonWarningsProps = Omit<AsSpecificComponent<typeof WithTooltip, "div">, "helpText" | "children"> & {
  scenarios: Scenario[]
}

export default function LessonWarnings({
  scenarios, ...props
}: LessonWarningsProps) {
  const {t} = useTranslation();

  const lessonLength = useMemo(() => (
    getLessonScenariosLength(scenarios)
  ), [scenarios]);

  const lessonHaveSecondaryScenario = scenarios.length > 1;

  const mainScenario = useMemo(() => (
    getMainScenario(scenarios)
  ), [scenarios]);
  const mainScenarioLength = mainScenario.actions.length;

  const lessonOverflow = lessonLength - MAX_LESSON_LENGTH;
  const mainScenarioOverflow = mainScenarioLength - MAX_MAIN_SCENARIO_LENGTH;

  const emptyActionsCount = useMemo(() => (
    scenarios.reduce((count, scenario) => (
      count + scenario.actions.filter(isActionEmpty).length
    ), 0)
  ), [scenarios]);

  const warningText = useMemo(() => {
    const warnings: (string | false)[] = [];

    function getActionPlural(count: number) {
      return t("types.plurals.action", {count})
    }

    if (lessonHaveSecondaryScenario) {
      warnings.push(...[
        (lessonOverflow > 0) && t("components.LessonWarnings.tooManyActions.lesson", {
          excess: getActionPlural(lessonOverflow)
        }),
        (mainScenarioOverflow > 0) && t("components.LessonWarnings.tooManyActions.mainScenario", {
          excess: getActionPlural(mainScenarioOverflow)
        })
      ])
    } else {
      warnings.push(...[
        (mainScenarioOverflow > 0) && t("components.LessonWarnings.tooManyActions.lesson", {
          excess: getActionPlural(mainScenarioOverflow)
        })
      ])
    }

    if (emptyActionsCount) {
      warnings.push(...[
        emptyActionsCount > 0 && t("components.LessonWarnings.emptyActions", {
          excess: getActionPlural(emptyActionsCount)
        })
      ])
    }

    return compact(warnings).map(item => `<p>${item}</p>`).join("\n")
  }, [t, lessonHaveSecondaryScenario, lessonOverflow, mainScenarioOverflow, emptyActionsCount]);

  return (
    <>
      {warningText && (
        <WithTooltip helpText={warningText} {...props}>
          <AlertCircleIcon className={classes.warning}/>
        </WithTooltip>
      )}
    </>
  )
}

function isActionEmpty(action: Action) {
  switch (action.__typename) {
    case "AvatarAction":
    case "SystemAction":
      return action.text === "";
    case "UserInputAction":
      return action.rawExpectedText === "";
    case "ChoiceUserInputAction":
      return action.branches.some(branch => {
        if (branch.avatarReaction && isActionEmpty(branch.avatarReaction)) {
          return true;
        }
        if (branch.systemReaction && isActionEmpty(branch.systemReaction)) {
          return true;
        }
        if (branch.userInput && isActionEmpty(branch.userInput)) {
          return true;
        }
        return false;
      })
    case "RandomAvatarAction":
      return action.branches.some(branch => {
        if (branch.avatarAction && isActionEmpty(branch.avatarAction)) {
          return true;
        }
        return false;
      });
    default:
      throw new Error(`isActionEmpty not implemented for type ${action.__typename}`);
  }
}
