import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {QueryData, query,
  useAddCustomDictMutation,
  useDeleteCustomDictMutation,
  useUpdateCustomDictMutation
} from "./dictionaries.graphql";
import {
  CustomDictionaryInput,
  Scalars,
} from "../../schema";

import ReactLink from "../../components/ReactLink";
import {useModal} from "../../components/ModalProvider";
import {reverse} from "../../routing";
import {analyticsSendEvent} from "../../libs/analytics";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import Button from "../../ui/button";
import {AddIcon} from "../../ui/icons";
import NavigationMenu from "../../components/service/NavigationMenu";
import CustomDictsTable from "../../components/service/dictionaries-table/CustomDictsTable";
import CustomDictionaryModal from "../../components/service/dictionaries-table/CustomDictionaryModal";

import classes from "./index.module.css";


export default function DictionariesScene() {
  const {t} = useTranslation();

  const {data, loading: fetching} = useQuery<QueryData>(query);
  const {dictionaries} = data ?? {};
  const loading = !data && fetching;

  const {add: addModal} = useModal();
  const [applyAddCustomDictMutation] = useAddCustomDictMutation();
  const [applyUpdateCustomDictMutation] = useUpdateCustomDictMutation();
  const [applyDeleteCustomDictMutation] = useDeleteCustomDictMutation();

  const onDictAdd = useCallback(() => {
    analyticsSendEvent("serviceCustomDictAdd");
    const data = {name: t("service.dictionaries.new")}
    applyAddCustomDictMutation({
      variables: {data},
      refetchQueries: ["GetCustomDictionaries"]
    });
  }, [applyAddCustomDictMutation, t])

  const onDictUpdate = useCallback((dictId: Scalars["ID"], data: CustomDictionaryInput) => {
    analyticsSendEvent("serviceCustomDictUpdate", {dictId});
    applyUpdateCustomDictMutation({
      variables: {dictionaryId: dictId, data: data},
      refetchQueries: ["GetCustomDictionaries"]
    });
  }, [applyUpdateCustomDictMutation])

  const onDictDelete = useCallback((dictId: Scalars["ID"]) => {
    analyticsSendEvent("serviceCustomDictDelete", {dictId});
    applyDeleteCustomDictMutation({
      variables: {dictionaryId: dictId},
      refetchQueries: ["GetCustomDictionaries"]
    });
  }, [applyDeleteCustomDictMutation])

  const onRowClick = useCallback((e: React.MouseEvent) => {
    const dictId = e.currentTarget.getAttribute("data-id")!;
    e.preventDefault()
    e.stopPropagation()
    const modal = addModal({
      id: `editCustomDict_${dictId}`,
      header: <CustomDictionaryModal.Header/>,
      content:
        <CustomDictionaryModal.Content
          dict={dictionaries?.find(dict => dict.id === dictId)}
          onSave={(data) => {
            onDictUpdate && onDictUpdate(dictId, data);
            modal.remove();
          }}
          onDelete={() => {
            onDictDelete && onDictDelete(dictId);
            modal.remove();
          }}
        />
    })
  }, [addModal, dictionaries, onDictUpdate, onDictDelete])

  return (
    <>
      <ReactDocumentTitle title={t("service.dictionaries.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.dictionaries")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.dictionaries.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <>
                    <CustomDictsTable
                      dicts={dictionaries}
                      onRowClick={onRowClick}
                    />
                    <div className="mt-[26px]">
                      <Button className={classes.addButton} onClick={onDictAdd}>
                        <AddIcon/>{t("service.dictionaries.add")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
