import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {reverse} from "../../routing";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import ReactLink from "../../components/ReactLink";
import ConversationsTable from "../../components/service/conversations-table/ConversationsTable";
import NavigationMenu from "../../components/service/NavigationMenu";
import {TABLE_ROWS_PER_PAGE} from "../../settings";

import {ListQueryData, listQuery} from "./conversations.graphql";
import classes from "./index.module.css";
import Paginator from "../../ui/paginator";


export default function ConversationsListScene() {
  const {t} = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const {data, loading: fetching} = useQuery<ListQueryData>(listQuery, {variables: {
    offset: (currentPage - 1) * TABLE_ROWS_PER_PAGE,
    limit: TABLE_ROWS_PER_PAGE,
  }, fetchPolicy: "network-only",});
  const {conversations} = data ?? {};
  const loading = !data && fetching;

  const items = conversations?.items ?? [];
  const itemsCount = Math.min(items.length, TABLE_ROWS_PER_PAGE) ?? 0;
  const totalItems = conversations?.countItems ?? 0;

  const totalPages = Math.ceil(totalItems / TABLE_ROWS_PER_PAGE);

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.conversations")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.conversations.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <>
                    <ConversationsTable conversations={conversations?.items}/>

                    <div className={classes.paginatorRow}>
                      <div className={classes.paginatorHelpText}>
                        {t("common.paginatorShowingItems", {
                          items: itemsCount,
                          totalItems: totalItems
                        })}
                      </div>

                      {(totalPages > 1) && (
                        <Paginator
                          className={classes.paginator}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={setCurrentPage}
                        />
                      )}
                    </div>
                  </>

                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
