/* eslint-disable max-lines */
/// Hooks
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useLazyQuery, useQuery} from "@apollo/client";
import useVisibility from "../../hooks/useVisibility";
import {useTour} from "../../providers/tour";
import useWaitForFileUpload from "../../hooks/useWaitForFileUpload";
import {useCurrentUser} from "../../App.context";

/// Libs
import {getDataWithMock} from "../../tours";
import {analyticsSendEvent} from "../../libs/analytics";
import {getLanguageCode} from "../../i18n";
import {getMainScenario, getScenarioById} from "./lesson-edit.utils";
import {reverse} from "../../routing";

/// Types
// GQL
import {
  avatarsQuery,
  avatarsQueryData,
  avatarsQueryVars,
  query as lessonQuery,
  queryData,
  queryVars,
  useAddActionMutation,
  useAddScenarioMutation,
  useDeleteActionMutation,
  useDeleteScenarioMutation,
  useUpdateActionMutation,
  useUpdateChildrenMutation,
  useUpdateLessonMutation,
  useUpdateScenarioMutation,
} from "./lesson-edit.graphql";
// Other
import {EditorAccessType, Lesson, LessonInput, ScenarioInput} from "../../schema";
import {Action, ActionInput} from "../../types";

/// Components
// UI and layout
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Skeleton from "../../ui/skeleton";
import Button from "../../ui/button";
import {PlayIcon} from "../../ui/icons";
import Breadcrumbs from "../../ui/breadcrumbs";
import Link from "../../ui/link";
import ReactLink from "../../components/ReactLink";
// Pages
import PageNotFoundScene from "../system/page-not-found";
// Other
import {DemoAccessExpiredWidget} from "../../components/system/DemoAccessExpiredWidget";
import LessonActionsList from "../../components/editor/LessonActionsList";
import EditableItemName from "../../components/editor/EditableItemName"
import ScenarioCustomizeForm from "../../components/editor/ScenarioCustomizeForm";
import LessonCustomizeForm from "../../components/editor/LessonCustomizeForm";
import ShareButton from "../../components/editor/ShareButton";
import WidgetErrorBoundary, {ErrorWidgetOptions} from "../../components/system/WidgetErrorBoundary";
import FileIsBrokenWidget from "../../components/editor/widgets/FileIsBroken";
import FileUploadingModal from "../../components/editor/FileUploadingModal";
import LessonScenariosLength from "../../components/editor/lesson-edit/LessonScenariosLength";
import LessonWarnings from "../../components/editor/lesson-edit/LessonWarnings";
import ScenarioSelector from "../../components/editor/lesson-edit/ScenarioSelector";
// Tour
import EditorLessonEditTour, {
  useMockData,
  classes as tourClasses,
  isEditorLessonEditTourMock,
  NAME as tourName
} from "./lesson-edit.tour";

/// Classes
import cn from "classnames";
import classes from "./lesson-edit.module.css";

export default function EditorLessonEditScene() {
  const {t, i18n} = useTranslation();
  const language = getLanguageCode(i18n.language)

  const {runningTourName, run: runTour, bindToPage: bindTourToPage, mock: tourMock} = useTour();
  const tourMode = runningTourName === tourName;

  useEffect(() => bindTourToPage(tourName), [bindTourToPage])

  const {id: lessonId} = useParams<{ id: string }>();

  useEffect(() => {
    analyticsSendEvent("editorLessonView", {lessonId});
  }, [lessonId]);

  const [query, {data, loading: fetching}] = useLazyQuery<queryData, queryVars>(lessonQuery, {
    variables: {lessonId, language}
  });

  const {avatars} = useQuery<avatarsQueryData, avatarsQueryVars>(avatarsQuery, {
    variables: {language}
  }).data ?? {}

  const isStaff = useCurrentUser()?.isStaff;

  useEffect(() => {
    if (lessonId === "tour") {
      runTour(tourName, true);
    } else {
      query();
    }
  }, [lessonId, runTour, query])

  const mock = useMemo(() => {
    if (isEditorLessonEditTourMock(tourMock)) {
      return tourMock;
    }
  }, [tourMock]);

  const mockData = useMockData(avatars, mock?.data.lessonName);

  const {
    lesson, course, scenarios, triggerDicts, cutWordsDicts, hotWordsDicts, resultDescriptions, editorAccessType
  } = useMemo(() => (
    getDataWithMock(data, mockData, tourMode)
  ), [data, mockData, tourMode]);

  const loading = !lesson || !avatars;
  const isDemoExpired = editorAccessType === EditorAccessType.EXPIRED_DEMO_ACCESS;

  const shareLessonId = lesson?.id ? `${lesson?.id}_${lesson?.accessToken}` : lessonId;

  const [isUploading, waitForFileUpload] = useWaitForFileUpload();

  const [scenariosVisibility, showScenarios, hideScenarios] = useVisibility((scenarios?.length ?? 0) > 1);
  const [statedCurrentScenarioId, setCurrentScenarioId] = useState<string>();

  // Fix for switching to tourMode. TODO: Replace with something better like useEffect on tourMode
  const currentScenarioId = tourMode ? mockData.scenarios![0].id : statedCurrentScenarioId;

  const currentScenario = useMemo(() => (scenarios && currentScenarioId) ? (
    getScenarioById(scenarios, currentScenarioId)
  ) : undefined, [scenarios, currentScenarioId])

  // We assuming that first element in scenario array is main
  const mainScenarioId = scenarios ? getMainScenario(scenarios)?.id : undefined;

  useEffect(() => {
    if (!loading && (currentScenarioId === undefined) && scenarios) {
      setCurrentScenarioId(getMainScenario(scenarios).id)
      if (scenarios.length > 1) {
        showScenarios()
      } else {
        hideScenarios()
      }
    }
  }, [loading, scenarios, currentScenarioId, currentScenario, showScenarios, hideScenarios])

  const [applyAddActionMutation] = useAddActionMutation();
  const [applyUpdateActionMutation] = useUpdateActionMutation();
  const [applyDeleteActionMutation] = useDeleteActionMutation();
  const [applyUpdateLessonMutation] = useUpdateLessonMutation();
  const [applyAddScenarioMutation] = useAddScenarioMutation();
  const [applyUpdateScenarioMutation] = useUpdateScenarioMutation();
  const [applyDeleteScenarioMutation] = useDeleteScenarioMutation();
  const [applyUpdateChildrenMutation] = useUpdateChildrenMutation();

  const shareLink = useMemo(() => (
    window.location.origin + reverse("playerPlay", {id: shareLessonId})
  ), [shareLessonId])

  const onShareClick = useCallback(() => {
    analyticsSendEvent("editorCopyLessonShareLink", {lessonId});
  }, [lessonId]);

  const onLessonUpdate = useCallback((data: LessonInput) => {
    analyticsSendEvent("editorLessonUpdate", {lessonId});

    waitForFileUpload(applyUpdateLessonMutation({
      variables: {lessonId, data, language}
    }), data)
  }, [lessonId, language, applyUpdateLessonMutation, waitForFileUpload]);

  const onLessonRename = useCallback((data: Pick<Lesson, "name">) => {
    analyticsSendEvent("editorLessonRename", {lessonId});

    applyUpdateLessonMutation({
      variables: {lessonId, data, language}
    });
  }, [lessonId, language, applyUpdateLessonMutation]);

  const onLessonPreview = useCallback(() => {
    analyticsSendEvent("editorLessonPreview", {lessonId});
  }, [lessonId]);

  const onActionAdd = useCallback((actionType: Action["__typename"], data: ActionInput) => {
    analyticsSendEvent("editorLessonAddAction", {
      lessonId, scenarioId: currentScenarioId ?? "no", actionType,
      position: data?.beforeActionId ? "insert" : "append"
    });

    applyAddActionMutation(actionType, {
      variables: {lessonId, scenarioId: currentScenarioId, data}
    });
  }, [lessonId, currentScenarioId, applyAddActionMutation]);

  const onActionUpdate = useCallback((actionId: Action["id"], actionType: Action["__typename"], data: ActionInput) => {
    analyticsSendEvent("editorLessonUpdateAction", {lessonId, actionId});

    waitForFileUpload(applyUpdateActionMutation(actionType, {
      variables: {lessonId, actionId, data}
    }), data);
  }, [lessonId, applyUpdateActionMutation, waitForFileUpload]);

  const onActionDelete = useCallback((actionId: Action["id"]) => {
    analyticsSendEvent("editorLessonDeleteAction", {lessonId, actionId});

    applyDeleteActionMutation({
      variables: {lessonId, actionId}
    });
  }, [lessonId, applyDeleteActionMutation]);

  const onScenarioAdd = useCallback(() => {
    applyAddScenarioMutation({
      variables: {lessonId, data: {name: t("editorLessonEdit.scenario.secondary")}}
    });
  }, [lessonId, t, applyAddScenarioMutation])

  const onScenarioUpdate = useCallback((data: ScenarioInput) => {
    analyticsSendEvent("editorScenarioUpdate", {scenarioId: currentScenarioId!});

    applyUpdateScenarioMutation({
      variables: {lessonId, scenarioId: currentScenarioId!, data}
    })
  }, [lessonId, currentScenarioId, applyUpdateScenarioMutation]);

  const onScenarioDelete = useCallback(() => {
    setCurrentScenarioId(mainScenarioId);

    analyticsSendEvent("editorScenarioDelete", {scenarioId: currentScenarioId!});

    applyDeleteScenarioMutation({
      variables: {lessonId, scenarioId: currentScenarioId!}
    })
  }, [mainScenarioId, lessonId, currentScenarioId, applyDeleteScenarioMutation]);

  const onFirstScenarioAdd = useCallback(() => {
    onScenarioAdd();
    showScenarios();
  }, [onScenarioAdd, showScenarios]);

  const onChildrenUpdate = useCallback(() => {
    analyticsSendEvent("editorLessonChildrenUpdate", {lessonId: lessonId});
    applyUpdateChildrenMutation({
      variables: {lessonId}
    })
  }, [lessonId, applyUpdateChildrenMutation]);

  const errorWidgets = useMemo(() => new Map<string, ErrorWidgetOptions>([
    ["FILE_IS_BROKEN", {
      widget: FileIsBrokenWidget,
      stopErrorProcessing: true
    }]
  ]), []);

  const warnings = useMemo(() => {
    if (!scenarios || !currentScenarioId) {
      return undefined;
    }

    return (
      <LessonWarnings
        className={classes.warnings}
        scenarios={scenarios}
      />
    )
  }, [scenarios, currentScenarioId]);

  if (!fetching && !lesson) {
    return <PageNotFoundScene/>;
  }
  return (
    <>
      <ReactDocumentTitle title={lesson?.name ?? t("editorLessonEdit.title")}/>

      {tourMode && <EditorLessonEditTour loading={loading} returnToPreviousPage={lessonId === "tour"}/>}
      <Layout data-testid="EditorLessonEditScene">
        <Header/>
        <Main>
          <Container className={classes.main}>
            <WidgetErrorBoundary errorWidgets={errorWidgets}>
              <Breadcrumbs className={classes.breadcrumbs}>
                <ReactLink href={reverse("editor")}>{t("breadcrumbs.editor")}</ReactLink>
                {course?.id ? (
                  <ReactLink href={reverse("editorCourseEdit", {id: course.id})}>
                    {course?.name === undefined
                      ? t("breadcrumbs.lesson")
                      : course.name || <span className={classes.notSpecified}>{t("common.notSpecified")}</span>
                    }
                  </ReactLink>
                ) : null}
                <span title={lesson?.name} translate="no">
                  {lesson?.name === undefined
                    ? t("breadcrumbs.lesson")
                    : lesson.name || <span className={classes.notSpecified}>{t("common.notSpecified")}</span>
                  }
                </span>
              </Breadcrumbs>

              <div className={classes.header}>
                {!loading && lesson?.name ? (
                  <EditableItemName
                    key={lesson.id}
                    className={tourClasses.lessonName}
                    item={lesson!}
                    onSave={mock?.nameEditSave ?? onLessonRename}
                  />
                ) : (
                  <Skeleton.Text height={28} width={250}/>
                )}
                <div className={cn(classes.topRightMenu, tourClasses.topRightMenu)}>
                  <Button
                    as={ReactLink}
                    className={tourClasses.participants}
                    href={reverse("accountMembers") + "?lessonId=" + encodeURIComponent(lessonId)}
                  >{t("editorLessonEdit.participants")}</Button>
                  {isDemoExpired ? (
                    <Button className={tourClasses.scorm} disabled>
                      SCORM 2004
                    </Button>
                  ) : (
                    <Button as="a" className={tourClasses.scorm} href={lesson?.scormDownloadUrl}>
                      SCORM 2004
                    </Button>
                  )}

                  <ShareButton
                    className={tourClasses.shareButton}
                    value={shareLink}
                    name={lesson?.name}
                    onClick={onShareClick}
                    title={t("components.ShareButton.lessonTitle")}
                    helpText={t("editorLessonEdit.shareButtonHelpText")}
                    disabled={isDemoExpired}
                  />
                  <Button
                    as={ReactLink}
                    className={cn(classes.previewButton, tourClasses.preview)}
                    href={reverse("playerPlay", {id: lessonId}) + "?preview=1"}
                    onClick={onLessonPreview}
                    color='primary'
                  >
                  <PlayIcon className={classes.previewIcon}/>
                    {t("editorLessonEdit.preview")}
                    {warnings}
                  </Button>
                  {isStaff && lesson?.isInitial && (
                      <Button
                        className={classes.previewButton}
                        onClick={onChildrenUpdate}
                        color='success'
                      >
                        {t("editorLessonEdit.updateChildrenLessons")}
                      </Button>
                    )
                  }
                </div>
              </div>

              <div className={classes.content}>
                <div className={classes.contentMain}>
                  <div className={classes.contentMainEdit}>
                    {scenarios && (scenariosVisibility ? (
                      <ScenarioSelector
                        className={classes.scenariosWrapper}
                        scenarios={scenarios}
                        currentScenarioId={currentScenarioId!}
                        disabled={isDemoExpired}
                        onScenarioAdd={onScenarioAdd}
                        onScenarioIdSelect={setCurrentScenarioId}
                      />
                    ) : (
                      isDemoExpired ? (
                        t("editorLessonEdit.cannotAddScenario")
                      ) : (
                        <Link className={classes.addScenariosLink} onClick={onFirstScenarioAdd}>
                          {t("editorLessonEdit.addScenario")}
                        </Link>
                      )
                    ))}
                    {!loading && currentScenario ? (
                      <LessonActionsList
                        className={tourClasses.timeline}
                        lesson={lesson!}
                        scenarios={scenarios}
                        actions={currentScenario.actions}
                        disabled={isDemoExpired}
                        onAdd={onActionAdd}
                        onUpdate={onActionUpdate}
                        onDelete={onActionDelete}
                      />
                    ) : (
                      <LessonActionsList.Skeleton/>
                    )}
                    {scenarios && currentScenarioId && (
                      <LessonScenariosLength
                        className={classes.lessonLength}
                        scenarios={scenarios}
                        currentScenarioId={currentScenarioId}
                      />
                    )}
                  </div>
                  <div className={classes.contentMainInfo}>
                    {warnings}
                  </div>
                </div>
                <div className={classes.contentAside}>
                  {currentScenario && !loading && (currentScenario.isMain ? (
                    <LessonCustomizeForm
                      className={tourClasses.lessonCustomizeForm}
                      lesson={lesson!}
                      triggerDicts={triggerDicts}
                      cutWordsDicts={cutWordsDicts}
                      hotWordsDicts={hotWordsDicts}
                      resultDescriptions={resultDescriptions}
                      avatars={avatars!}
                      disabled={isDemoExpired}
                      onSave={onLessonUpdate}
                    />
                  ) : (
                    <ScenarioCustomizeForm
                      key={currentScenarioId}
                      scenario={currentScenario}
                      onSave={onScenarioUpdate}
                      onDelete={onScenarioDelete}
                    />
                  ))}
                </div>
              </div>
            </WidgetErrorBoundary>
          </Container>
        </Main>
      </Layout>
      {isDemoExpired && <DemoAccessExpiredWidget/>}
      {isUploading && <FileUploadingModal/>}
    </>
  )
}
