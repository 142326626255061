/* eslint-disable max-lines */
import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {
  Avatar,
  AvatarAction,
  ChoiceUserInputAction,
  ChoiceUserInputBranchState,
  Scalars,
  SystemAction
} from "../../../schema";
import {gql} from "@apollo/client";

import Bubble from "../Bubble";
import Phrase, {PhraseVariant} from "../../Phrase";
import Link from "../../../ui/link";

import {AddIcon, CheckmarkCircleIcon, CrossCircleIcon, DeleteIcon, PlayForwardCircleIcon} from "../../../ui/icons";
import AvatarIcon from "../../AvatarIcon";
import SystemIcon from "../../SystemIcon";

import cn from "classnames";
import classes from "./ChoiceUserInputBubble.module.css";

type props = React.ComponentProps<typeof Bubble> & {
  action: ChoiceUserInputAction
  avatar: Avatar
  showCustomScore: boolean

  onAddBranchClick?: () => void
  onBranchClick?: (branchId: Scalars["ID"]) => void,
  onReactionAddClick?: (branchId: Scalars["ID"], type: AvatarAction["__typename"] | SystemAction["__typename"]) => void
  onReactionClick?: (branchId: Scalars["ID"], type: AvatarAction["__typename"] | SystemAction["__typename"],
                     actionId: Scalars["ID"]) => void
  onReactionDeleteClick?: (branchId: Scalars["ID"], type: AvatarAction["__typename"] | SystemAction["__typename"],
                           actionId: Scalars["ID"]) => void

  onDeleteBranchClick?: (branchId: Scalars["ID"]) => void
}

const branchIconComponent = {
  [ChoiceUserInputBranchState.CORRECT]: <CheckmarkCircleIcon className={cn(classes.icon, classes.correctIcon)}/>,
  [ChoiceUserInputBranchState.INCORRECT]: <CrossCircleIcon className={cn(classes.icon, classes.incorrectIcon)}/>,
  [ChoiceUserInputBranchState.INTERRUPT]: <PlayForwardCircleIcon className={cn(classes.icon, classes.incorrectIcon)}/>,
}

export default function ChoiceUserInputBubble({
                                                avatar,
                                                action,
                                                showCustomScore,
                                                className,

                                                onAddBranchClick,
                                                onBranchClick,
                                                onReactionAddClick,
                                                onReactionClick,
                                                onReactionDeleteClick,
                                                onDeleteBranchClick,
                                                ...props
                                              }: props) {
  const {t} = useTranslation();

  const choices = action.branches;

  const addBranchHandler: React.MouseEventHandler = React.useCallback(() => {
    onAddBranchClick && onAddBranchClick();
  }, [onAddBranchClick])

  const branchClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onBranchClick && onBranchClick(branchId!);
  }, [onBranchClick]);

  const deleteBranchClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onDeleteBranchClick && onDeleteBranchClick(branchId!);
  }, [onDeleteBranchClick]);


  const addAvatarReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionAddClick && onReactionAddClick(branchId!, "AvatarAction");
  }, [onReactionAddClick]);

  const editAvatarReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const actionId = e.currentTarget.getAttribute("data-id");
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionClick && onReactionClick(branchId!, "AvatarAction", actionId!);
  }, [onReactionClick]);

  const deleteAvatarReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    const actionId = e.currentTarget.getAttribute("data-id");
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionDeleteClick && onReactionDeleteClick(branchId!, "AvatarAction", actionId!);
  }, [onReactionDeleteClick]);


  const addSystemReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionAddClick && onReactionAddClick(branchId!, "SystemAction");
  }, [onReactionAddClick]);

  const editSystemReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const actionId = e.currentTarget.getAttribute("data-id");
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionClick && onReactionClick(branchId!, "SystemAction", actionId!);
  }, [onReactionClick]);

  const deleteSystemReactionClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    const actionId = e.currentTarget.getAttribute("data-id");
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onReactionDeleteClick && onReactionDeleteClick(branchId!, "SystemAction", actionId!);
  }, [onReactionDeleteClick]);

  return (
    <Bubble className={cn(classes.root, className)} {...props}>
      {choices.map(((branch, idx) => {
        const icon = branchIconComponent[branch.state];

        return (
          <div key={branch.id} className={classes.branch}>
            <div className={classes.branchIcon}>
              <div data-branch-id={branch.id} onClick={branchClickHandler}>
                {showCustomScore ? <div className={classes.customScore}>{branch.customScore}</div> : icon}
              </div>
            </div>

            <div className={classes.branchContent}>
              <div className={classes.branchFirstRow}>
                <button
                  className={classes.expectedText}
                  data-branch-id={branch.id}
                  onClick={branchClickHandler}
                >
                  <Phrase
                    text={branch.userInput.expectedText}
                    variant={PhraseVariant.DEFAULT}
                    placeholder={(
                      <Bubble.Placeholder>
                        {t("components.ChoiceUserInputActionX.branchPlaceholder")}
                      </Bubble.Placeholder>
                    )}
                  />
                </button>
                <Link
                  className={classes.branchDeleteIcon}
                  data-branch-id={branch.id}
                  onClick={deleteBranchClickHandler}
                ><DeleteIcon/></Link>
              </div>
              {branch.avatarReaction ? (
                <ChoiceUserInputActionReaction
                  data-id={branch.avatarReaction.id}
                  data-branch-id={branch.id}

                  icon={(<AvatarIcon className={classes.reactionIcon} avatar={avatar}/>)}
                  text={branch.avatarReaction.text}
                  onClick={editAvatarReactionClickHandler}
                  onDeleteClick={deleteAvatarReactionClickHandler}
                />
              ) : (
                <ChoiceUserInputActionAddReaction
                  data-branch-id={branch.id}
                  placeholder={t("components.ChoiceUserInputActionX.addAvatarReaction")}
                  onClick={addAvatarReactionClickHandler}
                />
              )}
              {branch.systemReaction ? (
                <ChoiceUserInputActionReaction
                  data-id={branch.systemReaction.id}
                  data-branch-id={branch.id}

                  icon={(<SystemIcon className={classes.reactionIcon}/>)}
                  text={branch.systemReaction.text}
                  onClick={editSystemReactionClickHandler}
                  onDeleteClick={deleteSystemReactionClickHandler}
                />
              ) : (
                <ChoiceUserInputActionAddReaction
                  data-branch-id={branch.id}
                  placeholder={t("components.ChoiceUserInputActionX.addSystemReaction")}
                  onClick={addSystemReactionClickHandler}
                />
              )}
            </div>
          </div>
        );
      }))}

      <Link className={classes.branchAdd} tabIndex={0} onClick={addBranchHandler}>
        <AddIcon/>
        <span>{t("components.ChoiceUserInputActionX.addBranch")}</span>
      </Link>
    </Bubble>
  )
}

function ChoiceUserInputActionAddReaction({className, placeholder, ...props}: ComponentProps<"button"> & {
  placeholder: string
}) {
  return (
    <button className={cn(classes.reaction, classes.reactionPlaceholder, className)} {...props}>
      <AddIcon/>
      <span>{placeholder}</span>
    </button>
  )
}

function ChoiceUserInputActionReaction({icon, text, onDeleteClick, ...props}: ComponentProps<"button"> & {
  icon: React.ReactNode,
  text: React.ReactNode,
  onDeleteClick?: React.MouseEventHandler,
}) {
  return (
    <button className={classes.reaction} {...props}>
      {icon}
      <span>{text}</span>
      <Link
        data-id={(props as any)["data-id"]}
        data-branch-id={(props as any)["data-branch-id"]}

        className={classes.reactionDeleteIcon}
        onClick={onDeleteClick}
      ><DeleteIcon/></Link>
    </button>
  )
}

ChoiceUserInputBubble.fragments = {
  root: gql`
    fragment ChoiceUserInputBubble on ChoiceUserInputAction {
      id
      __typename
      branches {
        id
        state
        customScore
        targetScenarioId

        userInput {
          id
          expectedText
          hintText
        }

        avatarReaction {
          id
          text
        }

        systemReaction {
          id
          text
        }
      }
    }
  `
}
