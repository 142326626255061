import React, {memo, useEffect, useRef, useState} from "react";
import {Route, Router, Switch} from "react-router";
import {History} from "history";
import {path, reverse} from "./routing";

import PageNotFoundScene from "./scenes/system/page-not-found";
import WelcomeScene from "./scenes/home";
import ModalProvider from "./components/ModalProvider";

import LoginBundle from "./scenes/login.bundle";
import PlayerBundle from "./scenes/player.bundle";
import EditorBundle from "./scenes/editor.bundle";
import LmsBundle from "./scenes/lms.bundle";
import AccountBundle from "./scenes/account.bundle";
import {ApolloClient, ApolloLink, ApolloProvider, NormalizedCacheObject, useQuery} from "@apollo/client";
import {loginUpdateCache} from "./scenes/login/common.graphql";
import {onError} from "@apollo/client/link/error";
import {hasGraphQLError} from "./apollo/utils";
import {Account, Editor, Student} from "./schema";
import AnalyticsTracker from "./components/AnalyticsTracker";
import {PersistStorageInterface} from "./persistStorage";
import {AppQuery} from "./App.graphql";
import AppContextProvider from "./App.context";
import PromblemsWatcherProvider from "./providers/problemsWatcher";
import {ErrorBoundaryWithBugReport} from "./components/system/ErrorBoundaryWithBugReport";
import TourProvider from "./providers/tour";
import GuideProvider from "./providers/guide";
import AnalyticsBundle from "./scenes/analytics.bundle";
import ServiceBundle from "./scenes/service.bundle";

type AppProps = {
  history: History,
  client: ApolloClient<NormalizedCacheObject>
  persistStorage: PersistStorageInterface
  isExternal: boolean
  noAnalytics?: boolean
}

function App({noAnalytics, isExternal, persistStorage, history, client}: AppProps) {
  const [loaded, setLoaded] = useState(false);
  const [outdated, setOutdated] = useState(false);

  const sessionIdRef = useRef<string | undefined>();

  useEffect(() => {
    if (isExternal) return undefined;

    sessionIdRef.current = persistStorage.getItem("sessionId") ?? undefined;
  }, [persistStorage, isExternal]);

  useEffect(() => {
    client.setLink(ApolloLink.from([
      (operation, forward) => {
        const sessionId = sessionIdRef.current;

        operation.setContext({
          headers: {
            authorization: sessionId ? `Bearer ${sessionId}` : ""
          }
        });
        return forward(operation);
      },

      onError((error) => {
        // @ts-ignore
        if (error.networkError && error.networkError.statusCode === 403) {
          history.push(reverse("login") + "?error=permission_denied")
        }

        if (error.graphQLErrors) {
          if (hasGraphQLError("SESSION_INACTIVE", error.graphQLErrors)) {
            loginUpdateCache(client.cache, {user: undefined});
            setOutdated(true)
          }

          if (hasGraphQLError("PERMISSION_DENIED", error.graphQLErrors)) {
            throw new Error("PERMISSION_DENIED")
          }

          if (hasGraphQLError("FILE_IS_BROKEN", error.graphQLErrors)) {
            throw new Error("FILE_IS_BROKEN")
          }
        }

        return undefined;
      }),

      client.link,
    ]))
  }, [history, client]);

  useEffect(() => setLoaded(true), []);

  const hasSession = React.useCallback(() => {
    return isExternal && !!persistStorage.getItem("sessionId");
  }, [persistStorage, isExternal]);

  const setSessionId = React.useCallback((sessionId: string | undefined) => {
    if (isExternal) {
      return;
    }

    sessionIdRef.current = sessionId;

    if (!sessionId) {
      persistStorage.removeItem("sessionId");
    } else {
      persistStorage.setItem("sessionId", encodeURIComponent(sessionId!));
    }
  }, [persistStorage, isExternal]);

  const {data} = useQuery<{
    account: Account | null,
    user: Student | Editor | null,
  }>(AppQuery, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    client: client,
    skip: !loaded,
  });

  const user = data?.user;

  useEffect(() => {
    if (outdated) {
      setSessionId(undefined)
      setOutdated(false)
      history.push(reverse("login"))
    }
  }, [outdated, history, setSessionId])

  if (!data || outdated || !loaded) {
    return null;
  }

  return (
    <AppContextProvider value={{setSessionId, hasSession, isExternal}}>
      <ApolloProvider client={client}>
        <ModalProvider>
          <ErrorBoundaryWithBugReport>
            <PromblemsWatcherProvider>
              <GuideProvider>
                <TourProvider>
                  <Router history={history}>
                    {!noAnalytics && (
                      <AnalyticsTracker user={user ?? null}/>
                    )}

                    <Switch>
                      <Route exact path={path("home")} component={WelcomeScene}/>

                      <Route path={path("login")} component={LoginBundle}/>
                      <Route path={path("editor")} component={EditorBundle}/>
                      <Route path={path("player")} component={PlayerBundle}/>
                      <Route path={path("accountMembers")} component={AccountBundle}/>
                      <Route path={path("analytics")} component={AnalyticsBundle}/>
                      <Route path={path("service")} component={ServiceBundle}/>
                      <Route path={path("lms")} component={LmsBundle}/>

                      <Route component={PageNotFoundScene}/>
                    </Switch>
                  </Router>
                </TourProvider>
              </GuideProvider>
            </PromblemsWatcherProvider>
          </ErrorBoundaryWithBugReport>
        </ModalProvider>
      </ApolloProvider>
    </AppContextProvider>
  )
}

export default memo(App);
