import React, {ComponentProps, useCallback, useEffect, useMemo} from "react";
import {
  Lesson,
  LessonMode,
  Scalars,
  Scenario,
} from "../../schema";
import {classes as tourClasses} from "../../scenes/editor/lesson-edit.tour"
import Timeline from "./Timeline";
import ActionEditForm from "./actions-forms/ActionEditForm";
import {useTranslation} from "react-i18next";
import {useModal} from "../ModalProvider";
import {useAudio} from "../../providers/audio";

import LessonActionsAddRow from "./LessonActionsAddRow";
import {Action, ActionInput} from "../../types";
import {DragDropContext, Draggable, DropResult} from "react-beautiful-dnd";
import LessonAction from "./LessonAction";

type ActionProps = ComponentProps<typeof LessonAction> & {
  index: number
}

function ActionItem({index, ...props}: ActionProps) {
  return (
    <Draggable draggableId={props.action.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <LessonAction {...props}/>
        </div>
      )}
    </Draggable>
  );
}

type props = {
  className?: string

  lesson: Lesson,
  scenarios?: Scenario[],
  actions: Action[],

  disabled?: boolean,

  onAdd?: (actionType: Action["__typename"], data: ActionInput) => void
  onUpdate?: (actionId: string, actionType: Action["__typename"], data: ActionInput) => void
  onDelete?: (actionId: string) => void
}

export default function LessonActionsList({className, scenarios, actions, disabled, ...props}: props) {
  const {t} = useTranslation();

  const {onUpdate, onAdd} = props;

  const {add: addModal} = useModal();
  const {audioManager} = useAudio();

  const noAvatar = useMemo(() => {
    return props.lesson.avatar.id === "none";
  }, [props.lesson])

  const customScores = useMemo(() => {
    return props.lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES ||
      props.lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST;
  }, [props.lesson.mode])

  const customParametersSupport = useMemo(() => {
    return props.lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST;
  }, [props.lesson.mode])

  const customParameters = useMemo(() => {
    return props.lesson.customParameters;
  }, [props.lesson.customParameters])

  useEffect(() => () => {
    audioManager.getPlayback("editor")?.abortPlay();
  }, [audioManager]);

  const addHandler = React.useCallback((type: Action["__typename"]) => {
    onAdd && onAdd(type, {});
  }, [onAdd])

  const editActionModal = useCallback((action: Action, branchId?: Scalars["ID"]) => {
    const modal = addModal({
      id: `EditAction_${action.id}`,
      // size: "l",
      header: t("components.LessonActions.editModalTitle"),
      content: (
        <ActionEditForm
          action={action}
          branchId={branchId}
          scenarios={scenarios}
          noAvatar={noAvatar}
          customScores={customScores}
          customParametersSupport={customParametersSupport}
          customParameters={customParameters}
          submitDisabled={disabled}
          onSubmit={(data) => {
            onUpdate && onUpdate(action.id, action.__typename, data);
            modal.remove();
          }}
        />
      )
    }, true);
  }, [scenarios, noAvatar, customScores, customParametersSupport, customParameters, disabled, addModal, t, onUpdate]);

  const onDragEnd = useCallback((result: DropResult) => {
    const {destination, source} = result;
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const draggedAction = actions[source.index];
    const beforeActionIndex = destination.index > source.index ? destination.index + 1 : destination.index;
    if (beforeActionIndex === actions.length) {
      onUpdate && onUpdate(draggedAction.id, draggedAction.__typename, {
        beforeActionId: null
      })
    } else {
      const targetAction = actions[beforeActionIndex]
      onUpdate && onUpdate(draggedAction.id, draggedAction.__typename, {
        beforeActionId: targetAction.id
      })
    }
  }, [actions, onUpdate])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Timeline className={className} extra={(
        <LessonActionsAddRow
          className={tourClasses.addActionRow}
          onAdd={addHandler}
          showHelpIcon
          disabled={disabled}
        />
      )}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {actions.length > 0 ? actions.map((action, index) => (
              <ActionItem
                action={action}
                scenarios={scenarios}
                index={index}
                editActionModal={editActionModal}
                disabled={disabled}
                key={action.id}
                {...props}/>
            )): (
              <LessonAction.Placeholder/>
            )}
            {provided.placeholder}
          </div>
        )}
      </Timeline>
    </DragDropContext>
  );
}

LessonActionsList.Skeleton = React.memo(function () {
  return (
    <Timeline.Skeleton>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow width={200}/>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow width={200}/>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow/>
      <Timeline.SkeletonRow width={200}/>
    </Timeline.Skeleton>
  )
})
