import {gql} from "@apollo/client";
import {ServiceAudio} from "../../schema";

export type QueryData = {
  audios: ServiceAudio[]
}

const query = gql`
  query GetServiceAudios {
    audios: getServiceAudios {
      id
      startedAt
      analysisState
      filepath
      duration
      deviceName
      userEmail
    }
  }
`;

export default query;