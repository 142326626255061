import {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";
import {Division} from "../../../schema";

import Table from "../../../ui/table";
import Button from "../../../ui/button";

import cn from "classnames"
import classes from "./DivisionsTable.module.css";


export type QueryData = {
  divisionData: {division: Division, level: number}[],
  onAdd: () => void,
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  divisionData: QueryData["divisionData"],
  onAdd: QueryData["onAdd"]
}

export default function DivisionsTable({divisionData, onAdd, className, ...props}: Props) {
  const {t} = useTranslation();

  return (
    <>
      <Table className={cn(classes.root, className)} {...props}>
        <Table.Head>
          <Table.Row>
            <Table.Cell>{t("components.DevicesTable.name")}</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {divisionData && divisionData.map((div) => (
            <Table.Row key={div.division.id}>
              <Table.Cell>
                <span style={{marginLeft: `${div.level*15}px`}}>{div.division.name}</span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Button onClick={onAdd}>
        {t("components.DivisionsTable.add")}
      </Button>
    </>
  )
}

DivisionsTable.fragments = {
  root: gql`
    fragment DivisionsTable on Division {
      id
      name
      parentId
      users {
        id
        email
      }
    }
  `,
}
