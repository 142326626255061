import React, {useContext} from "react";
import {gql, useQuery} from "@apollo/client";
import {Account, Editor, Student} from "./schema";
import {AppAccountFragment, AppUserFragment} from "./App.graphql";

const Context = React.createContext<{
  isExternal: boolean
  setSessionId: (sessionId: string | undefined) => void
  hasSession: () => boolean
}>(null!);

const AppContextProvider = Context.Provider;
export default AppContextProvider;

type CurrentUser = Pick<Student | Editor, "id" | "__typename" | "name" | "fullName" | "email" | "isStaff"
  | "isDeveloper" | "isExperimental" | "experimentalMode" | "accountsCount" | "extraEmail" | "isEmailActivated">;
type CurrentAccount = Pick<Account, "id" | "__typename" | "name" | "code" | "receiveReports" | "serviceSupport">;

export function useCurrentUser(): CurrentUser | null {
  const {data} = useQuery(gql`
    query CurrentUserCacheQuery {
      user: getCurrentUser { id ...AppUser }
    }

    ${AppUserFragment}
  `, {fetchPolicy: "cache-only"});

  return data.user ?? null;
}

export function useCurrentAccount(): CurrentAccount | null {
  const {data} = useQuery(gql`
    query CurrentAccountCacheQuery {
      account: getCurrentAccount { id ...AppAccount }
    }

    ${AppAccountFragment}
  `, {fetchPolicy: "cache-first"});

  return data.account ?? null;
}

export function useSession() {
  const ctx = useContext(Context);

  return {
    has: ctx.hasSession,
    set: ctx.setSessionId,
  }
}
