import React, {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AvatarEmotion, AvatarGesture, EducationSessionErrorReason, ErrorAudio} from "../../../../schema";
import Content from "./Content";
import {skipError} from "../../../../redux/player/actions";
import {getLastUserInputResult} from "../../../../redux/player/selectors";
import {PlayerReduxState} from "../../../../redux/player/types";
import classes from "./Content.module.css";
import {ERROR_DEFAULT_DURATION, ERROR_EXTENDED_DURATION} from "../../../../settings";
import {AudioManager} from "../../../../providers/audio";
import {isEqual} from "lodash";

type props = {
  isHintTextMode: boolean
  errorReason?: EducationSessionErrorReason | null
  recognizedText?: string
  audioManager?: AudioManager
  errorsAudio?: ErrorAudio[]
  useCharacterVoiceForErrors?: boolean
}

const UserInputErrorContent = ({
  isHintTextMode, errorReason, recognizedText, audioManager, errorsAudio, useCharacterVoiceForErrors, ...props
}: props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {lastUserInputResult} = useSelector(UserInputErrorContent.selector, isEqual)
  const handleSkipError = useCallback(() => dispatch(skipError()), [dispatch])

  const {text, audio} = useMemo(() => {
    let text: string;

    switch (errorReason) {
      case EducationSessionErrorReason.BAD_WORD:
        text = t("player.errorMessages.badWord")
        break;
      case EducationSessionErrorReason.STOP_WORDS:
        text = t("player.errorMessages.stopWords")
        break;
      case EducationSessionErrorReason.NO_ANSWER:
        text = t("player.errorMessages.noAnswer")
        break;
      case EducationSessionErrorReason.TIME_EXPIRED:
        text = t("player.errorMessages.timeExpired");
        break;
      case EducationSessionErrorReason.WRONG_CHOICE:
        text = t("player.errorMessages.wrongChoice");
        break;
      case EducationSessionErrorReason.MISSING_KEYWORD:
        text = t("player.errorMessages.missingKeyword");
        break;
      case EducationSessionErrorReason.DAILO_SPEECH_TIMEOUT:
        text = t("player.errorMessages.dailoSpeechTimeout");
        break;
      case EducationSessionErrorReason.DAILO_SPEECH_HTTP_ERROR:
        text = t("player.errorMessages.dailoSpeechHttpError");
        break;
      case EducationSessionErrorReason.DAILO_STT_ERROR:
        text = t("player.errorMessages.dailoSttError");
        break;
      default:
        console.error(`No realization for EducationSessionErrorReason: ${errorReason} in Modern player`)
      // falls through
      case EducationSessionErrorReason.WRONG_ANSWER:
        text = t("player.errorMessages.wrongAnswer");
        break;
    }

    if (isHintTextMode) {
      text += t("player.errorMessages.expectedTextExtra")
    }

    const audio = errorsAudio?.find(audio => audio.errorReason.toString() === errorReason)

    return {text, audio};
  }, [errorReason, errorsAudio, isHintTextMode, t])

  useEffect(() => {
    const t = setTimeout(() => handleSkipError(), isHintTextMode ? ERROR_EXTENDED_DURATION : ERROR_DEFAULT_DURATION);
    return () => clearTimeout(t);
  }, [handleSkipError, isHintTextMode]);

  useEffect(() => {
    if (useCharacterVoiceForErrors && lastUserInputResult?.errorState) {
      const audioPlayback = audioManager?.getPlayback("player/avatar")
      audioPlayback && audio && audioPlayback.play(audio.filepath ?? undefined, {
        gesture: audio.gesture ?? AvatarGesture.IDLE,
        emotion: audio.emotion ?? AvatarEmotion.IDLE,
      });
    }
  }, [audio, audioManager, lastUserInputResult, useCharacterVoiceForErrors]);

  return (
    <Content
      className={classes.content}
      variant="error"
      title={t("common.error")}
      {...props}
    >
      {text}
      {recognizedText && (
        <Content.Extra translate="no">
          {t("player.recognizedText")}: {recognizedText}
        </Content.Extra>
      )}
    </Content>
  )
}

UserInputErrorContent.selector = (state: PlayerReduxState) => ({
  lastUserInputResult: getLastUserInputResult(state)
})


export default UserInputErrorContent;
