/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react";
import {gql} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {
  Avatar,
  CutWordsDictionary,
  HotWordsDictionary,
  Lesson, LessonBadWordsFilter, LessonDisplayMode,
  LessonInput, LessonInputMode, LessonMode, LessonRecognitionAccuracy,
  ResultDescription,
  ResultDescriptionInput,
  TriggerDictionaryType
} from "../../schema";
import {useFormState} from "../../hooks/useFormState";
import {useGuide} from "../../providers/guide";
import {classes as tourClasses} from "../../scenes/editor/lesson-edit.tour";

import AvatarSelect from "./AvatarSelect";
import Select from "../../ui/select";
import WithTooltip from "../../ui/tooltip";
import Checkbox from "../../ui/checkbox";
import FileInput from "./FileInput";
import {AddIcon, HelpCircleIcon} from "../../ui/icons";
import Link from "../../ui/link";

import cn from "classnames";
import classes from "./LessonCustomizeForm.module.css";
import Button from "../../ui/button";
import {useModal} from "../ModalProvider";
import EditableTextarea from "./EditableTextarea";
import {
  useAddResultDescriptionMutation,
  useDeleteResultDescriptionMutation,
  useUpdateResultDescriptionMutation,
} from "../../scenes/editor/lesson-edit.graphql";
import {getLanguages} from "../../i18n";
import {INPUT_PHRASE_MAX_LENGTH} from "../../settings";
import useVisibility from "../../hooks/useVisibility";
import {analyticsSendEvent} from "../../libs/analytics";
import {useCurrentUser} from "../../App.context";
import ResultDescriptionModal from "./ResultDescriptionModal";
import Input from "../../ui/input";
import LessonDictionaryForm from "./LessonDictionaryForm";
import PhrasesEdit from "./PhrasesEdit";

type props = {
  className?: string,
  lesson: Lesson,
  triggerDicts: TriggerDictionaryType[] | undefined,
  cutWordsDicts: CutWordsDictionary[] | undefined,
  hotWordsDicts: HotWordsDictionary[] | undefined,
  resultDescriptions: ResultDescription[] | undefined,
  avatars: Avatar[],
  disabled?: boolean,
  onSave?: (data: LessonInput) => void,
}

type formState = {
  avatarId: NonNullable<LessonInput["avatarId"]>,
  languageCode: NonNullable<LessonInput["languageCode"]>,
  mode: NonNullable<LessonInput["mode"]>
  background?: LessonInput["background"],
  displayMode: LessonInput["displayMode"],
  inputMode: NonNullable<LessonInput["inputMode"]>,
  badWordsFilter: LessonInput["badWordsFilter"],
  generateVideo?: NonNullable<LessonInput["generateVideo"]> | "on",
  showDetailedResults?: NonNullable<LessonInput["showDetailedResults"]> | "on",
  evaluateScenarios?: NonNullable<LessonInput["evaluateScenarios"]> | "on",
  evaluateQuizScore?: NonNullable<LessonInput["evaluateQuizScore"]> | "on",
  inviteByLink?: NonNullable<LessonInput["inviteByLink"]> | "on",
  useCharacterVoiceForErrors?: NonNullable<LessonInput["useCharacterVoiceForErrors"]> | "on",
  recognitionAccuracy: NonNullable<LessonInput["recognitionAccuracy"]>,
  startMessage?: NonNullable<LessonInput["startMessage"]>,
  resultMessage?: NonNullable<LessonInput["resultMessage"]>,
  attemptsLimit?: NonNullable<LessonInput["attemptsLimit"]>,
  timeLimit?: NonNullable<LessonInput["timeLimit"]>
  customParameters: NonNullable<LessonInput["customParameters"]>
}

export default function LessonCustomizeForm(
  {className, lesson, triggerDicts, cutWordsDicts, hotWordsDicts, resultDescriptions, avatars, disabled, onSave}: props
) {
  const {t} = useTranslation();

  const inExperimentalMode = useCurrentUser()?.experimentalMode
  const avatarsEx = useMemo(() => {
    let avatarsEx = [...avatars];

    if (!avatarsEx.find(avatar => avatar.id === lesson.avatar.id)) {
      avatarsEx.unshift(lesson.avatar);
    }

    return avatarsEx;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.avatar.id, lesson.mode, ...(avatars.map(a => a.id))])

  const formState = useFormState<formState>({
    initialValues: {
      avatarId: lesson.avatar.id,
      languageCode: lesson.languageCode,
      mode: lesson.mode,
      displayMode: lesson.displayMode,
      inputMode: lesson.inputMode,
      badWordsFilter: lesson.badWordsFilter,
      generateVideo: lesson.generateVideo ? "on" : undefined,
      showDetailedResults: lesson.showDetailedResults ? "on" : undefined,
      evaluateScenarios: lesson.evaluateScenarios ? "on" : undefined,
      evaluateQuizScore: lesson.evaluateQuizScore ? "on" : undefined,
      inviteByLink: lesson.inviteByLink ? "on" : undefined,
      useCharacterVoiceForErrors: lesson.useCharacterVoiceForErrors ? "on" : undefined,
      recognitionAccuracy: lesson.recognitionAccuracy,
      startMessage: lesson.startMessage ?? undefined,
      resultMessage: lesson.resultMessage ?? undefined,
      attemptsLimit: lesson.attemptsLimit ?? 0,
      timeLimit: lesson.timeLimit ?? 0,
      customParameters: lesson.customParameters
    },
    preventDefault: true,
    onChange: (data) => {
      onSave && onSave({
        ...data,
        generateVideo: data.generateVideo === "on",
        showDetailedResults: data.showDetailedResults === "on",
        evaluateScenarios: data.evaluateScenarios === "on",
        evaluateQuizScore: data.evaluateQuizScore === "on",
        inviteByLink: data.inviteByLink === "on",
        useCharacterVoiceForErrors: data.useCharacterVoiceForErrors === "on",
        background: data.background === "" ? null : data.background,
        displayMode: data.displayMode === undefined ? LessonDisplayMode.DEFAULT : data.displayMode,
        inputMode: data.inputMode === undefined ? LessonInputMode.VOICE : data.inputMode,
      })
    }
  });

  const lessonModeHelpText = useMemo(() => [
    "<p>" + t("components.LessonCustomizeForm.lessonModeHelpText.STUDY_THREE_STEP") + "</p>",
    "<p>" + t("components.LessonCustomizeForm.lessonModeHelpText.STUDY_TWO_STEP") + "</p>",
    "<p>" + t("components.LessonCustomizeForm.lessonModeHelpText.CHOICES_TEST_TWO_STEP") + "</p>",
    "<p>" + t("components.LessonCustomizeForm.lessonModeHelpText.CHOICE_BASED_STUDY") + "</p>",
    "<P>" + t("components.LessonCustomizeForm.lessonModeHelpText.CHOICES_WITH_CUSTOM_SCORE") + "</p>",
    "<P>" + t("components.LessonCustomizeForm.lessonModeHelpText.CUSTOM_PARAMETERS_TEST") + "</p>",
    "<P>" + t("components.LessonCustomizeForm.lessonModeHelpText.QUIZ") + "</p>",
    "<p>" + t("components.LessonCustomizeForm.lessonModeHelpText.INTERVIEW") + "</p>",
  ].join("\n"), [t]);

  const imageSrc = (!!formState.values.background || lesson.background) && formState.values.background !== ""
    ? (formState.values.background ?? lesson.background ?? undefined)
    : undefined

  const {open: openGuide} = useGuide();

  const onLessonTypeHelpClick = useCallback(() => {
    openGuide("editor.lesson.modes");
  }, [openGuide]);

  const {add: addModal} = useModal();

  const [applyAddResultDescriptionMutation] = useAddResultDescriptionMutation();
  const [applyUpdateResultDescriptionMutation] = useUpdateResultDescriptionMutation();
  const [applyDeleteResultDescriptionMutation] = useDeleteResultDescriptionMutation();

  const onResultDescAdd = useCallback((data: ResultDescriptionInput) => {
    const lessonId = lesson.id;
    analyticsSendEvent("editorLessonResultDescAdd", {lessonId});

    applyAddResultDescriptionMutation({
      variables: {data, lessonId},
      refetchQueries: ["EditorEditLessonSceneQuery"]
    });
  }, [applyAddResultDescriptionMutation, lesson]);

  const onResultDescUpdate = useCallback((data: ResultDescriptionInput, resultDescriptionId: string) => {
      const lessonId = lesson.id;
      analyticsSendEvent("editorLessonResultDescUpdate", {lessonId, resultDescriptionId});

      applyUpdateResultDescriptionMutation({
        variables: {data, lessonId, resultDescriptionId},
        refetchQueries: ["EditorEditLessonSceneQuery"]
      });
  }, [applyUpdateResultDescriptionMutation, lesson]);

  const onResultDescDelete = useCallback((lessonId: string, resultDescriptionId: string) => {
      analyticsSendEvent("editorLessonResultDescDelete", {lessonId, resultDescriptionId});
      applyDeleteResultDescriptionMutation({
        variables: {lessonId, resultDescriptionId},
        refetchQueries: ["EditorEditLessonSceneQuery"]
      });
  }, [applyDeleteResultDescriptionMutation]);

  const onAddResultDescButtonClick = useCallback(() => {
    const modal = addModal({
      header: t("components.ResultDesc.editModalTitle"),
      content: (
        <ResultDescriptionModal.Content
          onSave={(data) => {
            onResultDescAdd && onResultDescAdd(data);
            modal.remove();
          }}
        />
      )
    }, true);
  }, [addModal, t, onResultDescAdd]);

  const onUpdateResultDescButtonClick = useCallback((e: React.MouseEvent) => {
    const descId = e.currentTarget.getAttribute("data-descid")!;
    const lessonId = lesson.id;
    const modal = addModal({
      header: t("components.ResultDesc.editModalTitle"),
      content: (
        <ResultDescriptionModal.Content
          desc={resultDescriptions?.find(desc => desc.id === descId)}
          onSave={(data) => {
            onResultDescUpdate && onResultDescUpdate(data, descId);
            modal.remove();
          }}
          onDelete={() => {
            onResultDescDelete && onResultDescDelete(lessonId, descId)
            modal.remove();
          }}
        />
      )
    }, true);
  }, [addModal, t, onResultDescUpdate, onResultDescDelete, lesson, resultDescriptions]);

  const [allResultDescsVisible, showAllResultDescs, showLessResultDescs] = useVisibility();

  const displayResultDescs = useMemo(() => {
    if (!resultDescriptions) {
      return;
    }
    if (!allResultDescsVisible && resultDescriptions.length > 3) {
      return resultDescriptions.slice(0, 3)
    }
    return resultDescriptions;
  }, [allResultDescsVisible, resultDescriptions])

  const customParametersTextareaProps = useMemo(() => ({
    maxLength: INPUT_PHRASE_MAX_LENGTH,
    rows: 1,
    autoComplete: "off",
    placeholder: t("components.LessonCustomizeForm.customParameters.placeholder")
  }), [t])

  const updateCustomParameters = useCallback((customParameters: string[]) => {
    formState.setValues({customParameters})
  }, [formState])

  return (
    <form className={className} method="post" onSubmit={formState.submitHandler}>
      <div className={classes.formItem}>
        <label className={classes.label}>{t("components.LessonCustomizeForm.avatar")}</label>
        <AvatarSelect
          className={tourClasses.avatarSelect}
          name="avatarId"
          avatars={avatarsEx}
          value={formState.values.avatarId}
          onChange={disabled ? undefined : formState.changeHandler}
        />
      </div>

      <div className={cn(classes.formItem, tourClasses.videoCallMode)}>
        <label className={cn(classes.label, classes.checkboxLabel)}>
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonCustomizeForm.videoCallMode.helpText")}>
            <Checkbox
              name="displayMode"
              checked={formState.values.displayMode === LessonDisplayMode.VIDEO_CALL}
              value={LessonDisplayMode.VIDEO_CALL}
              disabled={disabled}
              onChange={formState.changeHandler}
            />
            <span className={classes.checkboxText}>{t("components.LessonCustomizeForm.videoCallMode.label")}</span>
          </WithTooltip>
        </label>
      </div>

      <div className={cn(classes.formItem, tourClasses.lessonType)}>
        <label className={classes.label}>
          {t("components.LessonCustomizeForm.lessonType")}
          <Link className={classes.moreInfo} onClick={onLessonTypeHelpClick}>
            {t("components.LessonCustomizeForm.more")}
          </Link>
        </label>
        <WithTooltip as='span' placement="left" className={classes.help} helpText={lessonModeHelpText}>
          <Select name="mode" value={formState.values.mode} disabled={disabled} onChange={formState.changeHandler}>
            <option value={LessonMode.STUDY_THREE_STEP}>
              {t("types.LessonMode.STUDY_THREE_STEP")}
            </option>
            <option value={LessonMode.STUDY_TWO_STEP}>
              {t("types.LessonMode.STUDY_TWO_STEP")}
            </option>
            <option value={LessonMode.CHOICES_TEST_TWO_STEP}>
              {t("types.LessonMode.CHOICES_TEST_TWO_STEP")}
            </option>
            <option value={LessonMode.CHOICE_BASED_STUDY}>
              {t("types.LessonMode.CHOICE_BASED_STUDY")}
            </option>
            <option value={LessonMode.CHOICES_WITH_CUSTOM_SCORES}>
              {t("types.LessonMode.CHOICES_WITH_CUSTOM_SCORES")}
            </option>
            {inExperimentalMode && (
              <option value={LessonMode.CUSTOM_PARAMETERS_TEST}>
                {t("types.LessonMode.CUSTOM_PARAMETERS_TEST")}
              </option>
            )}
            <option value={LessonMode.QUIZ}>
              {t("types.LessonMode.QUIZ")}
            </option>
            <option value={LessonMode.INTERVIEW}>
              {t("types.LessonMode.INTERVIEW")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      {inExperimentalMode && lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST && (
        <div className={classes.formItem}>
          <label className={classes.label} htmlFor="customParameters">
            {t("components.LessonCustomizeForm.customParameters.title")}{" "}
            <WithTooltip className={classes.help} as='span'
              helpText={t("components.LessonCustomizeForm.customParameters.help")}
            ><HelpCircleIcon/></WithTooltip>
          </label>
          <PhrasesEdit
            value={formState.values.customParameters}
            onValueChange={updateCustomParameters}
            textareaProps={customParametersTextareaProps}
          />
        </div>
      )}

      <div className={classes.formItem}>
        <label className={classes.label}>
          {t("components.LessonCustomizeForm.lessonInput")}
        </label>
        <WithTooltip
          className={classes.help}
          as='span'
          helpText={t("components.LessonCustomizeForm.inputMode.helpText")}
        >
          <Select
            name="inputMode"
            value={formState.values.inputMode}
            disabled={disabled}
            onChange={formState.changeHandler}
          >
            <option value={LessonInputMode.VOICE}>
              {t("types.LessonInputMode.VOICE")}
            </option>
            <option value={LessonInputMode.TEXT}>
              {t("types.LessonInputMode.TEXT")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <div className={cn(classes.formItem, tourClasses.language)}>
        <label className={classes.label}>{t("components.LessonCustomizeForm.lang")}</label>
        <Select name="languageCode" className={classes.langSelector} value={formState.values.languageCode}
                disabled={disabled} onChange={formState.changeHandler}>
          {getLanguages().map(lang => (
            <option key={lang.lang} value={lang.languageCode}>{lang.verboseName}</option>
          ))}
        </Select>
      </div>

      <div className={cn(classes.formItem, tourClasses.recognitionAccuracy)}>
        <label className={classes.label}>{t("components.LessonCustomizeForm.recognitionAccuracy.label")}</label>
        <Select name="recognitionAccuracy" value={formState.values.recognitionAccuracy}
                disabled={disabled} onChange={formState.changeHandler}>
          <option value={LessonRecognitionAccuracy.WEAK}>
            {t("components.LessonCustomizeForm.recognitionAccuracy.weak")}
          </option>
          <option value={LessonRecognitionAccuracy.NORMAL}>
            {t("components.LessonCustomizeForm.recognitionAccuracy.normal")}
          </option>
          <option value={LessonRecognitionAccuracy.STRONG}>
            {t("components.LessonCustomizeForm.recognitionAccuracy.strong")}
          </option>
        </Select>
      </div>

      <div className={cn(classes.formItem, tourClasses.badWordsFilter)}>
        <label className={classes.label}>
          {t("components.LessonCustomizeForm.badWordsFilter.label")}
          {" "}
          <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonCustomizeForm.badWordsFilter.helpText")}>
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <WithTooltip className={classes.help} as='span'
                       helpText={t("components.LessonCustomizeForm.badWordsFilter.helpText")}>
          <Select name="badWordsFilter" value={formState.values.badWordsFilter ?? undefined}
                  disabled={disabled} onChange={formState.changeHandler}>
            <option value={LessonBadWordsFilter.NO}>
              {t("components.LessonCustomizeForm.badWordsFilter.no")}
            </option>
            <option value={LessonBadWordsFilter.FAIL}>
              {t("components.LessonCustomizeForm.badWordsFilter.fail")}
            </option>
            <option value={LessonBadWordsFilter.INTERRUPT}>
              {t("components.LessonCustomizeForm.badWordsFilter.interrupt")}
            </option>
          </Select>
        </WithTooltip>
      </div>

      <LessonDictionaryForm
        dictionaryType="trigger"
        triggerDicts={triggerDicts}
        lessonId={lesson.id}
        disabled={disabled}
      />

      {inExperimentalMode && (
        <LessonDictionaryForm
          dictionaryType="cutWords"
          cutWordsDicts={cutWordsDicts}
          lessonId={lesson.id}
          disabled={disabled}
        />
      )}

      {inExperimentalMode && (
        <LessonDictionaryForm
          dictionaryType="hotWords"
          hotWordsDicts={hotWordsDicts}
          lessonId={lesson.id}
          disabled={disabled}
        />
      )}

      {lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES && (
        <div className={cn(classes.formItem)}>
          <label className={classes.label}>
            {t("components.LessonCustomizeForm.resultDescriptions.label")}
            {" "}
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.LessonCustomizeForm.resultDescriptions.helpText")}>
              <HelpCircleIcon/>
            </WithTooltip>
          </label>

          {displayResultDescs && displayResultDescs?.length > 0 && (
              displayResultDescs?.map(desc => (
                <Button
                  className={cn(classes.listItem, classes.buttonBiggerText)}
                  onClick={onUpdateResultDescButtonClick}
                  key={desc.id}
                  data-descid={desc.id}
                  disabled={disabled}
                >
                  {`${desc.minScore}% - ${desc.maxScore}%`}
                </Button>
              ))
          )}
          {resultDescriptions && resultDescriptions.length > 3 && (
            allResultDescsVisible ? (
              <Link
                className={classes.listItem}
                onClick={showLessResultDescs}
              >{t("components.LessonCustomizeForm.resultDescriptions.showLess")}</Link>
            ) : (
              <Link
                className={classes.listItem}
                onClick={showAllResultDescs}
              >{t("components.LessonCustomizeForm.resultDescriptions.showAll")}</Link>
            )
          )}

          <Button
            className={classes.listItem}
            onClick={onAddResultDescButtonClick}
            disabled={disabled}
          >
            <AddIcon/>
            {t("common.add")}
          </Button>
        </div>
      )}

      <div className={cn(classes.formItem, tourClasses.generateVideo)}>
        <label className={cn(classes.label, classes.checkboxLabel)}>
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.LessonCustomizeForm.generateVideo.helpText")}>
            <Checkbox
              name="generateVideo"
              checked={formState.values.generateVideo === "on"}
              onChange={formState.changeHandler}
              disabled={disabled}
            />
            <span className={classes.checkboxText}>{t("components.LessonCustomizeForm.generateVideo.label")}</span>
          </WithTooltip>
        </label>
      </div>

      <div className={classes.formItem}>
        <label className={cn(classes.label, classes.checkboxLabel)}>
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.LessonCustomizeForm.showDetailedResults.helpText")}>
            <Checkbox
              name="showDetailedResults"
              checked={formState.values.showDetailedResults === "on"}
              onChange={formState.changeHandler}
              disabled={disabled}
            />
            <span className={classes.checkboxText}>
              {t("components.LessonCustomizeForm.showDetailedResults.label")}
            </span>
          </WithTooltip>
        </label>
      </div>

      <div className={classes.formItem}>
          <label className={cn(classes.label, classes.checkboxLabel)}>
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.LessonCustomizeForm.evaluateScenarios.helpText")}>
              <Checkbox
                name="evaluateScenarios"
                checked={formState.values.evaluateScenarios === "on"}
                onChange={formState.changeHandler}
                disabled={disabled}
              />
              <span className={classes.checkboxText}>
                {t("components.LessonCustomizeForm.evaluateScenarios.label")}
              </span>
            </WithTooltip>
          </label>
        </div>

      {lesson.mode === LessonMode.QUIZ && (
        <div className={classes.formItem}>
          <label className={cn(classes.label, classes.checkboxLabel)}>
            <WithTooltip className={classes.help} as='span'
                        helpText={t("components.LessonCustomizeForm.evaluateQuizScore.helpText")}>
              <Checkbox
                name="evaluateQuizScore"
                checked={formState.values.evaluateQuizScore === "on"}
                onChange={formState.changeHandler}
                disabled={disabled}
              />
              <span className={classes.checkboxText}>
                {t("components.LessonCustomizeForm.evaluateQuizScore.label")}
              </span>
            </WithTooltip>
          </label>
        </div>
      )}

      <div className={classes.formItem}>
        <label className={cn(classes.label, classes.checkboxLabel)}>
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.LessonCustomizeForm.inviteByLink.helpText")}>
            <Checkbox
              name="inviteByLink"
              checked={formState.values.inviteByLink === "on"}
              onChange={formState.changeHandler}
              disabled={disabled}
            />
            <span className={classes.checkboxText}>
              {t("components.LessonCustomizeForm.inviteByLink.label")}
            </span>
          </WithTooltip>
        </label>
      </div>

      <div className={classes.formItem}>
        <label className={cn(classes.label, classes.checkboxLabel)}>
          <WithTooltip className={classes.help} as='span'
                      helpText={t("components.LessonCustomizeForm.useCharacterVoiceForErrors.helpText")}>
            <Checkbox
              name="useCharacterVoiceForErrors"
              checked={formState.values.useCharacterVoiceForErrors === "on"}
              onChange={formState.changeHandler}
              disabled={disabled}
            />
            <span className={classes.checkboxText}>
              {t("components.LessonCustomizeForm.useCharacterVoiceForErrors.label")}
            </span>
          </WithTooltip>
        </label>
      </div>

      <div className={cn(classes.formItem, tourClasses.background)}>
        <label className={classes.label}>{t("components.LessonCustomizeForm.background")}</label>
        <FileInput
          variant='image'
          src={imageSrc}
          name="background"
          onDelete={formState.changeHandler}
          setValues={formState.setValues}
          disabled={disabled}
        />
      </div>

      {(lesson.mode === LessonMode.QUIZ) && (
        <div className={classes.formItem}>
          <label className={classes.label}>{t("components.LessonCustomizeForm.startMessage.label")}</label>
          <EditableTextarea.Wrapper multiline className={classes.nameWrapper}>
            <EditableTextarea
              maxLength={400}
              id="startMessage"
              name="startMessage"
              rows={4}
              defaultValue={formState.values.startMessage ?? undefined}
              onChange={formState.changeHandler}
              placeholder={t("components.LessonCustomizeForm.startMessage.placeholder")}
              buttonClassName={classes.inputButtons}
              disabled={disabled}
            />
          </EditableTextarea.Wrapper>
        </div>
      )}

      {(lesson.mode !== LessonMode.CHOICES_WITH_CUSTOM_SCORES) && (
        <div className={classes.formItem}>
          <label className={classes.label}>{t("components.LessonCustomizeForm.resultMessage.label")}</label>
          <EditableTextarea.Wrapper multiline className={classes.nameWrapper}>
            <EditableTextarea
              maxLength={1500}
              id="resultMessage"
              name="resultMessage"
              rows={4}
              defaultValue={formState.values.resultMessage ?? undefined}
              onChange={formState.changeHandler}
              placeholder={t("components.LessonCustomizeForm.resultMessage.placeholder")}
              buttonClassName={classes.inputButtons}
              disabled={disabled}
            />
          </EditableTextarea.Wrapper>
        </div>
      )}

      {inExperimentalMode &&
        lesson.mode !== LessonMode.STUDY_THREE_STEP &&
        lesson.mode !== LessonMode.STUDY_TWO_STEP && (
          <>
            <div className={classes.formItem}>
              <label className={classes.label}>
                {t("components.LessonCustomizeForm.attemptsLimit.label")}
                {" "}
                <WithTooltip className={classes.help} as='span'
                            helpText={t("components.LessonCustomizeForm.attemptsLimit.helpText")}>
                  <HelpCircleIcon/>
                </WithTooltip>
              </label>
              <WithTooltip className={classes.help} as='span'
                            helpText={t("components.LessonCustomizeForm.attemptsLimit.helpText")}>
              <Input
                style={{width: "100px", height: "40px"}}
                required
                min={0}
                type='number'
                name='attemptsLimit'
                value={formState.values.attemptsLimit}
                onChange={formState.changeHandler}
              />
              </WithTooltip>
            </div>

            <div className={classes.formItem}>
              <label className={classes.label}>
                {t("components.LessonCustomizeForm.timeLimit.label")}
                {" "}
                <WithTooltip className={classes.help} as='span'
                            helpText={t("components.LessonCustomizeForm.timeLimit.helpText")}>
                  <HelpCircleIcon/>
                </WithTooltip>
              </label>
              <WithTooltip className={classes.help} as='span'
                            helpText={t("components.LessonCustomizeForm.timeLimit.helpText")}>
              <Input
                style={{width: "100px", height: "40px"}}
                required
                min={0}
                type='number'
                name='timeLimit'
                value={formState.values.timeLimit}
                onChange={formState.changeHandler}
              />
              </WithTooltip>
            </div>
          </>
      )}
    </form>
  )
}

LessonCustomizeForm.fragments = {
  root: gql`fragment LessonCustomizeForm on Lesson {
    id
    languageCode
    recognitionAccuracy
    recognitionEngine
    mode
    displayMode
    inputMode
    generateVideo
    showDetailedResults
    evaluateScenarios
    evaluateQuizScore
    inviteByLink
    useCharacterVoiceForErrors
    startMessage
    resultMessage
    attemptsLimit
    timeLimit
    customParameters
    avatar {
      id
      ...AvatarSelect
    }
  }

  ${AvatarSelect.fragments.root}`,

  avatars: gql`fragment LessonCustomizeFormAvatars on Avatar {
    ...AvatarSelect
  }

  ${AvatarSelect.fragments.root}`
}
