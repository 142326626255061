/* eslint-disable max-lines */
import {gql, useMutation} from "@apollo/client";

import {
  Device,
  DeviceInput,
} from "../../schema";


export const query = gql`
  query GetDevices {
    devices: getDevices {
      id
      publicId
      name
      active
      lastUserEmail
    }
  }
`;

export const queryDev = gql`
  query GetAllDevices {
    devices: getAllDevices {
      id
      uuid
      publicId
      name
      active
      lastUserEmail
      accountName
    }
  }
`;

export function useUpdateDeviceMutation() {
  return useMutation<{
    device: Device
  }, {
    deviceId: Device["id"],
    data: DeviceInput
  }>(updateDeviceMutation);
}

const updateDeviceMutation = gql`
  mutation UpdateDeviceMutation($deviceId: ID!, $data: DeviceInput!) {
    device: updateDevice(id: $deviceId, data: $data) {
      id
      name
      active
    }
  }
`;


export function useAnalyzeServiceAudiosMutation() {
  return useMutation(analyzeServiceAudiosMutation);
}

const analyzeServiceAudiosMutation = gql`
  mutation AnalyzeServiceAudiosMutation {
    success: analyzeServiceAudios
  }
`;


export function useTryGetAnalysisResultsMutation() {
  return useMutation(tryGetAnalysisResultsMutation);
}

const tryGetAnalysisResultsMutation = gql`
  mutation TryGetAnalysisResultsMutation {
    success: tryGetAnalysisResults
  }
`;


export function useAddDeviceMutation() {
  return useMutation<{
    device: Device
  }, {
    data: DeviceInput
  }>(addDeviceMutation);
}

const addDeviceMutation = gql`
  mutation AddDeviceMutation($data: DeviceInput!) {
    device: addDevice(data: $data) {
      id
      name
      active
    }
  }
`;
