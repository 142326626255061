import React from "react";
import Textarea from "../../../ui/textarea";
import {useFormState} from "../../../hooks/useFormState";
import Button from "../../../ui/button";
import {useTranslation} from "react-i18next";
import {CustomDictionary, CustomDictionaryInput} from "../../../schema";
import Input from "../../../ui/input";

import classes from "./CustomDictionaryModal.module.css";
import CustomDictionaryColorSelect from "./CustomDictionaryColorSelect";

type contentProps = React.ComponentProps<"div"> & {
  dict?: CustomDictionary
  onSave?: (data: CustomDictionaryInput) => void
  onDelete?: (e: React.MouseEvent) => void
}

function CustomDictionaryModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.CustomDictionaryModal.title")}</span>
}

 function CustomDictionaryModalContent({dict, onSave, onDelete}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    name: string,
    words: string,
    color: number,
  }>({
    initialValues: {
      name: dict?.name ?? "",
      words: dict?.words ?? "",
      color: dict?.color ?? 0,
    },

    preventDefault: true,

    onSubmit: (data) => {
      onSave && onSave(data);
    }
  });


  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.CustomDictionaryModal.nameHelpText")}</p>
        <Input
          className={classes.input}
          name="name"
          value={formState.values.name ?? ""}
          required
          maxLength={36}
          placeholder={t("components.CustomDictionaryModal.namePlaceholder")}
          onChange={formState.changeHandler}
        />

        <div className={classes.colors}>
          <p className={classes.helpText}>{t("components.CustomDictionaryModal.colorHelpText")}</p>
          <CustomDictionaryColorSelect
            name={"color"}
            value={formState.values.color!}
            onChange={formState.changeHandler}
          />
        </div>

        <p className={classes.helpText}>{t("components.CustomDictionaryModal.phrasesHelpText")}</p>
        <Textarea
          className={classes.input}
          name="words"
          value={formState.values.words}
          rows={8}
          required
          placeholder={t("components.CustomDictionaryModal.phrasesPlaceholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={"success"}>
          {t("components.CustomDictionaryModal.submit")}
        </Button>
        {dict && (
          <Button data-id={dict.id} type="button" color="danger" onClick={onDelete}>
            {t("components.CustomDictionaryModal.delete")}
          </Button>
        )}
      </div>
    </form>
  )
}

const CustomDictionaryModal = {
  Header: CustomDictionaryModalHeader,
  Content: CustomDictionaryModalContent,
}

export default CustomDictionaryModal;
