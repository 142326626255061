import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {query, useUpdateDeviceMutation} from "./devices.graphql";
import {
  Device,
  DeviceInput,
} from "../../schema";

import ReactLink from "../../components/ReactLink";
import {useModal} from "../../components/ModalProvider";
import {reverse} from "../../routing";
import {analyticsSendEvent} from "../../libs/analytics";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import DevicesTable, {Props as DevicesTableProps} from "../../components/service/devices-table/DevicesTable";
import RenameDeviceModal from "../../components/service/devices-table/RenameDeviceModal";
import NavigationMenu from "../../components/service/NavigationMenu";

import classes from "./index.module.css";

export default function DevicesScene() {
  const {t} = useTranslation();

  const {data, loading: fetching} = useQuery(query);
  const {devices} = data ?? {};
  const loading = !data && fetching;

  const {add: addModal} = useModal();

  const [mutateUpdateDevice] = useUpdateDeviceMutation();

  const updateDevice = useCallback((deviceId: Device["id"], data: DeviceInput) => {
    mutateUpdateDevice({
      variables: {deviceId, data}
    });
  }, [mutateUpdateDevice])

  const renameDevice = useCallback((deviceId: Device["id"], name: string) => {
    analyticsSendEvent("serviceDevicesRenameDevice", {deviceId, name});
    updateDevice(deviceId, {name});
  }, [updateDevice])

  const onRenameDevice = useCallback<DevicesTableProps["onRenameDevice"]>((device) => {
    if (!devices) {
      return;
    }

    const onRenameDevice = (name: string) => {
      renameDevice(device.id, name);
      modal.remove();
    }

    const modal = addModal({
      id: `renameDevice_${device.id}`,
      header: <RenameDeviceModal.Header/>,
      content: <RenameDeviceModal.Content onRenameDevice={onRenameDevice} device={device} devices={devices}/>
    })
  }, [devices, renameDevice, addModal]);

  const toggleDeviceStatus = useCallback((deviceId: Device["id"], active: boolean) => {
    analyticsSendEvent("serviceDevicesSetDeviceStatus", {deviceId, active});
    updateDevice(deviceId, {active: active});
  }, [updateDevice])

  const onToggleDeviceStatus = useCallback<DevicesTableProps["onSetStatus"]>((device) => {
    if (!devices) {
      return;
    }
    toggleDeviceStatus(device.id, device.active ? false : true);
  }, [devices, toggleDeviceStatus]);

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.devices")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.devices.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <DevicesTable
                    devices={devices}
                    onRenameDevice={onRenameDevice}
                    onSetStatus={onToggleDeviceStatus}
                  />
                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
