import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router";
import {Trans, useTranslation} from "react-i18next";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Input from "../../ui/input";
import Button from "../../ui/button";
import {TERMS_OF_USE_LINK, USER_AGREEMENT_LINK} from "../../settings";
import {reverse} from "../../routing";
import {useFormState} from "../../hooks/useFormState";
import LoginLayout from "../../components/login/LoginLayout";
import classes from "./login.module.css";
import {useServiceLoginMutation} from "./login.graphql";
import useAutoResetState from "../../hooks/useAutoResetState";
import {CloseIcon} from "../../ui/icons";
import Animated from "../../ui/animated";
import ReactLink from "../../components/ReactLink";
import {analyticsSendEvent} from "../../libs/analytics";
import cn from "classnames";

export default function ServiceLoginScene() {
  const {t} = useTranslation();
  const history = useHistory();

  const [urlError, setUrlError] = useState<string | null>(null);

  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const deviceId = params.get("_device");

  useEffect(() => {
    setUrlError(params.get("error"));
  }, [params]);

  const [error, setError] = useAutoResetState<boolean>(false, 1000);

  const [login, {loading: loginLoading}] = useServiceLoginMutation();

  const [statusDisplay, setStatusDisplay] = useState<ReactNode | undefined>(<div/>);
  const [authSuccess, setAuthSuccess] = useState<boolean>(false);

  const loginForm = useFormState({
    initialValues: {
      username: "",
      password: "",
    },

    preventDefault: true,
    onChange: () => {
      setAuthSuccess(false);
    },
    onSubmit: (values) => {
      if (!deviceId) {
        setError(true);
        setStatusDisplay(
          <div className={cn(classes.extraRow, classes.error)}>
            {t("serviceLogin.noDeviceId")}
          </div>
        )
        return;
      }

      login({
        variables: {
          email: values.username,
          password: values.password,
          deviceId: deviceId,
        }
      }).then(({data}) => {
        const status = data?.status
        if (status) {
          analyticsSendEvent("serviceLogin");
          setStatusDisplay(
            <div className={cn(classes.extraRow, classes.success)}>
              {t("serviceLogin.success")}
            </div>
          )
          setAuthSuccess(true);
        }

        if (!status) {
          setError(true);
          setStatusDisplay(
            <div className={cn(classes.extraRow, classes.error)}>
              {t("serviceLogin.fail")}
            </div>
          )
          return;
        }
      });
    }
  });

  const onForgotPasswordClick = useCallback(() => {
    analyticsSendEvent("useForgotPasswordLink");
  }, []);

  const errorMessagesMap = useMemo(() => new Map<string, string>([
    ["provider_denied", t("login.error.provider.denied")],
    ["provider_no_access_token", t("login.error.provider.noAccessToken")],
    ["no_provider", t("login.error.noProvider")],
    ["session_inactive", t("login.error.sessionInactive")]
  ]), [t])

  if (urlError) {
    return (
      <>
        <ReactDocumentTitle title={t("login.error.title")}/>

        <LoginLayout data-testid="serviceLoginScene" className={classes.root}>
          <h1 className={classes.h1}>{t("login.error.h1")}</h1>
          <p>
            {errorMessagesMap.has(urlError) ? errorMessagesMap.get(urlError) : t("login.error.default")}
          </p>
        </LoginLayout>
      </>
    )
  }

  return (
    <>
      <ReactDocumentTitle title={t("login.title")}/>

      <LoginLayout data-testid="serviceLoginScene" className={classes.root}>
        <h1 className={classes.h1}>{t("login.h1")}</h1>

        <form onSubmit={loginForm.submitHandler} method="post" className={classes.loginForm}>
          <Input
            required
            pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$|[a-zA-Z0-9._-]{4,}$"
            title={t("login.usernameTip")}
            disabled={loginLoading}
            className={classes.loginFormInput}
            name='username'
            value={loginForm.values.username}
            onChange={loginForm.changeHandler}
            placeholder={t("login.username")}
          />

          <Input
            required
            disabled={loginLoading}
            className={classes.loginFormInput}
            name='password'
            value={loginForm.values.password}
            onChange={loginForm.changeHandler}
            type="password"
            placeholder={t("login.password")}
          />

          <div className={classes.loginFormSubmit}>
            <div className={classes.loginFormAcceptTerms}>
              <Trans i18nKey="login.acceptTerms" components={[
                <a href={USER_AGREEMENT_LINK}> </a>,
                <a href={TERMS_OF_USE_LINK}> </a>,
              ]}/>
            </div>
            <div className={classes.loginFormSubmitBtn}>
              {!error ? (
                <Button
                  disabled={loginLoading || authSuccess}
                  variant="morph"
                  color={authSuccess ? "success" : "primary"}
                  size='l'
                >
                  {t("login.submit")}
                </Button>
              ) : (
                <Animated type="shake">
                  <Button disabled type="button" variant="morph" color="danger" size='l'><CloseIcon/></Button>
                </Animated>
              )}
            </div>
          </div>
          <div className={classes.extraRow}>
            <ReactLink
              className={classes.forgotPasswordLink}
              onClick={onForgotPasswordClick}
              href={reverse("loginForgotPassword")}
            >{t("login.forgotPassword")}</ReactLink>
          </div>
          <div className={classes.extraRow}>
            {statusDisplay}
        </div>
        </form>
      </LoginLayout>
    </>
  )
}
