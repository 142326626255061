import React, {useCallback, useEffect, useMemo, useState} from "react";
import {EducationSessionRecordState, Lesson} from "../../schema";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import isEqual from "lodash/isEqual";
import {useLazyQuery, useQuery} from "@apollo/client";
import Layout, {Header, Main} from "../../components/Layout";
import classes from "./player.module.css";
import Container from "../../ui/adaptivecontainer";
import Results from "../../components/player/Results";
import {PlayerReduxState} from "../../redux/player/types";
import {getAdditionalScenarios, getCurrentScenario, getSessionId} from "../../redux/player/selectors";
import {postMessageOpener} from "../../utils";
import SessionActionsList from "../../components/results/SessionActionsList";
import {
  resultsQuery, ResultsQueryData, ResultsQueryVars,
  nextLessonQuery, NextLessonQueryData, NextLessonQueryVars
} from "./player.graphql";
import {analyticsSendEvent, toEventData} from "../../libs/analytics";
import {getSharedId} from "../../types";
import cn from "classnames";

export default function PlayerResultsScreen({lesson, headless}: { lesson: Lesson, headless?: boolean }) {
  const {t} = useTranslation();

  const {
    sessionId,
    externalUserId,
    isExternal,
    actions,
    additionalScenarios,
    isInterrupted,
    isShared,
  } = useSelector(PlayerResultsScreen.selector, isEqual);

  const {courseId} = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      courseId: params.get("courseId")
    }
  }, [])

  const handleFinish = useCallback(() => {
    if (isExternal) {
      postMessageOpener({type: "Finish"});
    }
  }, [isExternal]);

  const {data: nextLessonData} = useQuery<NextLessonQueryData, NextLessonQueryVars>(nextLessonQuery, {
    variables: {
      lessonId: isShared ? getSharedId(lesson) : lesson.id,
      courseId: courseId ?? undefined
    }
  })

  const nextLesson = nextLessonData?.nextLesson;

  const [query, {data, stopPolling}] = useLazyQuery<ResultsQueryData, ResultsQueryVars>(resultsQuery, {
    variables: {
      sessionId: sessionId!,
      externalUserId: externalUserId,
      lessonId: lesson.id
    },
    pollInterval: 5000
  });

  const results = data?.results;
  const descriptions = data?.resultDescriptions;

  useEffect(() => {
    setTimeout(() => query(), 500);
  }, [query]);

  const {recordState, record} = results ?? {};

  const [isResultsSent, setResultsSent] = useState(false);

  useEffect(() => {
    if (!results || isResultsSent) {
      return;
    }
    const {actionResults, ...pickedResult} = results;
    analyticsSendEvent("playerResult", {
      sessionId: sessionId!,
      externalUserId: String(externalUserId),
      ...toEventData(pickedResult)
    });
    setResultsSent(true);
  }, [results, isResultsSent, sessionId, externalUserId])

  useEffect(() => {
    if (!recordState) {
      return;
    }
    if (
      [EducationSessionRecordState.READY, EducationSessionRecordState.FAILED, EducationSessionRecordState.SKIPPED]
      .includes(recordState)
    ) {
      analyticsSendEvent("playerResultRecordStateUpdate", {
        recordState,
        recordURL: String(record)
      });
      stopPolling && stopPolling();
    }
  }, [recordState, record, stopPolling])

  if (!results) {
    return null;
  }

  const resultsClassName = lesson.showDetailedResults ? classes.resultsContainer : cn(
    classes.resultsContainerCenter, classes.resultsContainer)

  const summrayClassName = lesson.showDetailedResults ? classes.summaryContainer : cn(
    classes.summaryContainerCenter, classes.summaryContainer)

  return (
    <Layout player className={classes.resultsLayout}>
      {!headless && (
        <Header
          type={"filled"}
          hideLinks
          adaptive
        />
      )}
      <Main>
        <Container className={resultsClassName}>
          <div className={summrayClassName}>
            <Results
              lesson={lesson}
              courseId={courseId ?? undefined}
              results={results}
              onFinish={handleFinish}
              nextLesson={nextLesson}
              descriptions={descriptions}
            />
          </div>
          {!isInterrupted && lesson.showDetailedResults && (
            <div className={classes.detailedContainer}>
              <h2 className={classes.title}>{t("player.results.detailed")}</h2>
              <div className={classes.actionsListContainer}>
                <SessionActionsList
                  actionsResults={results.actionResults}
                  lesson={lesson}
                  actions={actions}
                  additionalScenarios={additionalScenarios}
                  finishedScenarios={results.finishedScenariosIds}
                />
              </div>
            </div>
          )}
        </Container>
      </Main>
    </Layout>
  )
}

PlayerResultsScreen.selector = (state: PlayerReduxState) => ({
  sessionId: getSessionId(state),
  isExternal: state.isExternal,
  externalUserId: state.externalUserId,
  actions: getCurrentScenario(state).actions,
  additionalScenarios: getAdditionalScenarios(state),
  isInterrupted: state.interrupted,
  isShared: state.isShared
})
