/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {StoreHelpers} from "react-joyride";
import ReactTour, {TourStep} from "./ReactTour";
import {useTour} from "../providers/tour";
import {
  getTargetsClassNamesWithPrefix,
  targetClassNamesToSelectors,
  TourEventListenersMock
} from "../tours";

export const NAME = "layout";
export const SUBNAMES = {header: "layout_header"};

/// Targets
type TargetNames = [
  "header", "home", "editor", "player", "members", "langSelector", "profileDropdown", "userInfo", "account",
  "sendBugReport", "playTour"
]
type TargetName = TargetNames[number]

const targetNames: TargetNames = [
  "header", "home", "editor", "player", "members", "langSelector", "profileDropdown", "userInfo", "account",
  "sendBugReport", "playTour"
]

export type Targets = Record<TargetName, TourStep["target"]>

export const classes = getTargetsClassNamesWithPrefix(targetNames, "tour_layout_");

/// Types
export type LayoutTourMock = TourEventListenersMock<"profileDropdownClick"> & {
  tour: "layout"
}

export function isLayoutTourMock(mock: any): mock is LayoutTourMock {
  return mock?.tour === NAME;
}

/// Component
type props = {
  targets?: Partial<Targets>,
}

export default function LayoutTour({targets: componentTargets}: props) {
  const {t} = useTranslation("tours");
  const {end: endTour, setMock, isAvailable: isTourAvailable} = useTour();

  const onTourEnd = useCallback(() => {
    return endTour("layout_header");
  }, [endTour])

  useEffect(() => () => {
    setTimeout(() => endTour("layout_header"));
  }, [endTour])

  const [helpers, setHelpers] = useState<StoreHelpers>();

  const targets = useMemo(() => ({
    ...targetClassNamesToSelectors(classes),
    ...componentTargets
  }), [componentTargets])

  const mock = useMemo<Omit<LayoutTourMock, "tour" | "mockBy" | "data">>(() => ({
    eventHandlers: {
      profileDropdownClick: () => helpers ? setTimeout(helpers?.next) : undefined
    }
  }), [helpers])

  useEffect(() => {
    setMock({
      ...mock,
      tour: NAME,
    })
    return () => setMock(undefined);
  }, [mock, setMock])

  const steps = useMemo<TourStep[]>(() => ([
    {
      title: t("layout.start.title"),
      content: t("layout.start.content"),
      target: "body",
      placement: "center",
    },
    {
      title: t("layout.header.title"),
      content: t("layout.header.content"),
      target: targets.header,
      placement: "bottom",
      spotlightPadding: 0,
    },
    {
      title: t("layout.home.title"),
      content: t("layout.home.content"),
      target: targets.home,
      placement: "bottom",
    },
    {
      title: t("layout.editor.title"),
      content: t("layout.editor.content"),
      target: targets.editor,
      placement: "bottom",
    },
    {
      title: t("layout.player.title"),
      content: t("layout.player.content"),
      target: targets.player,
      placement: "bottom",
    },
    {
      title: t("layout.members.title"),
      content: t("layout.members.content"),
      target: targets.members,
      placement: "bottom",
    },
    {
      title: t("layout.langSelector.title"),
      content: t("layout.langSelector.content"),
      target: targets.langSelector,
      placement: "bottom",
    },
    {
      title: t("layout.profileDropdown.title"),
      content: t("layout.profileDropdown.content"),
      target: targets.profileDropdown,
      placement: "bottom",
      disableNextButton: true,
      spotlightClicks: true,
    },
    {
      title: t("layout.userInfo.title"),
      content: t("layout.userInfo.content"),
      target: targets.userInfo,
      placement: "left",
    },
    {
      title: t("layout.account.title"),
      content: t("layout.account.content"),
      target: targets.account,
      placement: "left",
    },
    {
      title: t("layout.sendBugReport.title"),
      content: t("layout.sendBugReport.content"),
      target: targets.sendBugReport,
      placement: "left",
      spotlightPadding: 0,
    },
    isTourAvailable ? {
      title: t("layout.playTour.title"),
      content: t("layout.playTour.content"),
      target: targets.playTour,
      placement: "left",
      spotlightPadding: 0,
      spotlightClicks: true,
    } : {
      title: t("layout.playTourDisabled.title"),
      content: t("layout.playTourDisabled.content"),
      target: targets.playTour,
      placement: "left",
      spotlightPadding: 0,
    }
  ]), [t, targets, isTourAvailable])

  return (
    <ReactTour
      continuous
      run
      steps={steps}
      onTourEnd={onTourEnd}
      getHelpers={setHelpers}
      scrollToFirstStep
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      hideCloseButton
    />
  );
}
