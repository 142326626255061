/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import isEqual from "lodash/isEqual";
import {analyticsSendEvent, getUTMParams, toEventData} from "../../libs/analytics";
import {isAvatarAction, isSharedId, isSystemAction} from "../../types";
import {reverse} from "../../routing";
import browser, {isIPhone} from "../../browser";
import {AudioPlayback} from "../../libs/audio";
import {errorsAudioQuery, ErrorsAudioQueryData, query, QueryData} from "./player.graphql";
import {
  Avatar, AvatarAction, ErrorAudio, Gender, Lesson, LessonDisplayMode,
  LessonInputMode,
  QueryGetErrorsAudioArgs, QueryGetLessonArgs, Scalars, SystemAction
} from "../../schema";
import {ExternalConnectionType, getExternalConnectionType} from "../../utils";
import {useSendBugReport} from "../../hooks/telemetry";

// Hooks
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useCurrentUser} from "../../App.context";
import {useAudio} from "../../providers/audio";
import {useAudioPreloader} from "../../providers/audioPreloader";
import {useQuery} from "@apollo/client";

// Components
import PlayerLessonNotAvailableScene from "./lesson-not-available";
import MediaRecorderProvider from "../../providers/mediaRecorder";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import PlayerResultsScreen from "./player.results";
import WithProblemsWidget from "../../components/system/ProblemsWidget";

// Players (skins)
import VideoCallPlayer from "../../components/player/video-call/VideoCallPlayer";
import ModernPlayer from "../../components/player/modern/ModernPlayer";
import BabylonAvatarPlayerLazy from "../../components/player/BabylonAvatarPlayerLazy"; // debug Babylon mode

// Redux
import PlayerReduxStoreProvider from "../../redux/player";
import {PlayerReduxState} from "../../redux/player/types";
import {getSessionId, isResultStage, isStartFailed, isResumeFailed} from "../../redux/player/selectors";
import useFullscreenMode from "../../hooks/useFullscreenMode";
import {useProblemsWatcher} from "../../providers/problemsWatcher";

import LessonResumeWidget from "../../components/player/LessonResumeWidget";
import {start} from "../../redux/player/actions";
import resume from "../../redux/player/actions/resume";
import {useTryInviteUserToLessonMutation} from "../login/login.graphql";
import {useSelectAccountMutation} from "../login/login-select-account.graphql";

export default function PlayerPlayerScene() {
  const {t} = useTranslation();
  const {id: lessonId} = useParams<{ id: string }>();
  const {
    isDebug,
    isShared,
    isPreview,
    isExternal,
    noTrackFocus,
    externalUserId,
    utm
  } = useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return {
      isDebug: params.get("debug") === "1",
      isPreview: params.get("preview") === "1",
      isExternal: params.get("external") === "1",
      isShared: isSharedId(lessonId),
      noTrackFocus: params.get("noTrackFocus") === "1",
      externalUserId: params.get("externalUserId") ?? null,
      utm: getUTMParams(params)
    };
  }, [lessonId]);
  const isDeveloper = !!useCurrentUser()?.isDeveloper;

  const {audioContext, audioManager} = useAudio();
  const {audioPreloadManager, onPreloadReady: onAudioPreloadReady} = useAudioPreloader();

  useEffect(() => {
    const playbackOpts = {
      preload: true,
      onTrackStatusUpdate: audioPreloadManager.updateTrackStatus,
      onPreloadReady: onAudioPreloadReady,
      onPlayError: ({src}: HTMLAudioElement) => audioPreloadManager.stopTrackingStatus(src)
    }
    audioManager.installPlayback("player/avatar", new AudioPlayback(audioContext, playbackOpts));
    audioManager.installPlayback("player/system", new AudioPlayback(audioContext, playbackOpts));

    return () => {
      audioManager.uninstallPlayback("player/avatar");
      audioManager.uninstallPlayback("player/system");
    }
  }, [audioContext, audioManager, audioPreloadManager, onAudioPreloadReady]);

  const playerMode = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const mode = (params.get("mode") || "auto").toLowerCase();

    switch (mode) {
      case "3d":
        return "3D"
      case "2d":
        return "2D";
      default:
        if (browser.satisfiesOS({
          "android": "<8"
        })) {
          return "2D";
        }
        return browser.satisfies({
          chrome: ">=78",
          firefox: ">=78",
          safari: ">=13",
          ios: ">=13",
          edge: ">=84",
        }) ? "3D" : "2D";
    }
  }, []);

  useEffect(() => {
    analyticsSendEvent("playerPlay", {
      lessonId, isShared, isPreview, isExternal,
      initialMode: playerMode,
      externalUserId: externalUserId ?? undefined
    });
  }, [lessonId, isShared, isPreview, isExternal, playerMode, externalUserId]);

  const {data} = useQuery<QueryData, QueryGetLessonArgs>(query, {
    fetchPolicy: "network-only",
    variables: {
      lessonId: lessonId,
      externalUserId: externalUserId ?? null
    }
  });

  const loaded = !!data;
  const lesson = data?.lesson ?? null;
  const scenarios = data?.scenarios ?? null;
  const avatar = lesson?.avatar ?? null;
  const gestures = lesson?.gestures;

  const errorsAudioData = useQuery<ErrorsAudioQueryData, QueryGetErrorsAudioArgs>(errorsAudioQuery, {
    fetchPolicy: "network-only",
    variables: {
      gender: avatar?.gender === Gender.MALE ? "male" : "female"
    }
  });

  const errorsAudio = errorsAudioData.data?.audios

  const sortedScenarios = useMemo(() => {
    if (scenarios) {
      return [...scenarios].sort((a, b) => Number(b.isMain) - Number(a.isMain))
    }
    return null;
  }, [scenarios]);

  const notUsePreload = isIPhone();

  useEffect(() => {
    if (!sortedScenarios || notUsePreload) {
      return
    }

    const actions = sortedScenarios.flatMap(({actions}) => actions)

    const actionsWithAudio = actions.filter(
      (action): action is (AvatarAction | SystemAction) =>
        isAvatarAction(action) || isSystemAction(action)
    ).map(({audio}) => audio ?? "")
    audioPreloadManager.preload(actionsWithAudio, true)
  }, [sortedScenarios, notUsePreload, audioManager, audioPreloadManager])

  const askForCameraPermission = lesson?.displayMode === LessonDisplayMode.VIDEO_CALL

  if (loaded && !lesson) {
    return (<PlayerLessonNotAvailableScene reason="not-found"/>)
  }

  if (loaded && isDebug && isDeveloper) {
    return (<BabylonAvatarPlayerLazy debug avatar={avatar!} gestures={gestures!}/>);
  }

  return (
    <>
      <ReactDocumentTitle title={lesson?.name ?? t("player.title")}/>
      {(lesson && sortedScenarios) && (
        <MediaRecorderProvider
          mediaType={(lesson.displayMode === LessonDisplayMode.VIDEO_CALL) ? "video" : "audio"}
          noTrackFocus={noTrackFocus}
          lesson={lesson}
          isShared={isShared}
        >
          <PlayerReduxStoreProvider
            lessonId={lessonId}
            lesson={lesson}
            scenarios={sortedScenarios}
            isPreview={isPreview}
            isShared={isShared}
            isExternal={isExternal}
            externalUserId={externalUserId}
            askForCameraPermission={askForCameraPermission}
            utm={utm}
          >
            <ThemedPlayer
              lessonId={lessonId}
              lesson={lesson}
              avatar={avatar!}
              gestures={gestures!}
              initialMode={playerMode}
              isPreview={isPreview}
              isExternal={isExternal}
              externalUserId={externalUserId}
              errorsAudio={errorsAudio}
            />
          </PlayerReduxStoreProvider>
        </MediaRecorderProvider>
      )}
    </>
  )
}

type PlayerInnerProps = {
  lessonId: Scalars["ID"],
  lesson: Lesson,
  avatar: Avatar,
  gestures: string[],
  initialMode: "2D" | "3D",
  isExternal: boolean,
  isPreview: boolean,
  externalUserId: Scalars["ID"] | null,
  errorsAudio?: ErrorAudio[],
}

function ThemedPlayer({
  lessonId, lesson, avatar, gestures, initialMode, isExternal, isPreview, externalUserId, errorsAudio
}: PlayerInnerProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [allowFullscreen, toggleFullscreen] = useFullscreenMode(
    lesson.inputMode === LessonInputMode.TEXT? {} : {hotkey: "KeyF"}
  );
  const {failed, resumeFailed, isResultStage, sessionId} = useSelector(ThemedPlayer.selector, isEqual)

  const [resumeWidgetVisibility, setResumeWidgetVisibility] = useState(lesson.canResume && !isPreview);
  const [applyInviteUserToLessonMutation] = useTryInviteUserToLessonMutation()
  const [selectAccount] = useSelectAccountMutation()
  const inviteByLink = lesson?.inviteByLink;
  const accessKey = lesson?.accessToken;
  const accountId = lesson?.accountId;
  const loggedIn = !!useCurrentUser();

  const onRestart = useCallback(() => {
    dispatch(start());
    setResumeWidgetVisibility(false);
  }, [dispatch])

  const onResume = useCallback(() => {
    dispatch(resume());
    setResumeWidgetVisibility(false);
  }, [dispatch])

  useEffect(() => {
    if (!(lesson.canResume && !isPreview)) {
      if (inviteByLink && loggedIn && !isPreview) {
        applyInviteUserToLessonMutation({
          variables: {
            lessonId: lesson.id,
            accessKey: accessKey
          }
        }).then((success) => {
          if (success) {
            selectAccount({variables: {accountId: accountId}}).then(() => {
              history.push(reverse("playerPlay", {id: lesson.id}));
              dispatch(start());
            })
          }
        })
      } else {
        dispatch(start());
      }
    }
  }, [dispatch, lesson.canResume, isPreview, inviteByLink, loggedIn, accessKey, accountId,
      applyInviteUserToLessonMutation, selectAccount, history, lesson.id]);

  const backUrl = useMemo(() => isPreview
    ? reverse("editorLessonEdit", {id: lessonId})
    : reverse("player", {id: lessonId}
  ), [lessonId, isPreview]);

  const {resetAllFailReasons, isFailed, failReasons} = useProblemsWatcher();

  const sendBugReport = useSendBugReport();
  const onBugReportSend = useCallback((message: string, attachment?: File, contact?: string) => {
    resetAllFailReasons();
    return sendBugReport({
      message, attachment, contact, sessionId,
      location: "playerProblemsWatcher",
      errorReason: failReasons.join("; ")
    })
  }, [resetAllFailReasons, sendBugReport, sessionId, failReasons]);

  useEffect(() => {
    if (isResultStage) {
      analyticsSendEvent("playerFinish", toEventData({
        sessionId: sessionId,
        isExternal,
        externalConnectionType: getExternalConnectionType()
      }))
    }
  })

  if (inviteByLink && !loggedIn) {
    history.push(reverse("login")
      + "?_invited=" + lesson.id + "&_access_key=" + accessKey + "&_aid=" + accountId);
  }

  if (failed) {
    return (<PlayerLessonNotAvailableScene reason="limits"/>)
  }

  if (resumeFailed) {
    return (<PlayerLessonNotAvailableScene reason="expired"/>)
  }

  if (isResultStage) {
    if (isExternal && (getExternalConnectionType() === ExternalConnectionType.IFRAME)) {
      const params = new URLSearchParams()

      externalUserId && params.set("externalUserId", externalUserId)
      history.push(reverse("lmsPlay", {id: lessonId}) + `?${params}`)
    }
    return (
      <PlayerResultsScreen headless={isExternal} lesson={lesson}/>
    )
  }

  const playerDefaultProps = {
    allowFullscreen,
    toggleFullscreen,
    lesson,
    avatar,
    gestures,
    initialMode,
    headless: isExternal,
    backUrl,
    errorsAudio,
  }

  switch (lesson.displayMode) {
    case LessonDisplayMode.VIDEO_CALL:
      return (
        <WithProblemsWidget onBugReportSend={onBugReportSend} showWidget={isFailed} onClose={resetAllFailReasons}>
          {resumeWidgetVisibility && (
            <LessonResumeWidget
              onRestart={onRestart}
              onResume={onResume}
            />
          )}
          <VideoCallPlayer {...playerDefaultProps}/>
        </WithProblemsWidget>
      )
    default:
      console.warn("PlayerScene: unsupported player display mode")
    // eslint-disable-next-line no-fallthrough
    case LessonDisplayMode.DEFAULT:
      return (
        <WithProblemsWidget onBugReportSend={onBugReportSend} showWidget={isFailed} onClose={resetAllFailReasons}>
          {resumeWidgetVisibility && (
            <LessonResumeWidget
              onRestart={onRestart}
              onResume={onResume}
            />
          )}
          <ModernPlayer {...playerDefaultProps}/>
        </WithProblemsWidget>
      )
  }
}

ThemedPlayer.selector = (state: PlayerReduxState) => ({
  failed: isStartFailed(state),
  resumeFailed: isResumeFailed(state),
  isResultStage: isResultStage(state),
  sessionId: getSessionId(state),
})
