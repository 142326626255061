/* eslint-disable max-lines */
import React, {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {
  Avatar,
  RandomAvatarAction,
  Scalars,
  Scenario,
} from "../../../schema";
import {gql} from "@apollo/client";

import Bubble from "../Bubble";
import Link from "../../../ui/link";

import {AddIcon, DeleteIcon} from "../../../ui/icons";
import AvatarIcon from "../../AvatarIcon";

import cn from "classnames";
import classes from "./RandomAvatarBubble.module.css";
import {round} from "lodash";

type props = React.ComponentProps<typeof Bubble> & {
  action: RandomAvatarAction
  scenarios?: Scenario[]
  avatar: Avatar

  onAddBranchClick?: () => void
  onBranchClick?: (branchId: Scalars["ID"]) => void,
  onDeleteBranchClick?: (branchId: Scalars["ID"]) => void
  onPhraseClick?: (branchId: Scalars["ID"], actionId: Scalars["ID"]) => void
}

export default function RandomAvatarBubble({
                                                avatar,
                                                action,
                                                scenarios,
                                                className,

                                                onAddBranchClick,
                                                onBranchClick,
                                                onDeleteBranchClick,
                                                onPhraseClick,
                                                ...props
                                              }: props) {
  const {t} = useTranslation();

  const choices = action.branches;

  const addBranchHandler: React.MouseEventHandler = React.useCallback(() => {
    onAddBranchClick && onAddBranchClick();
  }, [onAddBranchClick])

  const branchClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onBranchClick && onBranchClick(branchId!);
  }, [onBranchClick]);

  const deleteBranchClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onDeleteBranchClick && onDeleteBranchClick(branchId!);
  }, [onDeleteBranchClick]);

  const editAvatarPhraseClickHandler: React.MouseEventHandler = React.useCallback((e) => {
    const actionId = e.currentTarget.getAttribute("data-id");
    const branchId = e.currentTarget.getAttribute("data-branch-id");

    onPhraseClick && onPhraseClick(branchId!, actionId!);
  }, [onPhraseClick]);

  return (
    <Bubble className={cn(classes.root, className)} {...props}>
      {choices.map(((branch, idx) => {

        return (
          <div key={branch.id} className={classes.branch}>

            <div className={classes.branchContent}>
              {branch.avatarAction && (
                <RandomAvatarPhrase
                  data-id={branch.avatarAction.id}
                  data-branch-id={branch.id}

                  icon={(<AvatarIcon className={classes.reactionIcon} avatar={avatar}/>)}
                  text={branch.avatarAction.text}
                  onClick={editAvatarPhraseClickHandler}
                >
                </RandomAvatarPhrase>
              )}
              <button
                className={classes.expectedText}
                data-branch-id={branch.id}
                onClick={branchClickHandler}
              >
                {branch.targetScenarioId && scenarios ? (
                  "→  " + scenarios.find(({id}) => id === branch.targetScenarioId)?.name
                ) :
                  <i>{"→  " + (t("components.RandomAvatarAction.targetScenarioPlaceholder"))}</i>
                }
              </button>
            </div>

            <div className={classes.sideSection}>
              <Link
                  className={classes.branchDeleteIcon}
                  data-branch-id={branch.id}
                  onClick={deleteBranchClickHandler}
              ><DeleteIcon/></Link>
              {round(branch.dropChance!) + "%"}
            </div>
          </div>
        );
      }))}

      <Link className={classes.branchAdd} tabIndex={0} onClick={addBranchHandler}>
        <AddIcon/>
        <span>{t("components.ChoiceUserInputActionX.addBranch")}</span>
      </Link>
    </Bubble>
  )
}


function RandomAvatarPhrase({icon, text, ...props}: ComponentProps<"button"> & {
  icon: React.ReactNode,
  text: React.ReactNode,
}) {
  return (
    <button className={classes.reaction} {...props}>
      {icon}
      <span>{text}</span>
    </button>
  )
}

RandomAvatarBubble.fragments = {
  root: gql`
    fragment RandomAvatarBubble on RandomAvatarAction {
      id
      __typename
      branches {
        id
        targetScenarioId
        dropChance
        avatarAction {
          id
          text
          isCustomAudio
          emotion
          gesture
          onlyFirstLevel
          media
          mediaType
          mediaViewRequired
          setMediaAsBackground
        }
      }
    }
  `
}
