import React from "react";
import {gql} from "@apollo/client";
import cn from "classnames";

import WithTooltip from "../../ui/tooltip";
import {Avatar} from "../../schema";

import classes from "./AvatarSelect.module.css";

type props = React.ComponentProps<"div"> & {
  name?: string
  value?: string
  onChange?: (e: React.FormEvent) => void
  avatars: Avatar[]
};

export default function AvatarSelect({avatars, name, value, onChange, className: extraClassName, ...props}: props) {
  return (
    <div className={cn(classes.root, extraClassName)} {...props}>
      <div className={classes.choices}>
        {avatars.map(avatar => (
          <WithTooltip key={avatar.id} as="label" className={classes.choice} helpText={avatar.name}>
            <input
              className={classes.input}
              type="radio"
              name={name}
              checked={avatar.id === value}
              value={avatar.id}
              onChange={onChange}
            />

            <div className={classes.inner}>
              <img
                src={avatar.preview}
                alt={avatar.name}
              />
            </div>
          </WithTooltip>
        ))}
      </div>
    </div>
  )
}

AvatarSelect.fragments = {
  root: gql`
    fragment AvatarSelect on Avatar {
      id
      name(languageCode: $language)
      preview
    }
  `
}
