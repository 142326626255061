import {gql, useMutation} from "@apollo/client";
import {Conversation, ConversationsPaginatedList, CustomDictionary} from "../../schema";

export type QueryData = {
  conversation: Conversation
  customDicts: CustomDictionary[]
  exportUrl: string
}

export type ListQueryData = {
  conversations: ConversationsPaginatedList
}

export const query = gql`
  query GetConversation($conversationId: ID!) {
    conversation: getConversation(conversationId: $conversationId) {
      id
      timestamp
      username
      deviceName
      audioFilepath
      duration
      summary
      wordsCloud
      userPart
      silencePart
      textEmotion
      audioEmotion
      mainSpeaker
      isFavorite
      segments {
        id
        startDuration
        finishDuration
        speaker
        text
        textEmotions
        audioEmotions
        speechSpeed
        pitchRange
        words
      }
    }
    customDicts: getCustomDictionaries {
      id
      name
      color
      words
    }

    exportUrl: getConversationExportUrl(conversationId: $conversationId)
  }
`;


export const listQuery = gql`
  query GetConversations($limit: Int!, $offset: Int) {
    conversations: getConversationsList(first: $limit, skip: $offset) {
      items {
        id
        __typename
        id
        username
        deviceName
        duration
        summary
        timestamp
        isViewed
        isFavorite
        audioFilepath
      }

      countItems
    }
  }
`;

export function useSetConversationFavState() {
  return useMutation<{
    conversation: Conversation
  }, {
    conversationId: Conversation["id"],
    state: Boolean
  }>(updateDeviceMutation);
}

const updateDeviceMutation = gql`
  mutation UpdateDeviceMutation($conversationId: ID!, $state: Boolean!) {
    conversation: setConversationFavState(conversationId: $conversationId, state: $state) {
      id
      isFavorite
    }
  }
`;