/* eslint-disable max-lines */
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {QueryData, query, useAddDivisionMutation} from "./divisions.graphql";
import {
  Division,
} from "../../schema";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import classes from "./index.module.css";
import ReactLink from "../../components/ReactLink";
import {reverse} from "../../routing";
import DivisionsTable from "../../components/service/divisions-table/DivisionsTable";
import NavigationMenu from "../../components/service/NavigationMenu";

export default function DivisionsScene() {
  const {t} = useTranslation();

  const [applyAddDivisionMutation] = useAddDivisionMutation();

  const {data, loading: fetching} = useQuery<QueryData>(query);
  const {divisions} = data ?? {};
  const loading = !data && fetching;

  const onAddDivision = useCallback(() => {
    applyAddDivisionMutation({
      variables: {data: {name: t("service.divisions.newDivision")}},
      refetchQueries: ["GetDivisions"]
    });
  }, [applyAddDivisionMutation, t])

  const orderedDivisions = useMemo(() => {
    let divisionsList: {division: Division, level: number}[] = []

    if (!divisions) {
      return [];
    }

    const flatDivisionTree = (divs: Division[], level: number) => {
      if (divs.length === 0 || !divisions) {
        return [];
      }
      divs.forEach(element => {
        divisionsList.push({division: element, level: level})
        const children: Division[] = divisions.filter((div: Division) => div.parentId === element.id)
        flatDivisionTree(children, level+1);
      });
    }

    /* eslint-disable array-callback-return */
    divisions.map((division: Division) => {
      if (!division.parentId) {
        divisionsList.push({division: division, level: 0})
        const children: Division[] = divisions.filter((div: Division) => div.parentId === division.id)
        flatDivisionTree(children, 1);
      }
    })
    /* eslint-enable array-callback-return */

    return divisionsList;
  }, [divisions])

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.divisions")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.divisions.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <DivisionsTable
                    divisionData={orderedDivisions}
                    onAdd={onAddDivision}
                  />
                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
