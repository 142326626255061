import React, {ChangeEvent, useMemo} from "react";

import classes from "./CustomDictionaryColorSelect.module.css";
import {useTranslation} from "react-i18next";
import WithTooltip from "../../../ui/tooltip";
import cn from "classnames";

type props = {
  name?: string,
  value: number,
  onChange?: (e: ChangeEvent) => void
}

export default function CustomDictionaryColorSelect({name, value, onChange}: props) {
  const {t} = useTranslation();

  const choices = useMemo(() => ([
    {
      value: 0,
      label: t("types.TailwindColor.slate"),
      color: "slate",
    },
    {
      value: 1,
      label: t("types.TailwindColor.red"),
      color: "red",
    },
    {
      value: 2,
      label: t("types.TailwindColor.orange"),
      color: "orange",
    },
    {
      value: 3,
      label: t("types.TailwindColor.yellow"),
      color: "yellow"
    },
    {
      value: 4,
      label: t("types.TailwindColor.lime"),
      color: "lime"
    },
    {
      value: 5,
      label: t("types.TailwindColor.green"),
      color: "green"
    },
    {
      value: 6,
      label: t("types.TailwindColor.teal"),
      color: "teal",
    },
    {
      value: 7,
      label: t("types.TailwindColor.cyan"),
      color: "cyan",
    },
    {
      value: 8,
      label: t("types.TailwindColor.blue"),
      color: "blue",
    },
    {
      value: 9,
      label: t("types.TailwindColor.indigo"),
      color: "indigo",
    },
    {
      value: 10,
      label: t("types.TailwindColor.purple"),
      color: "purple",
    },
    {
      value: 11,
      label: t("types.TailwindColor.fuchsia"),
      color: "fuchsia",
    },
    {
      value: 12,
      label: t("types.TailwindColor.pink"),
      color: "pink",
    },

  ]), [t]);

  return (
    <div className={classes.root}>
      {choices.map(choice => (
        <WithTooltip key={choice.value} as="label" className={classes.itemWrapper} helpText={choice.label}>
          <input
            className={classes.input}
            type="radio"
            name={name}
            /* eslint-disable-next-line eqeqeq */
            checked={value == choice.value}
            value={choice.value}
            onChange={onChange}
          />
          <div className={cn(classes.item, `bg-${choice.color}-500`)}></div>
        </WithTooltip>
      ))}
    </div>
  )
}
