import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {query, QueryData, QueryVars} from "./course.graphql";
import {useParams} from "react-router";
import {reverse} from "../../routing";
import {ChartMeasurementUnits, ChartVisualization, isSharedId} from "../../types";
import {DashboardDataType, LessonMode} from "../../schema";
import {getMapByObjectProperty} from "../../utils";

import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import LessonCard from "../../components/LessonCard";
import Breadcrumbs from "../../ui/breadcrumbs";
import ReactLink from "../../components/ReactLink";

import DashboardChart from "../../components/analytics/DashboardChart";
import {Card, Title} from "@tremor/react";
import classes from "./course.module.css";

export default function PlayerCourseScene() {
  const {t} = useTranslation();

  const {id: courseId} = useParams<{ id: string }>();
  const isShared = isSharedId(courseId);

  const {data, loading: fetching} = useQuery<QueryData, QueryVars>(query, {
    variables: {
      courseId: courseId
    }
  });

  const {course, lessonsStates, courseResultsByUser} = data ?? {};
  const loading = !course && fetching;

  const mappedLessonsStates = useMemo(() => {
    if (!lessonsStates) {
      return undefined;
    }
    return getMapByObjectProperty(lessonsStates, "lessonId");
  }, [lessonsStates])

  const dataType = DashboardDataType.AVG_SCORE_BY_LESSON;
  const visualization = ChartVisualization.BAR;
  const measurementUnits = ChartMeasurementUnits.PERCENTAGE;

  const hasLessonsWithCustomScores = course?.lessons.find(item => item.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES)
  const showAnalytics = hasLessonsWithCustomScores && courseResultsByUser?.byLesson.find(item => item.avgScore);

  const resultDescriptions = useMemo(() => {
    if (!course || !showAnalytics) {
      return undefined;
    }

    return course.lessons.map((item) => (
      item.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES && mappedLessonsStates!.get(
        item.id)?.resultDescription && (
        <Card className={classes.analyticsItem} decoration="top" decorationColor="violet">
          <Title>
            {item.name}
          </Title>
          {mappedLessonsStates!.get(item.id)?.resultDescription}
        </Card>
      )
    ))
  }, [course, mappedLessonsStates, showAnalytics])

  return (
    <>
      <ReactDocumentTitle title={course?.name ?? t("playerIndex.title")}/>

      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Container className={classes.lessons}>
              <Breadcrumbs className={classes.breadcrumbs}>
                {!isShared ? (
                  <ReactLink href={reverse("player")}>{t("breadcrumbs.player")}</ReactLink>
                ) : (
                  <span>{t("breadcrumbs.player")}</span>
                )}
                <span>{course?.name ?? t("playerIndex.title")}</span>
              </Breadcrumbs>
              <h1 className={classes.h1}>{course?.name ?? t("playerIndex.title")}</h1>

              {!loading && course && (
                <>
                  {course.lessons.length > 0 ? (
                    <div className={classes.lessonItems}>
                      {course.lessons.map((item) => (
                        <LessonCard
                          key={item.id}
                          className={classes.lessonItem}
                          lesson={item}
                          course={course}
                          {...(isShared ? {
                            isShared: true
                          } : {
                            isShared: false,
                            lessonState: mappedLessonsStates!.get(item.id)!
                          })}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={classes.emptyState}>{t("playerIndex.emptyState")}</div>
                  )}
                  {showAnalytics && (
                    <div className={classes.chart}>
                      <DashboardChart
                        data={courseResultsByUser}
                        dataType={dataType}
                        visualization={visualization}
                        measurementUnits={measurementUnits}
                        overrideTitle={t("playerIndex.courseChart")}
                        overrideCategories={["score"]}
                        overrideColors={["violet"]}
                      />
                      <h3 className={classes.h3}>{t("playerIndex.courseResultsDetails")}</h3>
                      <div className={classes.analyticsItems}>
                        {resultDescriptions}
                      </div>
                    </div>
                  )}
                </>
              )}

              {loading && (
                <div className={classes.lessonItems}>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                  <LessonCard.Skeleton className={classes.lessonItem}/>
                </div>
              )}
            </Container>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
