/* eslint-disable max-lines */
import {useCallback, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {Card} from "@tremor/react";
import {formatDateTime, formatDurationHrs} from "../../time";
import {QueryGetConversationArgs} from "../../schema";
import {useParams} from "react-router";
import {useFormState} from "../../hooks/useFormState";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import {StarIcon, StarOutlineIcon} from "../../ui/icons";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import Button from "../../ui/button";
import Select from "../../ui/select";
import ReactLink from "../../components/ReactLink";
import {reverse} from "../../routing";
import ConversationContent from "../../components/service/ConversationContent";
import NavigationMenu from "../../components/service/NavigationMenu";
import {getDictionaryColorById} from "../../utils";

import {QueryData, query, useSetConversationFavState} from "./conversations.graphql";
import classes from "./index.module.css";
import cn from "classnames";
import WithTooltip from "../../ui/tooltip";

type formState = {
  view: "all" | "employee" | "customer"
}

export default function ConversationScene() {
  const {t, i18n} = useTranslation();
  const audioRef = useRef(new Audio())

  const textEmotions = [
    {
      id: "neutral",
      value: t("service.emotions.text.neutral")
    },
    {
      id: "joy",
      value: t("service.emotions.text.joy")
    },
    {
      id: "sadness",
      value: t("service.emotions.text.sadness")
    },
    {
      id: "anger",
      value: t("service.emotions.text.anger")
    },
    {
      id: "enthusiasm",
      value: t("service.emotions.text.enthusiasm")
    },
    {
      id: "surprise",
      value: t("service.emotions.text.surprise")
    },
    {
      id: "disgust",
      value: t("service.emotions.text.disgust")
    },
    {
      id: "fear",
      value: t("service.emotions.text.fear")
    },
    {
      id: "guilt",
      value: t("service.emotions.text.guilt")
    },
    {
      id: "shame",
      value: t("service.emotions.text.shame")
    }
  ]

  const audioEmotions = [
    {
      id: "neutral",
      value: t("service.emotions.audio.neutral")
    },
    {
      id: "positive",
      value: t("service.emotions.audio.positive")
    },
    {
      id: "sad",
      value: t("service.emotions.audio.sad")
    },
    {
      id: "angry",
      value: t("service.emotions.audio.angry")
    },
    {
      id: "other",
      value: t("service.emotions.audio.other")
    }
  ]

  const {id: conversationId} = useParams<{ id: string }>();

  const {data, loading: fetching} = useQuery<QueryData, QueryGetConversationArgs>(query, {
    variables: {
      conversationId: conversationId
    }
  });
  const {conversation, customDicts, exportUrl} = data ?? {};
  const loading = !data && fetching;

  const [mutateFavState] = useSetConversationFavState();

  const toggleFavorite = useCallback(() => {
    const targetValue = !conversation?.isFavorite;
    mutateFavState({
      variables: {conversationId: conversationId, state: targetValue}
    });
  }, [mutateFavState, conversationId, conversation?.isFavorite])

  useEffect(() => {
    if (conversation && conversation.segments[0]) {
      audioRef.current.currentTime = conversation.segments[0].startDuration ?? 0
    }
  }, [conversation])

  const formState = useFormState<formState>({
    initialValues: {
      view: "all",
    },
    preventDefault: true,
  });

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <ReactLink
                href={reverse("serviceConversationsList")}>{t("breadcrumbs.service.conversations")}
              </ReactLink>
              <span>
                {!loading ? conversation?.username : "unknown"}
                {" / "}
                {!loading ? formatDateTime(conversation?.timestamp, i18n.language) : "unknown"}
              </span>
            </Breadcrumbs>
            <div className={classes.header}>
              <button type="button" className={classes.star} onClick={toggleFavorite}>
                {conversation?.isFavorite ? (
                  <WithTooltip className={classes.help} as='span' helpText={t("service.conversation.fav.remove")}>
                    <StarIcon className={classes.filled}/>
                  </WithTooltip>
                ) : (
                  <WithTooltip className={classes.help} as='span' helpText={t("service.conversation.fav.add")}>
                    <StarOutlineIcon className={classes.outline}/>
                  </WithTooltip>
                )}
              </button>
              <h1 className={classes.h1}>
                {!loading ? conversation?.username : "unknown"}
                {" / "}
                {!loading ? formatDateTime(conversation?.timestamp, i18n.language) : "unknown"}
              </h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <>
                    <audio
                      controls
                      className={classes.audioPlayer}
                      // @ts-ignore: File is valid type for src HTML prop
                      ref={audioRef}
                      src={conversation?.audioFilepath}
                    />
                    {/* <div className="mt-6">{t("service.conversation.summary")}</div>
                    <Card className="mt-2 rounded-xl">
                      {conversation?.summary}
                    </Card> */}
                    <div className="mt-6">{t("service.conversation.stats")}</div>
                    <Card className="mt-2 flex flex-row flex-wrap rounded-xl">
                      <div className="mt-[-5px] mb-[-6px] flex flex-row flex-wrap">
                        <div className="mr-6 mb-1">
                          {t("service.conversation.duration")}: <b>{formatDurationHrs(conversation?.duration)}</b>
                        </div>
                        <div className="mr-6 mb-1">
                          {t("service.conversation.userPart")}: <b>{
                            conversation ? (conversation.userPart * 100).toFixed(1).toString() : "Unknown"
                          }%</b>
                        </div>
                        <div className="mr-6 mb-1">
                          {t("service.conversation.silencePart")}: <b>{
                            conversation ? (conversation.silencePart * 100).toFixed(1).toString(): "Unknown"}
                          {t("service.conversation.silencePartUnit")}</b>
                        </div>
                        <div className="mr-6 mb-1">
                          {t("service.conversation.audioEmotion")}: <b>{
                            audioEmotions.find(
                              emotion => emotion.id === conversation?.audioEmotion.toLowerCase()
                            )?.value
                          }</b>
                        </div>
                        <div className="mr-6 mb-1">
                          {t("service.conversation.textEmotion")}: <b>{
                            textEmotions.find(
                              emotion => emotion.id === conversation?.textEmotion.toLowerCase()
                            )?.value
                          }</b>
                        </div>
                      </div>
                    </Card>
                    <div className="mt-6">{t("service.conversation.wordsCloud")}</div>
                    <Card className="mt-2 rounded-xl flex flex-row align-center">
                      <div className={classes.wordCloud}>
                        {conversation?.wordsCloud.map(word => parseInt(word.split(": ")[1]) > 1 && (
                          <div className="flex flex-row mb-1">
                            <Button
                              className={classes.wordButton}
                              color="secondary"
                            >
                              {word.split(": ")[0]}
                            </Button>
                            <span className="self-top mr-4">{"x"}{word.split(": ")[1]}</span>
                          </div>
                        ))}
                      </div>
                    </Card>

                    <div className="flex flex-row justify-between">
                      <div className="mt-12 w-60">
                        <Select name="view" value={formState.values.view}
                          onChange={formState.changeHandler}>
                          <option value="all">
                            {t("service.conversation.view.all")}
                          </option>
                          <option value="employee">
                            {t("service.conversation.view.employee")}
                          </option>
                          <option value="customer">
                            {t("service.conversation.view.customer")}
                          </option>
                        </Select>
                      </div>

                      <div className="mt-12 ml-12">
                        <Button
                          as="a"
                          href={exportUrl}
                          color="secondary"
                        >
                          {t("service.conversation.export")}
                        </Button>
                      </div>
                    </div>

                    <ConversationContent
                      dialogue={conversation}
                      audioRef={audioRef}
                      audioEmotionsLocales={audioEmotions}
                      textEmotionsLocales={textEmotions}
                      customDicts={customDicts}
                      segmentView={formState.values.view}
                    />
                  </>
                )}
              </div>
              <div className={classes.contentAside}>
                <div className={classes.formItem}>
                  <NavigationMenu className={classes.borderBottom}/>
                </div>
                <div className={classes.formItem}>
                  <div className={classes.label}>
                    {t("service.conversation.dictionaries.label")}
                  </div>
                  {!loading && customDicts && customDicts.length > 0 ? customDicts.map(dict => (
                    <div className="flex flex-row mb-1">
                      <div className={
                        cn(classes.colorCircle, `bg-${getDictionaryColorById(dict.color, t).color}-500`, "mb-1")}/>
                      <div className="ml-3 mt-[2px]">{dict.name}</div>
                    </div>
                  )) : (
                    t("service.conversation.dictionaries.notFound")
                  )}
                </div>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
