/* eslint-disable max-lines */
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Avatar, EducationSessionErrorReason, ErrorAudio, Lesson} from "../../../schema";
import {useTranslation} from "react-i18next";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {PlayerReduxState, UserInputDisplayMode} from "../../../redux/player/types";
import {
  getChosenBranches,
  getCurrentAction,
  getCurrentActionChoiceMode,
  getCurrentActionDisplayMode,
  getCurrentActionPhraseMode,
  getLastUserInputResult,
  isErrorState,
  isHintConfirmation,
  isIntroStage
} from "../../../redux/player/selectors";

// Content
import Content from "./content/Content";
import PermissionContent from "./content/PermissionContent";
import IntroContent from "./content/IntroContent";
import SystemActionContent from "./content/SystemActionContent";
import AvatarActionContent from "./content/AvatarActionContent";
import UserInputActionContent from "./content/UserInputActionContent";
import ChoiceUserInputActionContent from "./content/ChoiceUserInputActionContent";
import UserInputErrorContent from "./content/UserInputErrorContent";
import HintConfirmationContent from "./content/HintConfirmationContent";

// Other
import isEqual from "lodash/isEqual";
import {useMediaRecorder} from "../../../providers/mediaRecorder";
import {useAudioPreloader} from "../../../providers/audioPreloader";
import {FSImageRef} from "../../../ui/fullscreen/image";
import {FSVideoRef} from "../../../ui/fullscreen/video";
import {Action} from "../../../types";
import {PhraseVariant} from "../../Phrase";
import {ChoiceUserInputDisplayMode} from "../../../redux/player/selectors/getCurrentActionChoiceMode";
import selectRandomAvatarBranch from "../../../redux/player/actions/selectRandomAvatarBranch";
import {AudioManager} from "../../../providers/audio";


type CommonProps = {
  avatar: Avatar,
  openFullscreenMedia: React.ComponentProps<typeof SystemActionContent>["openFullscreenMedia"]
}

type ContentDisplayProps = CommonProps & {
  lesson: Lesson,
  avatarLoaded?: boolean,
  errorsAudio?: ErrorAudio[],
}

type SelectedType = {
  isIntro: boolean
  action: Action | null
  isError: boolean
  isHintConfirmation: boolean
  phraseMode?: PhraseVariant
  displayMode?: UserInputDisplayMode
  choiceMode?: ChoiceUserInputDisplayMode
  isHintTextMode: boolean
  errorReason?: EducationSessionErrorReason | null
  recognizedText?: string
  chosenBranches: Set<string>
  audioManager: AudioManager
}

type ContentListItem = {
  element: React.ReactElement<ContentDisplayItemProps>,
  key: string
}

type ContentDisplayType = React.ForwardRefExoticComponent<
  ContentDisplayProps & React.RefAttributes<FSImageRef | FSVideoRef>
> & {
  selector?: (state: PlayerReduxState) => SelectedType
}

const ContentDisplay: ContentDisplayType = forwardRef(({avatarLoaded, lesson, errorsAudio, ...props}, ref) => {
  const {t} = useTranslation();

  const [fsRef, setFSRef] = useState<FSImageRef | FSVideoRef | null>(null);
  useImperativeHandle(ref, () => fsRef!, [fsRef])

  const {hasPermission} = useMediaRecorder();
  const {isIntroLoaded: introAudioLoaded} = useAudioPreloader();

  const selected = useSelector(ContentDisplay.selector!, isEqual);
  const {isIntro, isHintConfirmation} = selected;
  const useCharacterVoiceForErrors = lesson.useCharacterVoiceForErrors!;

  const dispatch = useDispatch();

  const [items, setItems] = useState<{
    current?: ContentListItem
    previous?: ContentListItem
    extra?: ContentListItem
  }>({});

  const {
    action, isError, choiceMode, displayMode, phraseMode, isHintTextMode,
    errorReason, recognizedText, chosenBranches, audioManager
  } = selected;
  const {avatar, openFullscreenMedia} = props;

  useEffect(() => {
    if (action?.__typename === "RandomAvatarAction") {
      dispatch(selectRandomAvatarBranch());
    }
  }, [dispatch, action])

  useEffect(() => {
    if (action) {
      setItems((prevItems) => {
        setFSRef(null);
        const nextItem = {
          element: (
            <ContentItem
              ref={(ref) => {
                if (ref !== null) {
                  setFSRef(ref);
                }
              }}
              {...{
                action,
                isError,
                choiceMode,
                displayMode,
                phraseMode,
                avatar,
                openFullscreenMedia,
                chosenBranches: new Set(chosenBranches) // Pass deep copy, not a reference
              }}
            />
          ),
          key: action.id
        }

        if (isError) {
          const errorItem = {
            element: (
              <UserInputErrorContent
                {...{isHintTextMode, errorReason, recognizedText,
                  audioManager, errorsAudio, useCharacterVoiceForErrors}}
              />
            ),
            key: "error"
          }
          return {
            current: errorItem,
            extra: prevItems.current
          }
        }

        if (prevItems.current?.key === nextItem.key) {
          // same action
          return {
            ...prevItems,
            extra: prevItems.extra?.key === "error" ? prevItems.extra : undefined,
            current: nextItem
          }
        } else if (prevItems.current?.key === "error") {
          // after error
          return {
            previous: prevItems.extra,
            extra: prevItems.current,
            current: nextItem
          }
        } else {
          // next action
          return {
            previous: prevItems.current,
            current: nextItem
          }
        }
      })
    }
  }, [
    action, isError, choiceMode, displayMode, phraseMode, isHintTextMode, errorReason, recognizedText,
    avatar, openFullscreenMedia, chosenBranches, audioManager, errorsAudio, useCharacterVoiceForErrors, ref
  ])

  useEffect(() => {
    if (isIntro) {
      setItems({});
    }
  }, [isIntro])

  useEffect(() => {
    if (items.previous) {
      let timeout = setTimeout(() => setItems(prevItems => ({
        ...prevItems,
        previous: undefined
      })), 500)
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [items])

  if (hasPermission === false) {
    return <PermissionContent/>
  }

  if (isHintConfirmation) {
    return <HintConfirmationContent/>
  }

  if (!avatarLoaded) {
    return (
      <Content title={t("player.loading.title")}>
        {t("player.loading.avatar")}
      </Content>
    )
  }

  if (isIntro) {
    if (!introAudioLoaded) {
      return (
        <Content title={t("player.loading.title")}>
          {t("player.loading.lesson")}
        </Content>
      )
    }
    return (
      <IntroContent
        lesson={lesson}
      />
    )
  }

  return (
    <>
      {items.previous && (
        <Content.Container fadeAway key={`prev-${items.previous.key}`}>
          {items.previous.element}
        </Content.Container>
      )}
      {items.extra && (
        <Content.Container key={`extra-${items.extra.key}`}>
          {items.extra.element}
        </Content.Container>
      )}
      {items.current && (
        <Content.Container appear key={items.current.key}>
          {items.current.element}
        </Content.Container>
      )}
    </>
  )
})


type ContentDisplayItemProps = CommonProps & Pick<SelectedType, (
  "action" |"isError" | "phraseMode" | "displayMode" | "choiceMode" | "chosenBranches"
)> & {
  action: Action
  fadeAway?: boolean
}

type ContentDisplayItemType = React.ForwardRefExoticComponent<
  ContentDisplayItemProps & React.RefAttributes<FSImageRef | FSVideoRef>
>

const ContentItem: ContentDisplayItemType = forwardRef(({
  avatar, openFullscreenMedia,
  action, phraseMode, displayMode, choiceMode, chosenBranches
}, ref) => {

  switch (action.__typename) {
    case "AvatarAction":
      return (
        <AvatarActionContent
          action={action}
          avatarName={avatar.name}
          openFullscreenMedia={openFullscreenMedia}
          ref={ref}
        />
      )

    case "SystemAction":
      return (
        <SystemActionContent
          action={action}
          openFullscreenMedia={openFullscreenMedia}
          ref={ref}
        />
      )

    case "UserInputAction":
      return (
        <UserInputActionContent
          action={action}
          phraseMode={phraseMode}
          displayMode={displayMode!}
        />
      )

    case "ChoiceUserInputAction":
      return (
        <ChoiceUserInputActionContent
          action={action}
          phraseMode={phraseMode}
          choiceMode={choiceMode}
          chosenBranches={chosenBranches}
        />
      )

    case "RandomAvatarAction":
      return (
        <div></div>
      )

    default:
      throw new Error(`NotImplemented: Step type ${action!.__typename} not implemented in Modern player`);
  }
})

export default ContentDisplay;

ContentDisplay.selector = (state: PlayerReduxState) => ({
  isIntro: isIntroStage(state),
  action: getCurrentAction(state),
  isError: isErrorState(state),
  isHintConfirmation: isHintConfirmation(state),
  phraseMode: getCurrentActionPhraseMode(state),
  displayMode: getCurrentActionDisplayMode(state),
  choiceMode: getCurrentActionChoiceMode(state),
  isHintTextMode: getCurrentActionDisplayMode(state) === UserInputDisplayMode.HINT_TEXT ? true : false,
  errorReason: getLastUserInputResult(state)?.errorState,
  recognizedText: getLastUserInputResult(state)?.recognizedText,
  chosenBranches: getChosenBranches(state),
  audioManager: state.audioManager,
})
