/* eslint-disable max-lines */
import {ApolloCache, gql, useApolloClient, useMutation} from "@apollo/client";
import {
  Avatar,
  AvatarAction,
  AvatarActionInput,
  ChoiceUserInputAction,
  Course,
  LanguageCode,
  Lesson,
  LessonInput,
  MutationAddScenarioArgs,
  MutationDeleteActionArgs,
  MutationDeleteScenarioArgs,
  MutationUpdateAvatarActionArgs,
  MutationUpdateChildrenArgs,
  MutationUpdateChoiceUserInputActionArgs,
  MutationUpdateScenarioArgs,
  MutationUpdateSystemActionArgs,
  MutationUpdateUserInputActionArgs,
  SystemAction,
  SystemActionInput,
  UserInputAction,
  UserInputActionInput,
  RandomAvatarAction,
  MutationUpdateRandomAvatarActionArgs,
  TriggerDictionaryType,
  MutationAddTriggerDictionaryArgs,
  MutationUpdateTriggerDictionaryArgs,
  MutationDeleteTriggerDictionaryArgs,
  CutWordsDictionary,
  MutationAddCutWordsDictionaryArgs,
  MutationUpdateCutWordsDictionaryArgs,
  MutationDeleteCutWordsDictionaryArgs,
  EditorAccessType,
  ResultDescription,
  MutationAddResultDescriptionArgs,
  MutationUpdateResultDescriptionArgs,
  MutationDeleteResultDescriptionArgs,
  HotWordsDictionary,
  MutationAddHotWordsDictionaryArgs,
  MutationUpdateHotWordsDictionaryArgs,
  MutationDeleteHotWordsDictionaryArgs,
} from "../../schema";
import {useCallback} from "react";
import {MutationFunctionOptions} from "@apollo/client/react/types/types";
import {createOptimisticResponse, moveItemInCache, rewriteCacheFragment, rewriteCacheQuery} from "../../apollo/utils";
import cloneDeep from "lodash/cloneDeep";
import {Action, Scenario} from "../../types";
import {mapObject} from "../../utils";

import LessonCustomizeForm from "../../components/editor/LessonCustomizeForm";
import LessonAction from "../../components/editor/LessonAction";
import ScenarioCustomizeForm from "../../components/editor/ScenarioCustomizeForm";
import AvatarActionEditForm from "../../components/editor/actions-forms/AvatarActionEditForm";
import AvatarBubble from "../../components/editor/actions/AvatarBubble";
import UserInputBubble from "../../components/editor/actions/UserInputBubble";
import UserInputActionEditForm from "../../components/editor/actions-forms/UserInputActionEditForm";
import SystemBubble from "../../components/editor/actions/SystemBubble";
import SystemActionEditForm from "../../components/editor/actions-forms/SystemActionEditForm";
import ChoiceUserInputBubble from "../../components/editor/actions/ChoiceUserInputBubble";
import ChoiceUserInputActionEditForm from "../../components/editor/actions-forms/ChoiceUserInputActionEditForm";
import RandomAvatarBubble from "../../components/editor/actions/RandomAvatarBubble";
import RandomAvatarActionEditForm from "../../components/editor/actions-forms/RandomAvatarActionEditForm";

export type MutationUpdateAnyActionArgs = (
  MutationUpdateAvatarActionArgs |
  MutationUpdateSystemActionArgs |
  MutationUpdateUserInputActionArgs |
  MutationUpdateChoiceUserInputActionArgs |
  MutationUpdateRandomAvatarActionArgs
)

export type queryVars = {
  lessonId: Lesson["id"],
  language: LanguageCode
}

export type queryData = {
  lesson: Lesson,
  course: Pick<Course, "id" | "name">,
  scenarios: Scenario[],
  triggerDicts: TriggerDictionaryType[],
  cutWordsDicts: CutWordsDictionary[],
  hotWordsDicts: HotWordsDictionary[],
  resultDescriptions: ResultDescription[],
  editorAccessType: EditorAccessType,
}

export const query = gql`
  query EditorEditLessonSceneQuery($lessonId: ID!, $language: LanguageCode!) {
    lesson: getLesson(lessonId: $lessonId) {
      id
      name
      accessToken
      scormDownloadUrl
      avatar {
        id
        name(languageCode: $language)
        preview
      }
      languageCode
      isInitial
      badWordsFilter
      background

      ...LessonActionLesson
      ...LessonCustomizeForm
    }
    
    course: getCourse(lessonId: $lessonId) {
      id
      name
    }

    scenarios: getLessonScenarios(lessonId: $lessonId) {
      __typename
      id
      name
      isMain
      triggerPhrases
      afterAction
      activationMethod
      actions {
        id
        ...LessonAction
      }
    }

    triggerDicts: getLessonTriggerDictionaries(lessonId: $lessonId) {
      id
      name
      phrases
    }

    cutWordsDicts: getLessonCutWordsDictionaries(lessonId: $lessonId) {
      id
      name
      cutWordPairs
    }
    
    hotWordsDicts: getLessonHotWordsDictionaries(lessonId: $lessonId) {
      id
      name
      text
    }

    resultDescriptions: getLessonResultDescriptions(lessonId: $lessonId) {
      id
      minScore
      maxScore
      message
    }

    editorAccessType: getEditorAccessType
  }

  ${LessonCustomizeForm.fragments.root}
  ${LessonAction.fragments.root}
  ${LessonAction.fragments.lesson}
`;

export type avatarsQueryVars = {
  language: LanguageCode
}

export type avatarsQueryData = {
  avatars: Avatar[]
}

export const avatarsQuery = gql`
  query EditorEditLessonSceneAvatarsQuery($language: LanguageCode!) {
    avatars: getAvatars {
      ...LessonCustomizeFormAvatars
    }
  }

  ${LessonCustomizeForm.fragments.avatars}
`;

function moveActionInCache(
  cache: ApolloCache<any>, action: Pick<Action, "__typename" | "id">, beforeActionId: Action["id"] | null
) {
  return moveItemInCache<Scenario, Action>(cache, action, beforeActionId, "Scenario", "actions");
}

export function useUpdateActionMutation() {
  const client = useApolloClient();

  return [useCallback((
    type: Action["__typename"],
    opts: Omit<MutationFunctionOptions<{action: Action}, MutationUpdateAnyActionArgs>, "fetchPolicy">
  ) => {
    const beforeActionID = opts.variables?.data?.beforeActionId;
    const actionID = opts.variables?.actionId

    let mutation;
    let optimisticResponseAction: Action;

    switch (type) {
      case "AvatarAction": {
        mutation = updateAvatarActionMutation;

        const data = mapObject(opts.variables?.data as AvatarActionInput, value => value ?? null);

        const picked: Partial<AvatarAction> | undefined = data.customAudio ? {
          isCustomAudio: true,
          audio: data.customAudio
        } : undefined

        const fragmentArgs = {
          id: `${type}:${actionID}`,
          fragmentName: "UpdatedAvatarAction",
          fragment: gql`
            fragment UpdatedAvatarAction on AvatarAction {
              ...AvatarBubble
              ...AvatarActionEditForm
            }

            ${AvatarBubble.fragments.root}
            ${AvatarActionEditForm.fragments.root}
          `
        }

        optimisticResponseAction = createOptimisticResponse<AvatarAction>(
          client, fragmentArgs, {
            updatedData: data,
            keys: [
              "emotion", "gesture", "media", "mediaViewRequired", "setMediaAsBackground", "text", "onlyFirstLevel"
            ],
            picked
          }
        )

        break;
      }
      case "UserInputAction": {
        mutation = updateUserInputActionMutation;

        const fragmentArgs = {
          id: `${type}:${actionID}`,
          fragmentName: "UpdatedUserInputAction",
          fragment: gql`
            fragment UpdatedUserInputAction on UserInputAction {
              ...UserInputBubble
              ...UserInputActionEditForm
            }

            ${UserInputBubble.fragments.root}
            ${UserInputActionEditForm.fragments.root}
          `
        }

        optimisticResponseAction = createOptimisticResponse<UserInputAction>(
          client, fragmentArgs, {
            updatedData: opts.variables?.data as UserInputActionInput,
            keys: ["expectedText", "analogTexts", "hintText", "freeSpeech", "freeSpeechMinTime", "freeSpeechMaxTime"]
          }
        )
        break;
      }
      case "SystemAction": {
        mutation = updateSystemActionMutation;

        const data = mapObject(opts.variables?.data as SystemActionInput, value => value ?? null);

        const picked: Partial<SystemAction> | undefined = data.customAudio ? {
          isCustomAudio: true,
          audio: data.customAudio
        } : undefined

        const fragmentArgs = {
          id: `${type}:${actionID}`,
          fragmentName: "UpdatedSystemAction",
          fragment: gql`
            fragment UpdatedSystemAction on SystemAction {
              ...SystemBubble
              ...SystemActionEditForm
            }

            ${SystemBubble.fragments.root}
            ${SystemActionEditForm.fragments.root}
          `
        }

        optimisticResponseAction = createOptimisticResponse<SystemAction>(
          client, fragmentArgs, {
            updatedData: data,
            keys: ["media", "mediaViewRequired", "setMediaAsBackground", "text", "onlyFirstLevel"],
            picked
          }
        )
        break;
      }
      case "ChoiceUserInputAction": {
        mutation = updateChoiceUserInputActionMutation;

        const fragmentArgs = {
          id: `${type}:${actionID}`,
          fragmentName: "UpdatedChoiceUserInputAction",
          fragment: gql`
            fragment UpdatedChoiceUserInputAction on ChoiceUserInputAction {
              ...ChoiceUserInputBubble
              ...ChoiceUserInputActionEditForm
            }

            ${ChoiceUserInputBubble.fragments.root}
            ${ChoiceUserInputActionEditForm.fragments.root}
          `
        }

        optimisticResponseAction = createOptimisticResponse<ChoiceUserInputAction>(
          client, fragmentArgs, {
            updatedData: opts.variables?.data as any, // TODO: Some mess with ChoiceUserInputActionInput type
            keys: ["branches"]
          }
        )
        break;
      }
      case "RandomAvatarAction": {
        mutation = updateRandomAvatarActionMutation;

        const fragmentArgs = {
          id: `${type}:${actionID}`,
          fragmentName: "UpdatedRandomAvatarAction",
          fragment: gql`
            fragment UpdatedRandomAvatarAction on RandomAvatarAction {
              ...RandomAvatarBubble
              ...RandomAvatarActionEditForm
            }

            ${RandomAvatarBubble.fragments.root}
            ${RandomAvatarActionEditForm.fragments.root}
          `
        }

        optimisticResponseAction = createOptimisticResponse<RandomAvatarAction>(
          client, fragmentArgs, {
            updatedData: opts.variables?.data as any,
            keys: ["branches"]
          }
        )
        break;
      }
      default:
        throw new Error("[NotImplemented]");
    }

    return client.mutate({
      mutation,
      optimisticResponse: {
        action: optimisticResponseAction
      },
      update: (cache, mutationResult) => {

        const action = mutationResult.data?.action;

        if (!action) {
          return;
        }

        if (beforeActionID !== undefined) {
          moveActionInCache(cache, action, beforeActionID)
          return;
        }
      },
      ...opts
    });
  }, [client])];
}

const updateAvatarActionMutation = gql`
  mutation UpdateAvatarActionMutation($lessonId: ID!, $actionId: ID!, $data: AvatarActionInput!) {
    action: updateAvatarAction(lessonId: $lessonId, actionId: $actionId, data: $data) {
      ...AvatarBubble
      ...AvatarActionEditForm
    }
  }

  ${AvatarBubble.fragments.root}
  ${AvatarActionEditForm.fragments.root}
`;

const updateUserInputActionMutation = gql`
  mutation UpdateUserInputActionMutation($lessonId: ID!, $actionId: ID!, $data: UserInputActionInput!) {
    action: updateUserInputAction(lessonId: $lessonId, actionId: $actionId, data: $data) {
      ...UserInputBubble
      ...UserInputActionEditForm
    }
  }

  ${UserInputBubble.fragments.root}
  ${UserInputActionEditForm.fragments.root}
`;


const updateSystemActionMutation = gql`
  mutation UpdateSystemActionMutation($lessonId: ID!, $actionId: ID!, $data: SystemActionInput!) {
    action: updateSystemAction(lessonId: $lessonId, actionId: $actionId, data: $data) {
      ...SystemBubble
      ...SystemActionEditForm
    }
  }

  ${SystemBubble.fragments.root}
  ${SystemActionEditForm.fragments.root}
`;


const updateChoiceUserInputActionMutation = gql`
  mutation UpdateChoiceUserInputActionMutation($lessonId: ID!, $actionId: ID!, $data: ChoiceUserInputActionInput!) {
    action: updateChoiceUserInputAction(lessonId: $lessonId, actionId: $actionId, data: $data) {
      ...ChoiceUserInputBubble
      ...ChoiceUserInputActionEditForm
    }
  }

  ${ChoiceUserInputBubble.fragments.root}
  ${ChoiceUserInputActionEditForm.fragments.root}
`;


const updateRandomAvatarActionMutation = gql`
  mutation UpdateRandomAvatarActionMutation($lessonId: ID!, $actionId: ID!, $data: RandomAvatarActionInput!) {
    action: updateRandomAvatarAction(lessonId: $lessonId, actionId: $actionId, data: $data) {
      ...RandomAvatarBubble
      ...RandomAvatarActionEditForm
    }
  }

  ${RandomAvatarBubble.fragments.root}
  ${RandomAvatarActionEditForm.fragments.root}
`;


export function useAddActionMutation() {
  const client = useApolloClient();

  return [useCallback((
    type: Action["__typename"],
    opts: Omit<MutationFunctionOptions<any, any>, "fetchPolicy">
  ) => {
    const beforeActionId = opts.variables?.data?.beforeActionId;
    const scenarioId = opts.variables?.scenarioId;

    let mutation;

    switch (type) {
      case "AvatarAction":
        mutation = addAvatarActionMutation;
        break;
      case "UserInputAction":
        mutation = addUserInputActionMutation;
        break;

      case "SystemAction":
        mutation = addSystemActionMutation;
        break;

      case "ChoiceUserInputAction":
        mutation = addChoiceUserInputActionMutation;
        break;

      case "RandomAvatarAction":
        mutation = addRandomAvatarActionMutation;
        break;

      default:
        throw new Error("[NotImplemented]");
    }

    return client.mutate({
      mutation,
      update: (cache, mutationResult) => {
        const action = mutationResult.data?.action;

        if (!action) {
          return;
        }

        rewriteCacheFragment(cache, {
          fragment: gql`fragment Scenario on Scenario {
            id
            __typename
            actions {
              id
              __typename
            }
          }`,
          id: `Scenario:${scenarioId}`
        }, (cacheData: Pick<Scenario, "id" | "__typename"> & {
          actions: Pick<Action, "id" | "__typename">[]
        }) => {
          const updatedData = cloneDeep(cacheData)
          const updatedItems = updatedData.actions

          if (beforeActionId) {
            const beforeActionIndex = updatedItems.findIndex((item) => item.id === beforeActionId)

            if (beforeActionIndex !== -1) {
              updatedItems.splice(beforeActionIndex, 0, {
                id: action.id,
                __typename: action.__typename,
              });
            } else {
              console.warn("cannot insert action, cause beforeActionId not found in lesson actions")
              return undefined;
            }
          } else {
            updatedItems.push({
              id: action.id,
              __typename: action.__typename,
            });
          }

          return updatedData
        });
      },
      ...opts
    });
  }, [client])];
}

const addAvatarActionMutation = gql`
  mutation AddAvatarActionMutation($lessonId: ID!, $scenarioId: ID, $data: AvatarActionInput!) {
    action: addAvatarAction(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      id
      emotion
      gesture
      text
      audio
    }
  }
`;

const addUserInputActionMutation = gql`
  mutation AddUserInputActionMutation($lessonId: ID!, $scenarioId: ID, $data: UserInputActionInput!) {
    action: addUserInputAction(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      id
      expectedText
      analogTexts
      rawExpectedText
      freeSpeech
      freeSpeechMinTime
      freeSpeechMaxTime
    }
  }
`;


const addSystemActionMutation = gql`
  mutation AddSystemActionMutation($lessonId: ID!, $scenarioId: ID, $data: SystemActionInput!) {
    action: addSystemAction(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      id
      text
      audio
    }
  }
`;

export function useDeleteActionMutation() {
  const client = useApolloClient();

  return [useCallback((opts: Omit<
    MutationFunctionOptions<{deleteAction: boolean}, MutationDeleteActionArgs
  >, "fetchPolicy">) => {
      const lessonId = opts.variables?.lessonId;
      const actionId = opts.variables?.actionId;

      return client.mutate({
        mutation: deleteActionMutation,
        update: (cache, mutationResult) => {
          const result = mutationResult.data?.deleteAction;

          if (!result) {
            return;
          }

          rewriteCacheQuery(cache, {
            query: gql`query ($lessonId: ID!) {
              scenarios: getLessonScenarios(lessonId: $lessonId) {
                id
                __typename
                actions {
                  id
                  __typename
                }
              }
            }`,
            variables: {lessonId}
          }, (cacheData: {
            scenarios: Scenario[]
          }) => {
            if (!cacheData.scenarios) return;

            return {scenarios: cacheData.scenarios.map(scenario => ({
              ...scenario,
              actions: scenario.actions.filter((action: any) => action.id !== actionId)
            }))}
          })
        },
        ...opts
      });
    }

    ,
    [client]
  )]
    ;
}


const deleteActionMutation = gql`
  mutation DeleteActionMutation($lessonId: ID!, $actionId: ID!) {
    deleteAction(lessonId: $lessonId, actionId: $actionId)
  }
`;

export function useUpdateLessonMutation() {
  return useMutation<{
    lesson: Lesson
  }, {
    lessonId: Lesson["id"],
    data: LessonInput,
    language: LanguageCode
  }>(updateLessonMutation);
}

const updateLessonMutation = gql`
  mutation UpdateLessonMutation($lessonId: ID!, $data: LessonInput!, $language: LanguageCode!) {
    lesson: updateLesson(id: $lessonId, data: $data) {
      name
      avatar {
        id
        name(languageCode: $language)
        preview
      }
      ...LessonCustomizeForm
    }
  }

  ${LessonCustomizeForm.fragments.root}
`;


const addChoiceUserInputActionMutation = gql`
  mutation AddChoiceUserInputActionMutation($lessonId: ID!, $scenarioId: ID, $data: ChoiceUserInputActionInput!) {
    action: addChoiceUserInputAction(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      id
      branches {
        id
        state
        customScore
        targetScenarioId
        userInput {
          id
          expectedText
          analogTexts
          rawExpectedText
          analogTexts
        }
        avatarReaction {
          id
          emotion
          gesture
          text
          audio
        }
        systemReaction {
          id
          text
          audio
        }
      }
    }
  }
`;


const addRandomAvatarActionMutation = gql`
  mutation AddRandomAvatarActionMutation($lessonId: ID!, $scenarioId: ID, $data: RandomAvatarActionInput!) {
    action: addRandomAvatarAction(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      id
      branches {
        id
        targetScenarioId
        dropChance
        avatarAction {
          id
          emotion
          gesture
          text
          audio
        }
      }
    }
  }
`;


export function useAddScenarioMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{scenario: Scenario}, MutationAddScenarioArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
      const lessonId = opts.variables?.lessonId;

      return client.mutate({
        mutation: addScenarioMutation,
        update: (cache, mutationResult) => {
          const scenario = mutationResult.data?.scenario;

          if (!scenario) {
            return;
          }

          rewriteCacheQuery(cache, {
            query: gql`query ($lessonId: ID!) {
              scenarios: getLessonScenarios(lessonId: $lessonId) {
                id
                __typename
              }
            }`,
            variables: {lessonId}
          }, (cacheData: {
              scenarios: Scenario[]
            }) => {
            if (!cacheData.scenarios) return;

            return {scenarios: [...cacheData.scenarios, scenario]}
          })
        },
        ...opts
      });
    }, [client]
  )];
}


const addScenarioMutation = gql`
  mutation AddScenarioMutation($lessonId: ID!, $data: ScenarioInput!) {
    scenario: addScenario(lessonId: $lessonId, data: $data) {
      id
      name
    }
  }
`;

export function useUpdateScenarioMutation() {
  return useMutation<{scenario: Scenario}, MutationUpdateScenarioArgs>(updateScenarioMutation);
}

const updateScenarioMutation = gql`
  mutation UpdateScenarioMutation($lessonId: ID!, $scenarioId: ID!, $data: ScenarioInput!) {
    scenario: updateScenario(lessonId: $lessonId, scenarioId: $scenarioId, data: $data) {
      ...ScenarioCustomizeForm
    }
  }

  ${ScenarioCustomizeForm.fragments.root}
`;

const deleteScenarioMutation = gql`
  mutation DeleteScenarioMutation($lessonId: ID!, $scenarioId: ID!) {
    deleted: deleteScenario(lessonId: $lessonId, scenarioId: $scenarioId)
  }
`;

export function useUpdateChildrenMutation() {
  return useMutation<{succsess: Boolean}, MutationUpdateChildrenArgs>(updateChildrenMutation);
}

const updateChildrenMutation = gql`
  mutation updateChildrenMutation($lessonId: ID) {
    success: updateChildren(lessonId: $lessonId)
  }
`;

export function useDeleteScenarioMutation() {
  const client = useApolloClient();

  type opts = Omit<MutationFunctionOptions<{deleted: boolean}, MutationDeleteScenarioArgs>, "fetchPolicy">
  return [useCallback((opts: opts) => {
      const lessonId = opts.variables?.lessonId;
      const scenarioId = opts.variables?.scenarioId;

      return client.mutate({
        mutation: deleteScenarioMutation,
        update: (cache, mutationResult) => {
          const result = mutationResult.data?.deleted;

          if (!result) {
            return;
          }

          rewriteCacheQuery(cache, {
            query: gql`query ($lessonId: ID!) {
              scenarios: getLessonScenarios(lessonId: $lessonId) {
                id
                __typename
              }
            }`,
            variables: {lessonId}
          }, (cacheData: {
              scenarios: Scenario[]
            }) => {
            if (!cacheData.scenarios) return;

            return {scenarios: cacheData.scenarios.filter((scenario) => scenario.id !== scenarioId)}
          })
        },
        ...opts
      });
    }, [client]
  )];
}

const addTriggerDictionaryMutation = gql`
  mutation addTriggerDictionaryMutation($data: TriggerDictionaryInput!, $lessonId: ID!) {
    dict: addTriggerDictionary(data: $data, lessonId: $lessonId) {
      id
      name
      phrases
    }
  }
`;

export function useAddTriggerDictionaryMutation() {
  return useMutation<{dict: TriggerDictionaryType}, MutationAddTriggerDictionaryArgs>(addTriggerDictionaryMutation);
}

const updateTriggerDictionaryMutation = gql`
  mutation updateTriggerDictionaryMutation($data: TriggerDictionaryInput!, $lessonId: ID!, $triggerDictionaryId: ID!) {
    dict: updateTriggerDictionary(data: $data, lessonId: $lessonId, triggerDictionaryId: $triggerDictionaryId) {
      id
      name
      phrases
    }
  }
`;

export function useUpdateTriggerDictionaryMutation() {
  return useMutation<{dict: TriggerDictionaryType}, MutationUpdateTriggerDictionaryArgs>(
    updateTriggerDictionaryMutation);
}

const deleteTriggerDictionaryMutation = gql`
  mutation deleteTriggerDictionaryMutation($lessonId: ID!, $triggerDictionaryId: ID!) {
    deleted: deleteTriggerDictionary(lessonId: $lessonId, triggerDictionaryId: $triggerDictionaryId)
  }
`;

export function useDeleteTriggerDictionaryMutation() {
  return useMutation<{deleted: Boolean}, MutationDeleteTriggerDictionaryArgs>(deleteTriggerDictionaryMutation);
}


const addCutWordsDictionaryMutation = gql`
  mutation addCutWordsDictionaryMutation($data: CutWordsDictionaryInput!, $lessonId: ID!) {
    dict: addCutWordsDictionary(data: $data, lessonId: $lessonId) {
      id
      name
      cutWordPairs
    }
  }
`;

export function useAddCutWordsDictionaryMutation() {
  return useMutation<{dict: CutWordsDictionary}, MutationAddCutWordsDictionaryArgs>(addCutWordsDictionaryMutation);
}

const updateCutWordsDictionaryMutation = gql`
  mutation updateCutWordsDictionaryMutation(
    $data: CutWordsDictionaryInput!, $lessonId: ID!, $cutWordsDictionaryId: ID!) {
    dict: updateCutWordsDictionary(data: $data, lessonId: $lessonId, cutWordsDictionaryId: $cutWordsDictionaryId) {
      id
      name
      cutWordPairs
    }
  }
`;

export function useUpdateCutWordsDictionaryMutation() {
  return useMutation<{dict: CutWordsDictionary}, MutationUpdateCutWordsDictionaryArgs>(
    updateCutWordsDictionaryMutation);
}

const deleteCutWordsDictionaryMutation = gql`
  mutation deleteCutWordsDictionaryMutation($lessonId: ID!, $cutWordsDictionaryId: ID!) {
    deleted: deleteCutWordsDictionary(lessonId: $lessonId, cutWordsDictionaryId: $cutWordsDictionaryId)
  }
`;

export function useDeleteCutWordsDictionaryMutation() {
  return useMutation<{deleted: Boolean}, MutationDeleteCutWordsDictionaryArgs>(deleteCutWordsDictionaryMutation);
}


const addHotWordsDictionaryMutation = gql`
  mutation AddHotWordsDictionaryMutation($data: HotWordsDictionaryInput!, $lessonId: ID!) {
    dict: addHotWordsDictionary(data: $data, lessonId: $lessonId) {
      id
      name
      text
    }
  }
`;

export function useAddHotWordsDictionaryMutation() {
  return useMutation<{dict: HotWordsDictionary}, MutationAddHotWordsDictionaryArgs>(addHotWordsDictionaryMutation);
}

const updateHotWordsDictionaryMutation = gql`
  mutation UpdateHotWordsDictionaryMutation(
    $data: HotWordsDictionaryInput!, $lessonId: ID!, $hotWordsDictionaryId: ID!) {
    dict: updateHotWordsDictionary(data: $data, lessonId: $lessonId, hotWordsDictionaryId: $hotWordsDictionaryId) {
      id
      name
      text
    }
  }
`;

export function useUpdateHotWordsDictionaryMutation() {
  return useMutation<{dict: HotWordsDictionary}, MutationUpdateHotWordsDictionaryArgs>(
    updateHotWordsDictionaryMutation);
}

const deleteHotWordsDictionaryMutation = gql`
  mutation DeleteHotWordsDictionaryMutation($lessonId: ID!, $hotWordsDictionaryId: ID!) {
    deleted: deleteHotWordsDictionary(lessonId: $lessonId, hotWordsDictionaryId: $hotWordsDictionaryId)
  }
`;

export function useDeleteHotWordsDictionaryMutation() {
  return useMutation<{deleted: Boolean}, MutationDeleteHotWordsDictionaryArgs>(deleteHotWordsDictionaryMutation);
}


const addResultDescriptionMutation = gql`
  mutation addResultDescriptionMutation($data: ResultDescriptionInput!, $lessonId: ID!) {
    dict: addResultDescription(data: $data, lessonId: $lessonId) {
      id
      minScore
      maxScore
      message
    }
  }
`;

export function useAddResultDescriptionMutation() {
  return useMutation<{dict: ResultDescription}, MutationAddResultDescriptionArgs>(addResultDescriptionMutation);
}

const updateResultDescriptionMutation = gql`
  mutation updateResultDescriptionMutation(
    $data: ResultDescriptionInput!, $lessonId: ID!, $resultDescriptionId: ID!) {
    dict: updateResultDescription(data: $data, lessonId: $lessonId, resultDescriptionId: $resultDescriptionId) {
      id
      minScore
      maxScore
      message
    }
  }
`;

export function useUpdateResultDescriptionMutation() {
  return useMutation<{dict: ResultDescription}, MutationUpdateResultDescriptionArgs>(
    updateResultDescriptionMutation);
}

const deleteResultDescriptionMutation = gql`
  mutation deleteResultDescriptionMutation($lessonId: ID!, $resultDescriptionId: ID!) {
    deleted: deleteResultDescription(lessonId: $lessonId, resultDescriptionId: $resultDescriptionId)
  }
`;

export function useDeleteResultDescriptionMutation() {
  return useMutation<{deleted: Boolean}, MutationDeleteResultDescriptionArgs>(deleteResultDescriptionMutation);
}
