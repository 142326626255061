import {gql, useMutation} from "@apollo/client";
import SessionActionsList from "../../components/results/SessionActionsList";
import {MutationRecalculateSessionScoreArgs} from "../../schema";

const query = gql`
  query PlayerResultsLessonResultsQuery($lessonId: ID!, $userId: ID) {
    results: getLessonResults(lessonId: $lessonId, userId: $userId) {
      sessionId
      passed
      score
      time
      usedHints
      record
      recordState
      isContentMatches
      finishedScenariosIds
      parametricResults {
        parameterName
        score
      }

      actionResults {
        ...SessionActionsListActionResult
      }
    }
  }

  ${SessionActionsList.fragments.actionsResults}
`;

export const lessonQuery = gql`
  query PlayerResultsLessonQuery($lessonId: ID!) {
    lesson: getLesson(lessonId: $lessonId) {
      id
      name
      avatar {
        name
      }
      showDetailedResults
      mode
      inputMode
      resultMessage
    }
    
    actions: getLessonActions(lessonId: $lessonId) {
      id
      ...SessionActionsList
    }

    additionalScenarios: getLessonScenarios(lessonId: $lessonId) {
      id
      name
      isMain
      actions {
        id
        ...SessionActionsList
      }
    }
  }
  
  ${SessionActionsList.fragments.root}
`;

export function useRecalculateScoreMutation() {
  return useMutation<{success: boolean}, MutationRecalculateSessionScoreArgs>(recalculateScoreMutation);
}

const recalculateScoreMutation = gql`
  mutation RecalculateScoreMutation($sessionId: ID!) {
    success: recalculateSessionScore(sessionId: $sessionId)
  }
`;

export default query;
