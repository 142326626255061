import React, {ComponentProps, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {formatDateTime, formatDurationHrs} from "../../../time";
import {AudioAnalysisState, ServiceAudio} from "../../../schema";
import {gql} from "@apollo/client";

import Table from "../../../ui/table";

import cn from "classnames"
import classes from "./AudiosTable.module.css";
import {PlayCircleIcon, TimeOutlineIcon} from "../../../ui/icons";
import ReactLink from "../../ReactLink";
import {useModal} from "../../ModalProvider";


export type QueryData = {
  audios: ServiceAudio[] | undefined
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  audios: QueryData["audios"]
}

export default function AudiosTable({
  audios, className, ...props
}: Props) {
  const {t, i18n} = useTranslation();
  const {add: addModal} = useModal();

  const onPlayButtonClick = useCallback((e: React.MouseEvent) => {
    const audioId = e.currentTarget.getAttribute("data-id")!;
    e.preventDefault()
    e.stopPropagation()
    addModal({
      id: `playServiceAudio_${audioId}`,
      header: t("components.AudiosTable.preview"),
      content: (
        <audio
          controls
          autoPlay={true}
          className={cn(classes.previewAudio, className)}
          // @ts-ignore: File is valid type for src HTML prop
          src={audios?.find(audio => audio.id === audioId)?.filepath}
        />
      ),
      size: "xl"
    })
  }, [addModal, audios, className, t])

  return (
    <Table className={cn(classes.root, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{t("components.AudiosTable.startedAt")}</Table.Cell>
          <Table.Cell>{t("components.AudiosTable.analysisState")}</Table.Cell>
          <Table.Cell>{t("components.AudiosTable.preview")}</Table.Cell>
          <Table.Cell>{t("components.AudiosTable.duration")}</Table.Cell>
          <Table.Cell>{t("components.AudiosTable.deviceName")}</Table.Cell>
          <Table.Cell>{t("components.AudiosTable.userEmail")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {audios && audios.map((audio) => (
          <Table.Row key={audio.id}>
            <Table.Cell>{formatDateTime(audio.startedAt, i18n.language)}</Table.Cell>
            <Table.Cell>
              {audio.analysisState === AudioAnalysisState.IN_PROGRESS && (
                <span className={cn(classes.status, classes.inProgress, classes.centerAlign)}>
                  {t("components.AudiosTable.status.inProgress")}
                </span>
              )}
              {audio.analysisState === AudioAnalysisState.READY && (
                <span className={cn(classes.status, classes.ready, classes.centerAlign)}>
                  {t("components.AudiosTable.status.ready")}
                </span>
              )}
              {audio.analysisState === AudioAnalysisState.ERROR && (
                <span className={cn(classes.status, classes.error, classes.centerAlign)}>
                  {t("components.AudiosTable.status.error")}
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              <ReactLink className={classes.pointer}
                data-id={audio.id}
                onClick={onPlayButtonClick}
              >
                <PlayCircleIcon className={classes.playIcon}/>
              </ReactLink>
            </Table.Cell>
            <Table.Cell>
              <TimeOutlineIcon className="align-top mr-2"/>
                {audio.duration ? (
                  formatDurationHrs(audio.duration)
                ) : (
                  t("components.AudiosTable.durationUnknown")
                )}
            </Table.Cell>
            <Table.Cell>{audio.deviceName}</Table.Cell>
            <Table.Cell>{audio.userEmail ?? ""}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

AudiosTable.fragments = {
  root: gql`
    fragment AudiosTable on ServiceAudio {
      id
      startedAt
      analysisState
      filepath
      deviceName
      userEmail
    }
  `,
}
