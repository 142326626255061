/* eslint-disable max-lines */
import {gql, useMutation} from "@apollo/client";

import {
  CustomDictionary,
  MutationAddCustomDictionaryArgs,
  MutationDeleteCustomDictionaryArgs,
  MutationUpdateCustomDictionaryArgs,
} from "../../schema";


export type QueryData = {
  dictionaries: CustomDictionary[]
}

export const query = gql`
  query GetCustomDictionaries {
    dictionaries: getCustomDictionaries {
      id
      name
      color
      words
    }
  }
`;

const addCustomDictionaryMutation = gql`
  mutation AddCustomDictionaryMutation($data: CustomDictionaryInput!) {
    dictionary: addCustomDictionary(data: $data) {
      id
      name
      color
      words
    }
  }
`;

export function useAddCustomDictMutation() {
  return useMutation<{dictionariy: CustomDictionary}, MutationAddCustomDictionaryArgs>(addCustomDictionaryMutation);
}

const updateCustomDictionaryMutation = gql`
  mutation UpdateCustomDictionaryMutation($dictionaryId: ID!, $data: CustomDictionaryInput!) {
    dictionary: updateCustomDictionary(dictionaryId: $dictionaryId, data: $data) {
      id
      name
      color
      words
    }
  }
`;

export function useUpdateCustomDictMutation() {
  return useMutation<
    {dictionariy: CustomDictionary}, MutationUpdateCustomDictionaryArgs
  >(updateCustomDictionaryMutation);
}

const deleteCustomDictionaryMutation = gql`
  mutation AddCustomDictionaryMutation($dictionaryId: ID!) {
    deleted: deleteCustomDictionary(dictionaryId: $dictionaryId)
  }
`;

export function useDeleteCustomDictMutation() {
  return useMutation<{deleted: Boolean}, MutationDeleteCustomDictionaryArgs>(deleteCustomDictionaryMutation)
}
