import {ComponentProps} from "react";
import {useTranslation} from "react-i18next";
import {Device} from "../../../schema";
import {gql} from "@apollo/client";

import Table from "../../../ui/table";
import DeviceEditDropdown, {Props as DeviceEditDropdownProps} from "./DeviceEditDropdown";
import ReactLink from "../../ReactLink";

import cn from "classnames"
import classes from "./DevicesTable.module.css";


export type QueryData = {
  devices: Device[] | undefined
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  devices: QueryData["devices"],
  isDev?: boolean
} & Pick<DeviceEditDropdownProps, "onRenameDevice" | "onSetStatus" | "onGenerateQR">

export default function DevicesTable({
  devices, isDev, onRenameDevice, onSetStatus, onGenerateQR, className, ...props
}: Props) {
  const {t} = useTranslation();

  return (
    <Table className={cn(classes.root, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{t("components.DevicesTable.name")}</Table.Cell>
          <Table.Cell/>
          <Table.Cell>{t("components.DevicesTable.isActive")}</Table.Cell>
          <Table.Cell>{t("components.DevicesTable.publicId")}</Table.Cell>
          <Table.Cell>{t("components.DevicesTable.lastUser")}</Table.Cell>
          {isDev && (
            <Table.Cell>{t("components.DevicesTable.account")}</Table.Cell>
          )}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {devices && devices.map((device) => (
          <Table.Row key={device.publicId}>
            <Table.Cell>
              <ReactLink
                className={classes.groupName}
              >{device.name}</ReactLink>
            </Table.Cell>
            {<Table.Cell>
                <DeviceEditDropdown
                  device={device}
                  onRenameDevice={onRenameDevice}
                  onSetStatus={onSetStatus}
                  onGenerateQR={onGenerateQR}
                />
            </Table.Cell>}
            <Table.Cell>
              {device.active ? (
                <span className={cn(classes.status, classes.active, classes.centerAlign)}></span>
              ) : (
                <span className={cn(classes.status, classes.notActive, classes.centerAlign)}></span>
              )}
            </Table.Cell>

            <Table.Cell>{device.publicId}</Table.Cell>
            <Table.Cell>{device.lastUserEmail ?? ""}</Table.Cell>
            {isDev && (
              <Table.Cell>{device.accountName ?? ""}</Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

DevicesTable.fragments = {
  root: gql`
    fragment DevicesTable on Device {
      publicId
      name
      active
      lastUserEmail
    }
  `,
}
