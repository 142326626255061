/* eslint-disable max-lines */
import React, {useCallback, useMemo} from "react";
import {
  AvatarAction,
  ChoiceUserInputBranchInput,
  ChoiceUserInputBranchState,
  Lesson,
  LessonMode,
  Scalars,
  Scenario,
  SystemAction,
} from "../../schema";
import {useTour} from "../../providers/tour";
import {isEditorLessonEditTourMock} from "../../scenes/editor/lesson-edit.tour"
import Timeline from "./Timeline";
import ActionEditForm from "./actions-forms/ActionEditForm";
import {analyticsSendEvent} from "../../libs/analytics";
import {gql} from "@apollo/client";
import {useAudio} from "../../providers/audio";
import {useTranslation} from "react-i18next";

import AvatarIcon from "../AvatarIcon";
import AvatarBubble from "./actions/AvatarBubble";
import SystemIcon from "../SystemIcon";
import SystemBubble from "./actions/SystemBubble";
import UserIcon from "./UserIcon";
import UserInputBubble from "./actions/UserInputBubble";
import ChoiceUserInputBubble from "./actions/ChoiceUserInputBubble";
import RandomAvatarBubble from "./actions/RandomAvatarBubble";
import LessonActionsAddRow from "./LessonActionsAddRow";
import Button from "../../ui/button";
import {DeleteIcon} from "../../ui/icons";
import {Action, ActionInput} from "../../types";
import PlaceholderIcon from "./PlaceholderIcon";
import Bubble from "./Bubble";
import RandomAvatarIcon from "../RandomAvatarIcon";

type props = {

  lesson: Lesson,
  action: Action,
  scenarios?: Scenario[],
  disabled?: boolean,

  onAdd?: (actionType: Action["__typename"], data: ActionInput) => void
  onUpdate?: (actionId: string, actionType: Action["__typename"], data: ActionInput) => void
  onDelete?: (actionId: string) => void
  editActionModal: (action: Action, branchId?: string | undefined) => void
}

export default function LessonAction({
  lesson, action, scenarios, disabled, onAdd, onUpdate, onDelete, editActionModal
}: props) {

  const lessonID = lesson.id;

  const {mock: tourMock} = useTour();
  const getMockByActionID = useMemo(() => {
    if (tourMock && isEditorLessonEditTourMock(tourMock)) {
      return tourMock.mockBy.action.get.bind(tourMock.mockBy.action);
    }
  }, [tourMock]);

  const insertHandler = useCallback((actionType: Action["__typename"]) => {
    onAdd && onAdd(actionType, {
      "beforeActionId": action.id
    });
  }, [action, onAdd]);

  const {audioManager} = useAudio();

  const handlers = useMemo(() => {
      switch (action.__typename) {
        case "AvatarAction":
        case "SystemAction":
          return {
            onClick: () => editActionModal(action),

            onPlayClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();

              if (!action.audio) {
                console.warn("Cannot play action preview: no mediafile");
                return;
              }

              audioManager.getPlayback("editor")!
                .play(action.audio)
                .catch(console.error);
              analyticsSendEvent("editorLessonSoundPreviewAction", {
                lessonId: lessonID,
                actionId: action.id,
              });
            },
            onDelete: () => onDelete && onDelete(action.id)
          }

        case "UserInputAction":
          return {
            onClick: () => editActionModal(action),
            onDelete: () => {
              onDelete && onDelete(action.id);
            }
          }

        case "ChoiceUserInputAction":
          return {
            onAddBranchClick: () => {
              onUpdate && onUpdate(action.id, action.__typename, {
                branches: [...action.branches.map((branch) => ({
                  id: branch.id,
                })), {
                  state: ChoiceUserInputBranchState.CORRECT
                }]
              })
            },

            onBranchClick: (branchId: Scalars["ID"]) => {
              editActionModal(action, branchId);
            },

            onDeleteBranchClick: (branchId: Scalars["ID"]) => {
              onUpdate && onUpdate(action.id, action.__typename, {
                branches: action.branches.map((branch) => ({
                  id: branch.id,
                })).filter(item => item.id !== branchId)
              })
            },

            onReactionAddClick: (branchId: Scalars["ID"],
                                 reactionType: AvatarAction["__typename"] | SystemAction["__typename"]) => {
              const branches = action.branches.map((branch) => {
                let data: ChoiceUserInputBranchInput = {
                  id: branch.id
                }

                if (data.id === branchId) {
                  switch (reactionType) {
                    case "AvatarAction":
                      data["hasAvatarReaction"] = true;
                      break;
                    case "SystemAction":
                      data["hasSystemReaction"] = true;
                      break;
                    default:
                      console.warn("Cannot add reaction of type " + reactionType)
                      break;
                  }
                }

                return data;
              });

              onUpdate && onUpdate(action.id, action.__typename, {branches})
            },

            onReactionClick: (branchId: Scalars["ID"],
                              reactionType: AvatarAction["__typename"] | SystemAction["__typename"]) => {
              const branch = action.branches.find(branch => branch.id === branchId);

              if (!branch) {
                console.warn("Cannot find ChoiceUserInput branch with id=" + branchId);
                return;
              }

              switch (reactionType) {
                case "AvatarAction":
                  if (branch.avatarReaction) {
                    editActionModal(branch.avatarReaction);
                  }
                  break;
                case "SystemAction":
                  if (branch.systemReaction) {
                    editActionModal(branch.systemReaction);
                  }
                  break;
                default:
                  console.warn("Cannot edit reaction of type " + reactionType)
                  break;
              }
            },

            onReactionDeleteClick: (branchId: Scalars["ID"],
                                    reactionType: AvatarAction["__typename"] | SystemAction["__typename"]) => {
              const branches = action.branches.map((branch) => {
                let data: ChoiceUserInputBranchInput = {
                  id: branch.id
                }

                if (data.id === branchId) {
                  switch (reactionType) {
                    case "AvatarAction":
                      data["hasAvatarReaction"] = false;
                      break;
                    case "SystemAction":
                      data["hasSystemReaction"] = false;
                      break;
                    default:
                      console.warn("Cannot add reaction of type " + reactionType)
                      break;
                  }
                }

                return data;
              });

              onUpdate && onUpdate(action.id, action.__typename, {branches})
            },

            onDelete: () => {
              onDelete && onDelete(action.id);
            }
          }

        case "RandomAvatarAction":
          return {
            onAddBranchClick: () => {
              onUpdate && onUpdate(action.id, action.__typename, {
                branches: [...action.branches.map((branch) => ({
                  id: branch.id,
                })), {
                }]
              })
            },

            onBranchClick: (branchId: Scalars["ID"]) => {
              editActionModal(action, branchId);
            },

            onDeleteBranchClick: (branchId: Scalars["ID"]) => {
              onUpdate && onUpdate(action.id, action.__typename, {
                branches: action.branches.map((branch) => ({
                  id: branch.id,
                })).filter(item => item.id !== branchId)
              })
            },

            onDelete: () => {
              onDelete && onDelete(action.id);
            },

            onPhraseClick: (branchId: Scalars["ID"]) => {
              const branch = action.branches.find(branch => branch.id === branchId);

              if (!branch) {
                console.warn("Cannot find RandomAvatarAction branch with id=" + branchId);
                return;
              }

              if (branch.avatarAction) {
                editActionModal(branch.avatarAction);
              }
            }
          }
        default:
          throw new Error("[NotImplemented]")
      }
  }, [audioManager, lessonID, editActionModal, action, onDelete, onUpdate]);

  const deleteBtn = (
    <Button size='s' onClick={handlers.onDelete} disabled={disabled}>
      <DeleteIcon fontSize={14}/>
    </Button>
  );

  const mockClassName = ((getMockByActionID && getMockByActionID(action.id)) ?? {}).classes?.item;

  const rowProps = {
    className: mockClassName,
    extra: deleteBtn
  }

  const withAddRow = useCallback((children: React.ReactNode) => (
    <>
      <LessonActionsAddRow.Collapsable disabled={disabled} onAdd={insertHandler}/>
      {children}
    </>
  ), [disabled, insertHandler])

  switch (action.__typename) {
    case "AvatarAction":
      return withAddRow(
        <Timeline.Row icon={(<AvatarIcon avatar={lesson.avatar}/>)} {...rowProps}>
          <AvatarBubble
            action={action}
            onPlayClick={handlers.onPlayClick}
            onClick={handlers.onClick}
          />
        </Timeline.Row>
      )
    case "SystemAction":
      return withAddRow(
        <Timeline.Row icon={(<SystemIcon/>)} {...rowProps}>
          <SystemBubble
            action={action}
            onPlayClick={handlers.onPlayClick}
            onClick={handlers.onClick}
          />
        </Timeline.Row>
      )
    case "UserInputAction":
      return withAddRow(
        <Timeline.Row icon={(<UserIcon/>)} {...rowProps}>
          <UserInputBubble
            action={action}
            onClick={handlers.onClick}
          />
        </Timeline.Row>
      );

    case "ChoiceUserInputAction":
      return withAddRow(
        <Timeline.Row icon={(<UserIcon/>)} {...rowProps}>
          <ChoiceUserInputBubble
            avatar={lesson.avatar}
            action={action}
            showCustomScore={lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES ||
              lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST}
            onAddBranchClick={disabled ? undefined : handlers.onAddBranchClick}
            onBranchClick={handlers.onBranchClick}
            onDeleteBranchClick={disabled ? undefined : handlers.onDeleteBranchClick}
            onReactionAddClick={disabled ? undefined : handlers.onReactionAddClick}
            onReactionClick={handlers.onReactionClick}
            onReactionDeleteClick={disabled ? undefined : handlers.onReactionDeleteClick}
          />
        </Timeline.Row>
      )

    case "RandomAvatarAction":
      return withAddRow(
        <Timeline.Row icon={(<RandomAvatarIcon/>)} {...rowProps}>
          <RandomAvatarBubble
            avatar={lesson.avatar}
            action={action}
            scenarios={scenarios}
            onAddBranchClick={disabled ? undefined : handlers.onAddBranchClick}
            onBranchClick={handlers.onBranchClick}
            onDeleteBranchClick={disabled ? undefined : handlers.onDeleteBranchClick}
            onPhraseClick={handlers.onPhraseClick}
          />
        </Timeline.Row>
      )

    default:
      console.warn("Unsupported action type " + action.__typename);
      return <div/>
  }
}

LessonAction.Placeholder = function Placeholder() {
  const {t} = useTranslation();

  return (
    <Timeline.Row icon={(<PlaceholderIcon/>)}>
      <Bubble disabled>
        <Bubble.Placeholder>
          {t("components.LessonActions.placeholder")}
        </Bubble.Placeholder>
      </Bubble>
    </Timeline.Row>
  )
}

LessonAction.fragments = {
  lesson: gql`
    fragment LessonActionLesson on Lesson {
      id
      avatar {
        ...AvatarIcon
      }
    }

    ${AvatarIcon.fragments.root}
  `,

  root: gql`
    fragment LessonAction on Action {
      id
      __typename

      ... on AvatarAction {
        ...AvatarBubble
      }

      ... on SystemAction {
        ...SystemBubble
      }

      ... on UserInputAction {
        ...UserInputBubble
      }

      ... on ChoiceUserInputAction {
        ...ChoiceUserInputBubble
      }

      ... on RandomAvatarAction {
        ...RandomAvatarBubble
      }

      ...ActionEditForm
    }

    ${AvatarBubble.fragments.root}
    ${SystemBubble.fragments.root}
    ${UserInputBubble.fragments.root}
    ${ChoiceUserInputBubble.fragments.root}
    ${RandomAvatarBubble.fragments.root}

    ${ActionEditForm.fragments.root}
  `,
}
