import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {queryDev, useUpdateDeviceMutation,
  useAnalyzeServiceAudiosMutation, useTryGetAnalysisResultsMutation, useAddDeviceMutation} from "./devices.graphql";
import {
  Device,
  DeviceInput,
} from "../../schema";

import ReactLink from "../../components/ReactLink";
import {useModal} from "../../components/ModalProvider";
import {reverse} from "../../routing";
import {analyticsSendEvent} from "../../libs/analytics";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import Button from "../../ui/button";
import {AddIcon} from "../../ui/icons";
import DevicesTable, {Props as DevicesTableProps} from "../../components/service/devices-table/DevicesTable";
import RenameDeviceModal from "../../components/service/devices-table/RenameDeviceModal";
import CopyQRCode from "../../components/CopyQRCode";
import NavigationMenu from "../../components/service/NavigationMenu";

import classes from "./index.module.css";

export default function DevicesDevScene() {
  const {t} = useTranslation();
  const {data, loading: fetching} = useQuery(queryDev);
  const {devices} = data ?? {};
  const loading = !data && fetching;
  const {add: addModal} = useModal();

  const [mutateUpdateDevice] = useUpdateDeviceMutation();
  const [mutateAnalyzeNewAudios] = useAnalyzeServiceAudiosMutation();
  const [mutateTryGetAnalysisResults] = useTryGetAnalysisResultsMutation();
  const [mutateAddDevice] = useAddDeviceMutation();

  const sendToAnalysis = useCallback(() => {
    mutateAnalyzeNewAudios()
  }, [mutateAnalyzeNewAudios])

  const getResults = useCallback(() => {
    mutateTryGetAnalysisResults()
  }, [mutateTryGetAnalysisResults])

  const addDevice = useCallback(() => {
    const data = {name: "Новое устройство"}
    mutateAddDevice({
      variables: {data},
      refetchQueries: ["GetAllDevices"]
    });
  }, [mutateAddDevice])

  const updateDevice = useCallback((deviceId: Device["id"], data: DeviceInput) => {
    mutateUpdateDevice({
      variables: {deviceId, data}
    });
  }, [mutateUpdateDevice])

  const renameDevice = useCallback((deviceId: Device["id"], name: string) => {
    analyticsSendEvent("serviceDevicesRenameDevice", {deviceId, name});
    updateDevice(deviceId, {name});
  }, [updateDevice])

  const onRenameDevice = useCallback<DevicesTableProps["onRenameDevice"]>((device) => {
    if (!devices) {
      return;
    }

    const onRenameDevice = (name: string) => {
      renameDevice(device.id, name);
      modal.remove();
    }

    const modal = addModal({
      id: `renameDevice_${device.id}`,
      header: <RenameDeviceModal.Header/>,
      content: <RenameDeviceModal.Content onRenameDevice={onRenameDevice} device={device} devices={devices}/>
    })
  }, [devices, renameDevice, addModal]);

  const toggleDeviceStatus = useCallback((deviceId: Device["id"], active: boolean) => {
    analyticsSendEvent("serviceDevicesSetDeviceStatus", {deviceId, active});
    updateDevice(deviceId, {active: active});
  }, [updateDevice])

  const onToggleDeviceStatus = useCallback<DevicesTableProps["onSetStatus"]>((device) => {
    if (!devices) {
      return;
    }
    toggleDeviceStatus(device.id, device.active ? false : true);
  }, [devices, toggleDeviceStatus]);

  const generateQR = useCallback((id: string, name?: string) => {
    const link = reverse("serviceLogin") + "?_device=" + id;

    addModal({
      id: `deviceQR_${id}`,
      header: t("service.devicesDev.qrModal.title") + name,
      content: (
        <CopyQRCode
          className={classes.qr}
          value={link}
          fileName={id && `Device Login QR Code (${name ?? id})`}
          qrProps={{size: 192}}
        />
      ),
      size: "s",
      stopCloseOnOuterClick: true
    })
  }, [addModal, t])

  const onGenerateQR = useCallback((device) => {
    generateQR(device.publicId, device.name)
  }, [generateQR])

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.devicesDev")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.devicesDev.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <>
                    <DevicesTable
                      devices={devices}
                      isDev
                      onRenameDevice={onRenameDevice}
                      onSetStatus={onToggleDeviceStatus}
                      onGenerateQR={onGenerateQR}
                    />
                    <div className="mt-[26px]">
                      <Button
                        className={classes.addButton}
                        color="secondary"
                        onClick={addDevice}
                      >
                        <AddIcon/>Добавить устройство
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
                <div className="mt-6">
                  <Button color="primary" onClick={sendToAnalysis}>Отправить аудио на анализ</Button>
                </div>
                <div className="mt-2">
                  <Button className="mt-2" color="primary" onClick={getResults}>Получить результаты</Button>
                </div>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
