/* eslint-disable max-lines */
import React, {useMemo} from "react";
import {Cell, Column, useExpanded, UseExpandedRowProps, useTable} from "react-table";
import {gql} from "@apollo/client";
import {EducationMemberRow, EducationSessionRecordState, Participation} from "../../../schema";

import {formatDateTime, formatDuration} from "../../../time";
import {useTranslation} from "react-i18next";
import FeedbackWidget from "../../FeedbackWidget";
import {CaretDown, CaretForward, PlayIcon} from "../../../ui/icons";
import MemberEditDropdown from "./MemberEditDropdown";
import ParticipationEditDropdown from "./ParticipationEditDropdown";
import UserItem from "../UserItem";

import cn from "classnames";
import classes from "./MembersTable.module.css";
import useVisibility from "../../../hooks/useVisibility";
import RecordLink from "../../RecordLink";
import Link from "../../../ui/link";
import FullscreenVideo from "../../../ui/fullscreenvideo";
import ReactLink from "../../ReactLink";
import {reverse} from "../../../routing";

type CellDataType = EducationMemberRow | Participation;
type MembersCell = Cell<CellDataType> & {
  row: UseExpandedRowProps<CellDataType>
}

export type params = {
  data: EducationMemberRow[],
  singleSubRow: boolean | undefined,

  lessons: React.ComponentProps<typeof MemberEditDropdown>["lessons"],
  courses: React.ComponentProps<typeof MemberEditDropdown>["courses"],
  onAssign: React.ComponentProps<typeof MemberEditDropdown>["onAssign"],
  onSetStatus: React.ComponentProps<typeof MemberEditDropdown>["onSetStatus"]
  onSetParticipationStatus: React.ComponentProps<typeof ParticipationEditDropdown>["onSetStatus"]
  onParticipationDelete: React.ComponentProps<typeof ParticipationEditDropdown>["onParticipationDelete"]
}

function useMembersTableTable(
  data: params["data"],
  singleSubRow: params["singleSubRow"],

  lessons: params["lessons"],
  courses: params["courses"],
  onAssign: params["onAssign"],
  onSetStatus: params["onSetStatus"],
  onSetParticipationStatus: params["onSetParticipationStatus"],
  onParticipationDelete: params["onParticipationDelete"],
) {
  const {t, i18n} = useTranslation();

  const columns = useMemo<(Column<CellDataType>)[]>(() => ([
    {
      id: "expand",
      Header: () => null,
      Cell: ({row}: MembersCell) => row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>{row.isExpanded
          ? <CaretDown/>
          : <CaretForward/>
        }</span>
      ) : null,
    },

    {
      id: "id",
      Header: () => t("components.MembersTable.user"),
      Cell: ({row}: MembersCell) => {
        switch (row.original.__typename) {
          case "EducationMemberRow":
            return (
              <UserItem user={row.original.user} {...row.getToggleRowExpandedProps()}/>
            )
          case "Participation":
            if (row.original.lesson.courseName) {
              return (
                <div>
                  <b>{row.original.lesson.name}
                  </b> {"/ " + t("components.MembersTable.course") + row.original.lesson.courseName}
                </div>
              )
            }
            return (<div>{row.original.lesson.name}</div>)

          default:
            return "NotImplemented"
        }
      },
    },

    {
      id: "options",
      Header: () => null,
      Cell: ({row}: MembersCell) => {
        switch (row.original.__typename) {
          case "EducationMemberRow":
            return(
              <span {...row.getToggleRowExpandedProps()}>
                <MemberEditDropdown
                  member={row.original}
                  lessons={lessons}
                  courses={courses}
                  onAssign={onAssign}
                  onSetStatus={onSetStatus}
                />
              </span>
            );
          case "Participation":
            return(
              <span {...row.getToggleRowExpandedProps()}>
                <ParticipationEditDropdown
                  participation={row.original}
                  onSetStatus={onSetParticipationStatus}
                  onParticipationDelete={onParticipationDelete}
                />
              </span>
            );
          default:
            return "NotImplemented"
        }
      },
    },

    {
      id: "hasActiveMembership",
      Header: () => t("components.MembersTable.status"),
      Cell: ({row}: MembersCell) => {
        switch (row.original.__typename) {
          case "EducationMemberRow":
            return row.original.user.hasActiveMembership ? (
              <span className={cn(classes.status, classes.activeMember, classes.centerAlign)}></span>
            ) : (
              <span className={cn(classes.status, classes.notActiveMember, classes.centerAlign)}></span>
            );
          case "Participation":
            return !row.original.isActive && (
              <span className={cn(classes.status, classes.notActiveParticipation, classes.centerAlign)}>
                {t("components.MembersTable.participationNotActive")}
              </span>
            );
          default:
            return "NotImplemented"
        }
      }
    },

    {
      id: "createdAt",
      Header: () => t("components.MembersTable.createdAt"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        let time: number | null = null;

        switch (obj.__typename) {
          case "EducationMemberRow":
            return "";
          case "Participation":
            time = obj.createdAt;
            break;
          default:
            return "NotImplemented"
        }

        if (time === null) {
          return "";
        }

        return formatDateTime(time, i18n.language)
      }
    },

    {
      id: "startedAt",
      Header: () => t("components.MembersTable.startEducation"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        let time: number | null = null;

        switch (obj.__typename) {
          case "EducationMemberRow":
            time = obj.participations.reduce<number | null>((prev, current) => {
              if (!prev || prev > current.startedAt) {
                return current.startedAt ?? prev;
              }
              return prev;
            }, null);
            break;
          case "Participation":
            time = obj.startedAt;
            break;
          default:
            return "NotImplemented"
        }

        if (time === null) {
          return <i className={classes.notStarted}>{t("components.MembersTable.notStarted")}</i>;
        }

        return formatDateTime(time, i18n.language)
      }
    },

    {
      id: "attemptsCount",
      Header: () => t("components.MembersTable.attemptsCount"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        let count: number | null = null;

        switch (obj.__typename) {
          case "EducationMemberRow":
            if (obj.attemptsCount) {
              return obj.attemptsCount;
            }
            break;
          case "Participation":
            if (obj.attemptsCount) {
              return (
                <ReactLink
                  href={reverse("playerUserResults", {lessonId: obj.lesson.id, userId: obj.student.id})}
                >
                  {obj.attemptsCount}
                </ReactLink>
              )
            }
            break;
          default:
            return "NotImplemented"
        }

        return count ? count : 0;
      }
    },

    {
      id: "bestScore",
      Header: () => t("components.MembersTable.bestScore"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        switch (obj.__typename) {
          case "EducationMemberRow":
            if (obj.averageScore) {
              return obj.averageScore + t("components.MembersTable.avg");
            }
            return "";
          case "Participation":
            return obj.bestAttempt?.score ?? "";
          default:
            return "[NotImplemented]"
        }
      }
    },

    {
      id: "bestTime",
      Header: () => t("components.MembersTable.bestTime"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        switch (obj.__typename) {
          case "EducationMemberRow":
            return "";
          case "Participation":
            return obj.bestAttempt?.time ? formatDuration(obj.bestAttempt?.time) : "";
          default:
            return "[NotImplemented]"
        }
      }
    },

    {
      id: "feedback",
      Header: () => t("components.MembersTable.feedback"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        switch (obj.__typename) {
          case "EducationMemberRow":
            if (obj.averageFeedbackScore) {
              return obj.averageFeedbackScore + "/5" + t("components.MembersTable.avg");
            }
            return "";
          case "Participation":
            return (obj.feedbackScore || obj.feedbackText) ? (
              <FeedbackWidget
                score={obj.feedbackScore}
                text={obj.feedbackText}
              />
            ) : null;
          default:
            return "NotImplemented"
        }
      }
    },

    {
      id: "record",
      Header: () => t("components.MembersTable.record"),
      Cell: ({row}: MembersCell) => {
        let obj = row.original;
        const [firstVideoVisible, showFirstVideo, hideFirstVideo] = useVisibility();
        const [bestVideoVisible, showBestVideo, hideBestVideo] = useVisibility();

        if (singleSubRow && obj.__typename === "EducationMemberRow" && obj.participations.length === 1) {
          obj = obj.participations[0];
        }

        switch (obj.__typename) {
          case "EducationMemberRow":
            return "";
          case "Participation":
            return (
              <div className={classes.records}>
                {obj.firstAttempt && obj.firstAttempt.record && (
                  <>
                    <RecordLink as={Link}
                      className={classes.recordButton}
                      data-id={obj.firstAttempt.record}
                      disabled={obj.firstAttempt.recordState !== EducationSessionRecordState.READY}
                      state={obj.firstAttempt.recordState}
                      onClick={showFirstVideo}
                      label={t("components.MembersTable.firstRecord")}
                      textVariant="short"
                    >
                      {(obj.firstAttempt.recordState === EducationSessionRecordState.READY) && (
                        <PlayIcon className={classes.playIcon}/>
                      )}
                    </RecordLink>
                    {firstVideoVisible &&
                      <FullscreenVideo src={obj.firstAttempt.record ?? undefined} onClose={hideFirstVideo}/>}
                  </>
                )}
                {obj.bestAttempt && obj.bestAttempt.record && obj.firstAttempt?.record !== obj.bestAttempt.record && (
                  <>
                    <RecordLink as={Link}
                      className={classes.recordButton}
                      data-id={obj.bestAttempt.record}
                      disabled={obj.bestAttempt.recordState !== EducationSessionRecordState.READY}
                      state={obj.bestAttempt.recordState}
                      onClick={showBestVideo}
                      label={t("components.MembersTable.bestRecord")}
                      textVariant="short"
                    >
                      {(obj.bestAttempt.recordState === EducationSessionRecordState.READY) && (
                        <PlayIcon className={classes.playIcon}/>
                      )}
                    </RecordLink>
                    {bestVideoVisible &&
                      <FullscreenVideo src={obj.bestAttempt.record ?? undefined} onClose={hideBestVideo}/>}
                  </>
                )}
              </div>
            );
          default:
            return "NotImplemented"
        }
      }
    },
  ]),
  [t, i18n, lessons, courses, onAssign, onSetStatus, onSetParticipationStatus, onParticipationDelete, singleSubRow]);

  return useTable<CellDataType>({
    columns: columns,
    data: data,

    getSubRows: (row) => {
      if (row.__typename === "EducationMemberRow" && !singleSubRow) {
        return row.participations;
      }

      return [];
    }
  }, useExpanded);
}

const MembersTableTable = {
  use: useMembersTableTable,
  fragments: {
    root: gql`
      fragment MembersTable on EducationMemberRow {
        id

        ...MemberEditDropdown

        participations {
          id

          lesson { id name courseName }
          student { id }
          createdAt
          startedAt
          isActive
          attemptsCount
          firstAttempt { record recordState }
          bestAttempt { time score record recordState }
          feedbackScore
          feedbackText
        }
        user {
          id
          ...UserItem
        }
        averageScore
        averageFeedbackScore
        attemptsCount
      }
      ${MemberEditDropdown.fragments.root}
      ${UserItem.fragments.root}
    `,
    lessons: gql`
      fragment MembersTableLessons on Lesson {
        ...MemberEditDropdownLessons
      }
      ${MemberEditDropdown.fragments.lessons}
    `,
    courses: gql`
      fragment MembersTableCourses on Course {
        ...MemberEditDropdownCourses
      }
      ${MemberEditDropdown.fragments.courses}
    `
  }
}

export default MembersTableTable;
