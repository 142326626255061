/* eslint-disable max-lines */
// Hooks
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import useAsyncMemo from "../../hooks/useAsyncMemo";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useLazyQuery} from "@apollo/client";
import lmsQuery, {useSendFeedbackMutation} from "./lms-play.graphql";
import {useModal} from "../../components/ModalProvider";
import useVisibility from "../../hooks/useVisibility";

// Libs
import {analyticsSendEvent, analyticsSetUser, analyticsSetUserProperties, toEventData} from "../../libs/analytics";
import {bindEventListener, isMicAccessible, mapObject, postMessageOpener} from "../../utils";
import browser from "../../browser";

// Types
import {
  AccountBase, DetailedSessionFeedbackInput, ExternalUser, Lesson, LessonEducationState,
  LessonEducationStatus,
  LessonMode
} from "../../schema";
import {PlayableAction} from "../../redux/player/types";

// Components
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Button from "../../ui/button";
import ResultsDisplay from "../../components/results/ResultsDisplay";
import FeedbackForm from "../../components/results/FeedbackForm";
import WithTooltip from "../../ui/tooltip";
import ReloadButton from "../../components/ReloadButton";
import StartDisplay, {StartDisplayMode} from "../../components/player/lms/StartDisplay";
import StatusDisplay from "../../components/player/lms/StatusDisplay";
import SessionActionsList from "../../components/results/SessionActionsList";
import Link from "../../ui/link";
import Widget from "../../ui/widget";

// Classes
import classes from "./lms-play.module.css";


export default function LmsPlayScene() {
  const {t} = useTranslation();

  // Making reference for window in case we open new one
  const openedWindow = useRef<Window>()


  /// Getting data from url: lesson ID from url body and all other data from search query
  const {id: lessonId} = useParams<{ id: string }>();

  const {externalUserId, parentProtocol, forceMode, allowAnotherDevice, requestMic} = useMemo(() => {
    const params = new URLSearchParams(window.location.search.substr(1));

    return {
      externalUserId: params.get("externalUserId") ?? null,
      parentProtocol: params.get("protocol") ?? null,
      forceMode: params.get("forceMode") ?? null,
      allowAnotherDevice: !!params.get("allowAnotherDevice") ?? null,
      requestMic: !!params.get("requestMic") ?? null,
    };
  }, []);


  /// Query
  // Making query for previous completion data and user info
  const [query, {data, refetch, loading}] = useLazyQuery<{
    state: LessonEducationState,
    lesson: Pick<Lesson, "id" | "mode" | "inputMode" | "languageCode" | "avatar">, actions: PlayableAction[],
    account: AccountBase, user: ExternalUser
  }>(lmsQuery, {
    variables: {lessonId},
    notifyOnNetworkStatusChange: true
  });

  // If exID is defined making query
  useEffect(() => {
    if (!data && externalUserId) {
      query({variables: {externalUserId, lessonId}})
    }
  }, [data, externalUserId, lessonId, query])

  // Sometimes we need to get full access to mic on this page: iframe may not have access to mic, but enumerateDevices
  // will return like everything is OK
  const useGetUserMedia = useMemo(() => (
    requestMic || browser.isWebView()
  ), [requestMic])

  /// Access state
  /// Calculating which access options we have: can we acces microphone and if not - why (http restriction or no)
  const accessState = useAsyncMemo<{
    isMicAccessible?: boolean,
    isLmsHttps?: boolean
  }>(async () => ({
    isMicAccessible: await isMicAccessible(useGetUserMedia),
    isLmsHttps: parentProtocol === "https:",
  }), [useGetUserMedia, parentProtocol])

  /// Refetch
  /// Set events to refetch data and show user his last results
  // Refetch on focus
  useEffect(() => bindEventListener(window, "focus", () => {
    if (refetch) {
      refetch();
      setTimeout(refetch, 1000);
    }
  }), [refetch]);

  // Refetch on message: when openedWindow sends results
  useEffect(() => {
    return bindEventListener(window, "message", (event) => {
      const {data: message, source} = (event as MessageEvent);
      if (source === openedWindow.current?.window) {
        switch (message.type) {
          case "Complete":
            refetch && refetch();
            break;
          case "Finish":
            openedWindow.current?.close();
            break;
          // no default
        }
      }
    })
  }, [openedWindow, refetch])


  /// Calculating url search params in case we open new page or giving url/QR to open on another device
  const urlParams = useMemo(() => {
    const params = new URLSearchParams();

    params.set("external", "1");
    externalUserId && params.set("externalUserId", externalUserId)
    return params;
  }, [externalUserId])


  /// Start display mode
  /// Calculating which of start displays show to user: default (just redirect to player), opener (new window)
  /// or copy (show url to copy and QR-code)
  const {state, lesson, actions} = data ?? {}

  const lastAttempt = state?.lastAttempt

  const canStart = state?.status !== LessonEducationStatus.COMPLETE
  const canRetry = state?.canRetry

  const startDisplayMode: StartDisplayMode = useMemo(() => {
    if (forceMode) {
      if (Object.values(StartDisplayMode).includes(forceMode as StartDisplayMode)) {
        return forceMode as StartDisplayMode
      } else {
        console.warn(`forceMode "${forceMode}" not supported, fallback to default logic`)
      }
    }
    return accessState?.isMicAccessible
      ? StartDisplayMode.DEFAULT
      : accessState?.isLmsHttps ? StartDisplayMode.COPY : StartDisplayMode.OPENER
  }, [forceMode, accessState])

  // Function for opener mode: will open new window with lesson
  const openInNewWindow = useCallback((urlString) => {
    openedWindow.current = window.open(urlString, "Dailo") ?? undefined
  }, [openedWindow])


  /// Results
  /// Getting results and passing to lms
  const result = state?.bestAttempt
  const sendDataToLms = useCallback(() => {
    if (result) {
      const pickedResult = {
        passed: result.passed,
        score: result.score,
        time: result.time,
      }

      analyticsSendEvent("lmsPlayFinish", pickedResult)

      postMessageOpener({
        type: "Complete",
        ...pickedResult
      });
      postMessageOpener({type: "Finish"});
    }
  }, [result])
  // Function to manually refetch results
  const update = useCallback(() => refetch && refetch(), [refetch]);


  /// Detailed results
  const {add: addModal} = useModal();

  // Function to show detailed results in modal
  const showDetailedResults = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!result?.actionResults || !lesson || !actions) {
      return;
    }

    addModal({
      className: classes.modal,
      size: "xl",
      header: <span>{t("player.results.detailed")}</span>,
      content: (
        <div className={classes.actionsListContainer}>
          <SessionActionsList
            lesson={lesson}
            actionsResults={result.actionResults}
            actions={actions}
          />
        </div>
      )
    })
  }, [lesson, result, actions, addModal, t]);


  /// Feedback
  /// Making feedback sending function for FeedbackForm with analytics
  const [isFeedbackVisible, , hideFeedback] = useVisibility(true);

  const [sendFeedback] = useSendFeedbackMutation()
  const onFeedback = useCallback((score?: number, data?: DetailedSessionFeedbackInput, message?: string) => {
    if (!lastAttempt) {
      console.warn("onFeedback: last attempt is undefined");
      return;
    }

    analyticsSendEvent("leaveFeedback", {
      from: "lmsPlay",
      score,
      message,
      ...mapObject(data ?? {}, item => !!item)
    });

    return sendFeedback({
      variables: {
        message, score, data,
        sessionId: lastAttempt.sessionId
      }
    })
  }, [sendFeedback, lastAttempt])


  /// User data
  /// Getting more data about user for better analytics
  const accountId = data?.account?.id ?? null;

  useEffect(() => {
    if (!loading) {
      analyticsSetUser(data?.user?.id ?? null);
      analyticsSetUserProperties({accountId});
    }
  }, [loading, data, accountId]);


  /// Analytics
  // Sending event on page open with all data pre-computed (like startDisplayMode)
  useEffect(() => {
    if (accessState?.isLmsHttps === undefined || accessState?.isMicAccessible === undefined) {
      return;
    }
    analyticsSendEvent("lmsPlayOpen", toEventData({
      lessonId,
      externalUserId,
      parentProtocol,
      forceMode,
      requestMic: useGetUserMedia,
      ...accessState,
      startDisplayMode
    }))
  }, [
    lessonId,
    externalUserId,
    parentProtocol,
    forceMode,
    useGetUserMedia,
    accessState,
    startDisplayMode
  ])

  // Send event on state update (when user completed lesson)
  useEffect(() => {
    if (state) {
      analyticsSendEvent("lmsPlayStateUpdate", {
        status: state?.status,
        canRetry: state?.canRetry,
        canStart
      })
    }
  }, [state, canStart])


  /// Precomputing which start displays to show
  const primaryStartDisplayVisible = (canStart || state?.canRetry) && (startDisplayMode !== StartDisplayMode.COPY)
  const secondaryStartDisplayVisible = allowAnotherDevice || (
    (canStart || state?.canRetry) && (startDisplayMode === StartDisplayMode.COPY)
  )

  /// Precomputing start display props
  const startDisplayProps = useMemo(() => ({
    className: classes.startContainer,
    mode: startDisplayMode,
    lessonId,
    urlParams,
    openInNewWindow,
    canStart,
    canRetry,
  }), [startDisplayMode, lessonId, urlParams, openInNewWindow, canStart, canRetry])

  return (
    <Layout player className={classes.layout}>
      <Header hideLinks disableHomeButton/>
      <Main>
        <Container className={classes.container}>
          <div className={classes.mainContainer}>
            {state?.status && (
              <StatusDisplay
                className={classes.statusDisplay}
                status={state.status}
                passed={state.bestAttempt?.passed}
              />
            )}
            {(lesson && result) ? (
              <ResultsDisplay
                className={classes.resultsDisplay}
                lesson={lesson}
                results={result}
                hideTitle
                hideHelpText
              >
                {result.isContentMatches && (
                  <Link className={classes.detailedResultsBtn} onClick={showDetailedResults}>
                    {t("lmsPlay.showDetailedResults")}
                  </Link>
                )}
                <div className={classes.row}>
                  <WithTooltip placement='bottom' helpText={t("lmsPlay.finishHelpText")} showAfter={700}>
                    <Button color="success" onClick={sendDataToLms}>
                      {t("lmsPlay.finish")}
                    </Button>
                  </WithTooltip>
                  <ReloadButton onReloadClick={update} loading={loading} className={classes.updateButtonRound}/>
                </div>
              </ResultsDisplay>
            ) : (
              <ReloadButton onReloadClick={update} loading={loading} className={classes.updateButton} round={false}>
                {t("lmsPlay.updateResults") + " "}
              </ReloadButton>
            )}
            {primaryStartDisplayVisible && (
              <StartDisplay {...startDisplayProps}/>
            )}
          </div>
          {secondaryStartDisplayVisible && (
            <StartDisplay
              {...startDisplayProps}
              mode={StartDisplayMode.COPY}
              secondaryStartDisplay={primaryStartDisplayVisible}
            />
          )}
          {(lastAttempt?.feedbackScore === 0) && isFeedbackVisible && (
            <Widget
              dropShadow
              className={classes.feedbackWidget}
              showCloseButton
              onCloseButtonClick={hideFeedback}
            >
              <FeedbackForm
                isQuizMode={lesson?.mode === LessonMode.QUIZ}
                className={classes.feedback}
                onFeedback={onFeedback}
                result={lastAttempt}
              />
            </Widget>
          )}
        </Container>
      </Main>
    </Layout>
  )
}
