/* eslint-disable max-lines */

export type AudioTrackStatus = {
  loaded: boolean,
  played: boolean,
}

export type AudioTrackStatusUpdater = (src: string, status: Partial<AudioTrackStatus>) => void

export type AudioTracksStatusMap = Map<string, AudioTrackStatus>

export interface PlaybackInterface {
  play(src: string | undefined, opts?: any): Promise<void>

  abortPlay(): void

  destroy(): void
}

export interface PlaybackPluginInterface {
  readonly name: string

  install(audio: HTMLAudioElement, audioSource: MediaElementAudioSourceNode): void

  uninstall(audio: HTMLAudioElement, audioSource: MediaElementAudioSourceNode): void
}

class PlayWithOptsEvent extends Event {
  readonly opts: any;

  constructor(opts?: any) {
    super("playWithOpts");
    this.opts = opts;
  }
}

export type AudioPlaybackOpts = {
  preload?: boolean,
  onTrackStatusUpdate?: AudioTrackStatusUpdater,
  onPreloadReady?: (callback: () => void) => void,
  onPlayError?: (audio: HTMLAudioElement, ev: string | Event) => void,
}

export class AudioPlayback implements PlaybackInterface {
  protected audioContext: AudioContext;

  private readonly audio: HTMLAudioElement;
  private readonly audioSource: MediaElementAudioSourceNode;

  private installedPlugins: Record<string, PlaybackPluginInterface> = {}
  private opts: AudioPlaybackOpts;

  constructor(audioContext: AudioContext, opts?: AudioPlaybackOpts) {
    this.audioContext = audioContext;

    this.audio = new Audio();
    this.audioSource = this.audioContext.createMediaElementSource(this.audio);
    this.audioSource.connect(this.audioContext.destination);

    this.audio.loop = false;
    this.audio.crossOrigin = "anonymous";

    this.opts = opts ?? {}

    this.audio.oncanplaythrough = () => {
      this.opts.onTrackStatusUpdate && this.opts.onTrackStatusUpdate(this.audio.src, {loaded: true})
      this.audio.play().catch(console.error)
    }
  }

  async play(src: string, opts?: any): Promise<void> {
    this.resetAudio();

    if (src === undefined) {
      return new Promise(() => {
        // infinite promise, when no audio file present
      })
    }
    this.opts.onTrackStatusUpdate && this.opts.onTrackStatusUpdate(src, {played: true})
    await this.audioContext.resume()
    return new Promise((resolve, reject) => {
      this.audio.onended = () => {
        this.resetAudio();
        if (!this.opts.preload || !this.opts.onPreloadReady) {
          resolve();
        } else {
          this.opts.onPreloadReady(() => {
            resolve();
          })
        }
      }

      this.audio.onerror = (ev, ...args) => {
        console.warn("PLAY_AUDIO_ERROR", ev, ...args);
        this.opts.onPlayError && this.opts.onPlayError(this.audio, ev)
        this.resetAudio();
        reject("ERROR");
      }
      this.audio.onabort = () => {
        this.resetAudio();
        reject("ABORT");
      }

      this.audio.onplay = () => {
        this.audio.dispatchEvent(new PlayWithOptsEvent(opts))
      }
      this.audio.src = src;
      this.audio.load();
    })
  }

  abortPlay() {
    this.audio.pause();
    this.resetAudio();
  }

  destroy() {
    this.abortPlay();

    Object.keys(this.installedPlugins).forEach((plugin) => {
      this.uninstallPlugin(plugin);
    })
  }

  installPlugin(plugin: PlaybackPluginInterface) {
    this.installedPlugins[plugin.name] = plugin;
    plugin.install(this.audio, this.audioSource);
  }

  uninstallPlugin(name: PlaybackPluginInterface["name"]) {
    const plugin = this.installedPlugins[name];

    if (!plugin) {
      return;
    }

    plugin.uninstall(this.audio, this.audioSource);
    delete this.installedPlugins[name];
  }

  protected resetAudio() {
    this.audio.onabort = null;
    this.audio.onerror = null;
    this.audio.onended = null;
    this.audio.onload = null;
    this.audio.onloadstart = null;

    this.audio.removeAttribute("src");
    this.audio.load();
  }
}

export type LipSyncOpts = {
  smoothingTimeConstant?: number,
  fftSize?: number,
  onTalkStart?: () => void,
  onTalkStop?: () => void,
  onLipStateChange?: (newValue: LipState) => void,
}

export enum LipState {
  CLOSE,
  NARROW_OPEN,
  OPEN,
  WIDE_OPEN
}

export class LipSync implements PlaybackPluginInterface {
  name = "LipSync";

  private readonly analyser: AnalyserNode;

  onTalkStart?: LipSyncOpts["onTalkStart"];
  onTalkStop?: LipSyncOpts["onTalkStop"];
  onLipStateChange?: LipSyncOpts["onLipStateChange"];

  constructor(audioContext: AudioContext, opts?: LipSyncOpts) {
    this.analyser = audioContext.createAnalyser();
    this.analyser.connect(audioContext.destination);
    this.analyser.smoothingTimeConstant = opts?.smoothingTimeConstant ?? 1;
    this.analyser.fftSize = opts?.fftSize ?? 512;

    this.onTalkStart = opts?.onTalkStart;
    this.onTalkStop = opts?.onTalkStop;
    this.onLipStateChange = opts?.onLipStateChange;
  }

  install(audio: HTMLAudioElement, audioSource: MediaElementAudioSourceNode) {
    audioSource.connect(this.analyser);

    audio.addEventListener("play", this.onPlay);
    audio.addEventListener("pause", this.onPause);
    audio.addEventListener("error", this.onPause);
  }

  uninstall(audio: HTMLAudioElement, audioSource: MediaElementAudioSourceNode) {
    audio.removeEventListener("play", this.onPlay);
    audio.removeEventListener("pause", this.onPause);
    audio.removeEventListener("error", this.onPause);

    audioSource.disconnect(this.analyser);
  }

  loopId?: number = undefined;
  lipState: LipState = LipState.CLOSE;

  tick = () => {
    const newMouthState = this.freqToMouthState();

    if (newMouthState !== this.lipState) {
      this.lipState = newMouthState;
      this.onLipStateChange && this.onLipStateChange(newMouthState);
    }
  }

  freqToMouthState() {
    let data = new Uint8Array(this.analyser.frequencyBinCount);

    this.analyser.getByteTimeDomainData(data);
    const n = Math.max(...Array.from(data).map(n => (n / 256 - 0.5) * 2))

    if (n > 0.6) {
      return LipState.WIDE_OPEN;
    } else if (n > 0.3) {
      return LipState.OPEN;
    } else if (n > 0.15) {
      return LipState.NARROW_OPEN;
    } else {
      return LipState.CLOSE;
    }
  }

  onPlay = () => {
    if (!this.loopId) {
      this.loopId = window.setInterval(this.tick, 10);
      this.onTalkStart && this.onTalkStart();
    }
  }

  onPause = () => {
    if (this.loopId) {
      window.clearInterval(this.loopId);
      this.loopId = undefined;
      this.onTalkStop && this.onTalkStop();
    }
  }
}
