import React, {ComponentProps, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {formatDateTime, formatDurationHrs} from "../../../time";

import {reverse} from "../../../routing";

import cn from "classnames"
import Table from "../../../ui/table";
import {StarIcon, StarOutlineIcon} from "../../../ui/icons";
import {useHistory} from "react-router";
import {Conversation} from "../../../schema";

import classes from "./ConversationsTable.module.css";


export type QueryData = {
  conversations: Conversation[] | undefined
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  conversations: QueryData["conversations"]
}

const colors = [
  {
    id: 0,
    name: "blue"
  },
  {
    id: 1,
    name: "emerald"
  },
  {
    id: 2,
    name: "amber"
  },
  {
    id: 3,
    name: "red"
  },
  {
    id: 4,
    name: "cyan"
  },
  {
    id: 5,
    name: "lime"
  },
  {
    id: 6,
    name: "yellow"
  },
  {
    id: 7,
    name: "rose"
  },
  {
    id: 8,
    name: "violet"
  },
  {
    id: 9,
    name: "sky"
  },
  {
    id: 10,
    name: "teal"
  },
  {
    id: 11,
    name: "orange"
  },
]


export default function ConversationsTable({
  conversations, className, ...props
}: Props) {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const onRowClick = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id")!;
    history.push(reverse("serviceConversation", {id: id}));
  }, [history])

  let currentColor = 0;

  const audioPaths = conversations?.map(conversation => (
    conversation.audioFilepath
  ))

  const uniqueAudioPaths = Array.from(new Set(audioPaths));

  const linkedConvs = uniqueAudioPaths?.map((path) => {
    const linked = conversations?.filter(conv => conv.audioFilepath === path)

    if (linked && linked.length > 1) {
      currentColor = currentColor < 12 ? currentColor + 1 : 1;
      return linked.map(item => {
        return {
          "id": item.id,
          "color": colors.find(color => color.id === currentColor - 1)?.name ?? "inherit"
        }
      })
    }

    return {
      "id": linked![0].id,
      "color": "inherit"
    }
  })

  const flatLinked = linkedConvs.flatMap(item => item)

  return (
    <Table className={cn(classes.root, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell/>
          <Table.Cell>{t("components.ConversationsTable.timestamp")}</Table.Cell>
          <Table.Cell>{t("components.ConversationsTable.userEmail")}</Table.Cell>
          {/* <Table.Cell>{t("components.ConversationsTable.summary")}</Table.Cell> */}
          <Table.Cell>{t("components.ConversationsTable.duration")}</Table.Cell>
          <Table.Cell>{t("components.ConversationsTable.deviceName")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {conversations && conversations.map((conversation) => (
          <Table.Row
              data-id={conversation.id}
              key={conversation.id}
              className={
                cn(classes.pointer, `bg-${flatLinked?.find(item => item.id === conversation.id)?.color}-50`)
              }
              onClick={onRowClick}>
            <Table.Cell>
              {!conversation.isViewed ? (
                <span className={cn(classes.status, classes.new, classes.centerAlign)}>
                  {"NEW"}
                </span>
              ) : (
                <div className={classes.star}>
                  {conversation.isFavorite ? (
                    <StarIcon className={classes.filled}/>
                  ) : (
                    <StarOutlineIcon className={classes.outline}/>
                  )}
                </div>
              )}
            </Table.Cell>
            <Table.Cell>{formatDateTime(conversation.timestamp, i18n.language)}</Table.Cell>
            <Table.Cell>{conversation.username ?? ""}</Table.Cell>
            {/* <Table.Cell>
              <WithTooltip className={classes.help} as='span' helpText={conversation.summary}>
                <span className={classes.summary}>
                  {conversation.summary && conversation.summary.slice(0, 30) + "..."}
                </span>
              </WithTooltip>
            </Table.Cell> */}
            <Table.Cell>{formatDurationHrs(conversation.duration) ?? "unknown"}</Table.Cell>
            <Table.Cell>{conversation.deviceName}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

// ConversationsTable.fragments = {
//   root: gql`
//     fragment AudiosTable on ServiceAudio {
//       id
//       startedAt
//       analysisState
//       filepath
//       deviceName
//       userEmail
//     }
//   `,
// }
