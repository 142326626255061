import React, {ComponentProps, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {formatDuration} from "../../time";
import {getDictionaryColorById} from "../../utils";
import Table from "../../ui/table";
import IconButton from "../../ui/iconbutton";
import {CaretDown, TimeOutlineIcon} from "../../ui/icons";
import {Conversation, CustomDictionary} from "../../schema";

import cn from "classnames"
import classes from "./ConversationContent.module.css";


export type QueryData = {
  dialogue: Conversation | undefined
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  dialogue: QueryData["dialogue"],
  audioRef: React.MutableRefObject<HTMLAudioElement>,
  audioEmotionsLocales: {id: string, value: string}[],
  textEmotionsLocales: {id: string, value: string}[],
  customDicts?: CustomDictionary[] | undefined,
  segmentView?: "all" | "employee" | "customer",
}

export default function ConversationContent({
  dialogue, audioRef, audioEmotionsLocales, textEmotionsLocales, customDicts, segmentView, className, ...props
}: Props) {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState<string | undefined>(undefined);

  const speechSpeed = [
    {
      id: "slow",
      value: t("components.Conversation.speed.slow")
    },
    {
      id: "reasonable",
      value: t("components.Conversation.speed.reasonable")
    },
    {
      id: "fast",
      value: t("components.Conversation.speed.fast")
    }
  ]

  const expressiveness = [
    {
      id: "monotonic",
      value: t("components.Conversation.expressiveness.monotonic")
    },
    {
      id: "reasonable",
      value: t("components.Conversation.expressiveness.reasonable")
    },
    {
      id: "expressive",
      value: t("components.Conversation.expressiveness.expressive")
    }
  ]

  const onRowClick = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id")!;
    e.preventDefault()
    e.stopPropagation()
    const segment = dialogue?.segments.find(segment => segment.id === id)
    if (segment) {
      audioRef.current.currentTime = segment.startDuration;
    }
  }, [dialogue, audioRef])

  const onExpandDetails = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id")!;
    e.preventDefault()
    e.stopPropagation()
    if (expanded === id) {
      setExpanded(undefined)
    } else {
      setExpanded(id);
    }
  }, [expanded])

  useEffect(() => {
    setExpanded(undefined)
  }, [segmentView])

  const segments = dialogue?.segments;

  const uniqueCustomers = useMemo(() => (
    Array.from(new Set(segments!.filter(segment => segment.speaker !== dialogue?.mainSpeaker).map(segment => (
      segment.speaker
    ))))
  ), [dialogue, segments])

  const words = useMemo(() => (
    segments!.map(segment => (
      segment.text.split(" ").map((word, index) => (
        {
          segmentId: segment.id,
          index: index,
          word: word,
          color: getDictionaryColorById(
            customDicts?.find(
              dict => dict.id === segment.words[index].split(": ")[1])?.color ?? -1, t
            ).color
        }
      ))
    ))

  ), [customDicts, segments, t])

  return (
    <div>
      <Table className={cn(classes.root, className)} {...props}>
        <Table.Head>
          <Table.Row>
            <Table.Cell>{t("components.Conversation.speaker")}</Table.Cell>
            <Table.Cell>{t("components.Conversation.message")}</Table.Cell>
            <Table.Cell/>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {segments && segments.map((segment) => (
            <>
              {(segmentView === "all" ||
              (segment.speaker === dialogue.mainSpeaker && segmentView === "employee") ||
              (segment.speaker !== dialogue.mainSpeaker && segmentView === "customer")) && (

                <Table.Row key={segment.id}>
                  <Table.Cell key={`speaker_${segment.id}`}
                    data-id={segment.id}
                    className={cn(classes.pointer, classes.flexRow)}
                    onClick={onRowClick}
                  >
                    <div
                      className={segment.speaker === dialogue.mainSpeaker ? classes.employeeIcon: classes.clientIcon}
                    >
                      <span>{segment.speaker === dialogue.mainSpeaker
                        ? (t("components.Conversation.employee").charAt((0)))
                        : t("components.Conversation.customer").charAt((0))}
                      </span>
                    </div>
                    <span className="self-center ml-2">
                      {segment.speaker === dialogue.mainSpeaker
                        ? t("components.Conversation.employee")
                        : uniqueCustomers.length > 1
                          ? t("components.Conversation.customer") + " " + (
                              uniqueCustomers.indexOf(segment.speaker) + 1
                            ).toString()
                          : t("components.Conversation.customer")
                      }
                    </span>
                  </Table.Cell>
                  <Table.Cell
                    key={`content_${segment.id}`}
                    data-id={segment.id}
                    className={classes.pointer}
                    onClick={onRowClick}
                  >
                    {words.flat().filter(word => word.segmentId === segment.id).map((word, index) => (
                      <span
                        className={cn(
                          `bg-${word.color}-500`, word.color !== "inherit" && "text-white pl-1 pr-1 rounded-md"
                        )}
                      >
                        {index === 0 ? word.word.charAt(0).toUpperCase() + word.word.slice(1) + " " : word.word + " "}
                      </span>
                    ))}
                  </Table.Cell>
                  <Table.Cell

                    data-id={segment.id}
                    className={classes.pointer}
                    onClick={onRowClick}
                  >
                    <IconButton
                      data-id={segment.id}
                      className={cn(classes.toggleButton, {[classes.checked]: expanded === segment.id})}
                      size="s"
                      icon={CaretDown}
                      onClick={onExpandDetails}
                    />
                  </Table.Cell>
                </Table.Row>
              )}

            {segment.id === expanded && (
              <Table.Row className={classes.expanded} key={segment.id + "_exp"}>
                <Table.Cell
                  data-id={segment.id}
                  className={cn(classes.segmentTime, classes.pointer)}
                  onClick={onRowClick}
                >
                  <TimeOutlineIcon className="mr-2"/>
                  {formatDuration(segment.startDuration)}{" - "}{formatDuration(segment.finishDuration)}
                </Table.Cell>
                <Table.Cell>
                  <div>{t("components.Conversation.details.audioEmotion")}{": "}
                    <b>
                      {segment.audioEmotions.map(
                        emotion => " > " + audioEmotionsLocales.find(e => e.id === emotion.toLowerCase())?.value)}
                      </b>
                  </div>
                  <div>{t("components.Conversation.details.textEmotion")}{": "}
                    <b>
                      {segment.textEmotions.map(
                        emotion => " > " + textEmotionsLocales.find(e => e.id === emotion.toLowerCase())?.value)}
                      </b>
                  </div>
                  {segment.speaker === dialogue.mainSpeaker && (
                    <>
                      <div>{t("components.Conversation.details.speechSpeed")}{": "}
                        <b>{speechSpeed.find(key => key.id === segment.speechSpeed.toLowerCase())?.value}</b>
                      </div>
                      <div>{t("components.Conversation.details.pitchRange")}{": "}
                        <b>{expressiveness.find(key => key.id === segment.pitchRange.toLowerCase())?.value}</b>
                      </div>
                    </>
                  )}

                </Table.Cell>
                <Table.Cell/>
              </Table.Row>
            )}
            </>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
