/* istanbul ignore file */
import {generatePath} from "react-router";

const urls = {
  "home": "/",

  "signup": "/login/sign-up/",
  "login": "/login/",
  "serviceLogin": "/login/service/",
  "loginForgotPassword": "/login/forgot-password/",
  "loginResetPassword": "/login/reset_password/:hash/",
  "loginWithProvider": "/login/:provider/",
  "loginSelectAccount": "/login/account/",

  "editor": "/editor/",
  "editorLessonList": "/editor/",
  "editorLessonEdit": "/editor/:id/",
  "editorCourseEdit": "/editor/course/:id/",

  "player": "/play/",
  "playerPlay": "/play/:id/",
  "playerResults": "/play/:lessonId/results/",
  "playerUserResults": "/play/:lessonId/:userId/results/",
  "playerCourse": "/play/course/:id/",

  "accountMembers": "/account/members/",
  "accountMembersGroups": "/account/members/groups/",

  "analytics": "/analytics/",

  "service": "/service/",
  "serviceConversationsList": "/service/conversations/",
  "serviceConversation": "/service/conversations/:id/",
  "serviceAudios": "/service/audios/",
  "serviceDictionaries": "/service/dictionaries/",
  "serviceDivisions": "/service/divisions/",
  "serviceDevices": "/service/devices/",
  "serviceAnalytics": "/service/analytics/",
  "serviceDevicesDev": "/service/devices-dev/",

  "lms": "/lms",
  "lmsPlay": "/lms/play/:id/",
};


export function path(name: keyof typeof urls): string {
  if (!urls[name]) {
    throw Error(`UnknownRoute("${name}")`);
  }

  return urls[name];
}

type reverseParams = { [key: string]: string | number | boolean | undefined };

export function reverse(name: keyof typeof urls, params?: reverseParams) {
  return generatePath(path(name), params);
}

export function getLessonId() {
  const match = /\/play\/(\d+(?:_.+)?)(?:$|\/)/.exec(window.location.pathname);
  if (match) {
    return match[1];
  }
  return null;
}

export function getCourseId() {
  const match = /\/play\/course\/(\d+(?:_.+)?)(?:$|\/)/.exec(window.location.pathname);
  if (match) {
    return match[1];
  }
  return null;
}
