/* eslint-disable max-lines */
import {gql, useMutation} from "@apollo/client";

import {
  Division,
  DivisionInput,
} from "../../schema";

export type QueryData = {
  divisions: Division[]
}

export const query = gql`
  query GetDivisions {
    divisions: getDivisions {
      id
      parentId
      name
      users {
        id
        email
      }
    }
  }
`;

export function useAddDivisionMutation() {
  return useMutation<{
    division: Division
  }, {
    data: DivisionInput
  }>(addDivisionMutation);
}

const addDivisionMutation = gql`
  mutation AddDivisionMutation($data: DivisionInput!) {
    division: addDivision(data: $data) {
      id
      name
      parentId
      users {
        id
        name
      }
    }
  }
`;
