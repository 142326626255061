import {gql, useMutation} from "@apollo/client";
import {MutationHookOptions} from "@apollo/client/react/types/types";
import {loginUpdateCache} from "./common.graphql";
import {AppAccountFragment, AppUserFragment} from "../../App.graphql";
import {
  MutationServiceLoginArgs, MutationSignUpArgs, MutationTryInviteUserToLessonArgs, Scalars, SignUpStatus
} from "../../schema";

export function useLoginMutation(opts?: MutationHookOptions) {
  return useMutation(loginMutation, {
    ...opts,

    update: (cache, mutationResult) => {
      if (mutationResult.data?.login) {
        loginUpdateCache(cache, {
          user: mutationResult.data?.login.user,
          account: mutationResult.data?.login.account
        });
      }

      opts?.update && opts.update(cache, mutationResult, {});
    }
  });
}

export const loginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      sessionId
      account { id ...AppAccount}
      user { id ...AppUser }
    }
  }

  ${AppUserFragment}
  ${AppAccountFragment}
`;

export function useSignUpMutation() {
  return useMutation<{
    status: SignUpStatus
  }, MutationSignUpArgs>(signUpMutation);
}

const signUpMutation = gql`
  mutation SignUpMutation(
    $username: String!,
    $fullname: String!,
    $password: String!,
    $repeatPassword: String!,
    $languageCode: LanguageCode!,
    $accountId: ID,
    $isDemo: Boolean,
  ) {
    status: signUp(
      username: $username,
      fullname: $fullname,
      password: $password,
      repeatPassword: $repeatPassword,
      languageCode: $languageCode,
      accountId: $accountId,
      isDemo: $isDemo,
    )
  }
`;

export function useServiceLoginMutation() {
  return useMutation<{
    status: Boolean
  }, MutationServiceLoginArgs>(serviceLoginMutation);
}

const serviceLoginMutation = gql`
  mutation serviceLoginMutation(
    $email: String!,
    $password: String!,
    $deviceId: String!,
  ) {
    status: serviceLogin(
      email: $email,
      password: $password,
      deviceId: $deviceId,
    )
  }
`;

export function useTryInviteUserToLessonMutation() {
  return useMutation<{
    accountId: Scalars["ID"];
  }, MutationTryInviteUserToLessonArgs>(tryInviteUserToLessonMutation);
}

const tryInviteUserToLessonMutation = gql`
  mutation TryInviteUserToLessonMutation($lessonId: ID!, $accessKey: String!) {
    accountId: tryInviteUserToLesson(lessonId: $lessonId, accessKey: $accessKey)
  }
`;