import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import query, {QueryData} from "./audios.graphql";

import ReactLink from "../../components/ReactLink";
import {reverse} from "../../routing";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import AudiosTable from "../../components/service/audios-table/AudiosTable";
import NavigationMenu from "../../components/service/NavigationMenu";

import classes from "./index.module.css";

export default function AudiosScene() {
  const {t} = useTranslation();

  const {data, loading: fetching} = useQuery<QueryData>(query);
  const {audios} = data ?? {};
  const loading = !data && fetching;

/* eslint-disable max-len */
//     const audioMock = [
//       {
//         id: "1",
//         userEmail: "test@cerevrum.com",
//         deviceName: "test device",
//         startedAt: Date.parse("2024-01-01 00:00:00") / 1000,
//         filepath: "https://storage.yandexcloud.net/audio-temp/41c1f9b5-9541-4017-ae27-41713b0df051_24-26-01-16-00-28.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=YCAJEESYYUTd2Is9Wugzkue_5%2F20240208%2Fru-central1%2Fs3%2Faws4_request&X-Amz-Date=20240208T142628Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=ffdfd8bcd986e5d8c9459cc5e48fcbbc132c1f4060b94a3c9bae2af8189ac58a",
//         duration: undefined,
//         analysisState: AudioAnalysisState.READY
//       }
//     ]
/* eslint-enable max-len */

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <ReactLink href={reverse("service")}>{t("breadcrumbs.service.main")}</ReactLink>
              <span>{t("breadcrumbs.service.audios")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.audios.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                {!loading && (
                  <AudiosTable
                    audios={audios}
                  />
                )}
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
