import React, {ComponentProps} from "react";
import {gql} from "@apollo/client";
import {Group, User} from "../../schema";

import Skeleton from "../../ui/skeleton";

import cn from "classnames";
import classes from "./UserItem.module.css";

type PickedUser = Pick<User, "fullName" | "name" | "email"> & {
  groups: Pick<Group, "id" | "name">[]
}

export type Props = ComponentProps<"div"> & {
  user: PickedUser
}

export default function UserItem({className, user, ...props}: Props) {
  const {fullName, name, email, groups} = user;
  return (
    <div className={cn(classes.root, className)} {...props}>
      <b>{fullName}</b>
      {!!name && !!email && (
        <span className={classes.email}>{email}</span>
      )}
      {groups && (
        <span className={classes.groupsWrapper}>
          {groups.map(({name, id}) => (
            <span className={classes.group} key={id}>{name}</span>
          ))}
        </span>
      )}
    </div>
  )
}

UserItem.Skeleton = React.memo(function ({className, ...props}: React.ComponentProps<typeof Skeleton.Block>) {
  return (
    <Skeleton.Block
      className={cn(classes.root, className)}
      width={200}
      height={40}
      {...props}
    />
  )
})

UserItem.fragments = {
  root: gql`
    fragment UserItem on User {
      fullName
      name
      email
      groups {
        id
        name
      }
    }
  `
}
