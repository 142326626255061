import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Content from "./Content";
import {LessonInputMode, LessonMode, UserInputAction} from "../../../../schema";
import {PlayerReduxState, UserInputDisplayMode} from "../../../../redux/player/types";
import Phrase, {PhraseVariant} from "../../../Phrase";
import UserInputHelpText from "../../UserInputHelpText";
import {useDispatch, useSelector} from "react-redux";
import {getLesson} from "../../../../redux/player/selectors";
import selectInput from "../../../../redux/player/actions/selectInput";
import {isEqual} from "lodash";


type UserInputActionContentProps = Omit<React.ComponentProps<typeof Content>, "title" | "variant" | "children"> & {
  action: UserInputAction;
  phraseMode: PhraseVariant | undefined;
  displayMode: UserInputDisplayMode;
}

const UserInputActionContent = ({action, phraseMode, displayMode, ...props}: UserInputActionContentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const lesson = useSelector(UserInputActionContent.selector, isEqual);
  const isInputSelectable =
    lesson.mode === LessonMode.QUIZ ||
    lesson.mode === LessonMode.CHOICES_WITH_CUSTOM_SCORES ||
    lesson.mode === LessonMode.CUSTOM_PARAMETERS_TEST;
  const [isClickRegistered, registerClick] = useState(false);
  const isTextInput = lesson.inputMode === LessonInputMode.TEXT;

  const onContentClick = useCallback(() => {
    const branchIndex = 0;
    if (isInputSelectable && !isClickRegistered) {
      registerClick(true);
      dispatch(selectInput(branchIndex));
    }
  }, [dispatch, isInputSelectable, isClickRegistered])

  const displayVariant = useMemo(() => {
    if (isTextInput) {
      if (isInputSelectable) {
        return UserInputDisplayMode.TEXT_INPUT_SELECTABLE
      } else {
        return UserInputDisplayMode.TEXT_INPUT
      }
    } else {
      if (isInputSelectable) {
        return UserInputDisplayMode.SELECTABLE
      } else {
        return displayMode
      }
    }
  }, [isTextInput, isInputSelectable, displayMode])

  return (
    <Content
      title={
        <UserInputHelpText
          action={action}
          displayMode={displayVariant ?? displayMode}
        />
      }
      onClick={onContentClick}
      variant={displayMode === UserInputDisplayMode.EXPECTED_TEXT ? "user" : "userHint"}
      {...props}
    >
      {action.freeSpeech && (
        <span>{t("player.userInputActionFreeSpeechTheme")}</span>
      )}

      {displayMode === UserInputDisplayMode.EXPECTED_TEXT ? (
        <Phrase
          text={
            action.expectedText
            .replace("<entername>", t("player.enterName"))
            .replace("<name>", t("player.sessionUserName"))
          }
          variant={phraseMode ?? PhraseVariant.DEFAULT}
        />
      ) : (
        <Phrase
          text={action.hintText}
          variant={PhraseVariant.DEFAULT}
        />
      )}
    </Content>
  )
};

export default UserInputActionContent;

UserInputActionContent.selector = (state: PlayerReduxState) => {
  return getLesson(state)
}
