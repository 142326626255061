import {ComponentProps, useMemo} from "react";
import {isEqual} from "lodash";
import {useTranslation} from "react-i18next";
import {gql} from "@apollo/client";

import ReactLink from "../../ReactLink";
import {CustomDictionary} from "../../../schema";
import Table from "../../../ui/table";

import cn from "classnames"
import classes from "./CustomDictsTable.module.css";


export type QueryData = {
  dicts: CustomDictionary[] | undefined
  onRowClick: (e: React.MouseEvent) => void
}

export type Props = Omit<ComponentProps<typeof Table>, "children"> & {
  dicts: QueryData["dicts"]
  onRowClick: QueryData["onRowClick"]
}

export default function CustomDictsTable({
  dicts, onRowClick, className, ...props
}: Props) {
  const {t} = useTranslation();

  const choices = useMemo(() => ([
    {
      value: 0,
      label: t("types.TailwindColor.slate"),
      color: "slate",
    },
    {
      value: 1,
      label: t("types.TailwindColor.red"),
      color: "red",
    },
    {
      value: 2,
      label: t("types.TailwindColor.orange"),
      color: "orange",
    },
    {
      value: 3,
      label: t("types.TailwindColor.yellow"),
      color: "yellow"
    },
    {
      value: 4,
      label: t("types.TailwindColor.lime"),
      color: "lime"
    },
    {
      value: 5,
      label: t("types.TailwindColor.green"),
      color: "green"
    },
    {
      value: 6,
      label: t("types.TailwindColor.teal"),
      color: "teal",
    },
    {
      value: 7,
      label: t("types.TailwindColor.cyan"),
      color: "cyan",
    },
    {
      value: 8,
      label: t("types.TailwindColor.blue"),
      color: "blue",
    },
    {
      value: 9,
      label: t("types.TailwindColor.indigo"),
      color: "indigo",
    },
    {
      value: 10,
      label: t("types.TailwindColor.purple"),
      color: "purple",
    },
    {
      value: 11,
      label: t("types.TailwindColor.fuchsia"),
      color: "fuchsia",
    },
    {
      value: 12,
      label: t("types.TailwindColor.pink"),
      color: "pink",
    },

  ]), [t]);


  return (
    <Table className={cn(classes.root, className)} {...props}>
      <Table.Head>
        <Table.Row>
          <Table.Cell>{t("components.CustomDictsTable.name")}</Table.Cell>
          <Table.Cell>{t("components.CustomDictsTable.color")}</Table.Cell>
          <Table.Cell>{t("components.CustomDictsTable.content")}</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {dicts && dicts.map((dict) => (
          <Table.Row data-id={dict.id} key={dict.id} className={classes.pointer} onClick={onRowClick}>
            <Table.Cell>
              <ReactLink
                className={classes.groupName}
              >{dict.name}</ReactLink>
            </Table.Cell>
            <Table.Cell>
              <div className={cn(
                classes.color, `bg-${choices.find(choice => isEqual(choice.value, dict.color))!.color}-500`
              )}>
              </div>
            </Table.Cell>
            <Table.Cell>
              <span className={classes.summary}>
                {dict.words?.slice(0, 30) + (dict.words && dict.words?.length > 30 ? "..." : "")}
              </span>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

CustomDictsTable.fragments = {
  root: gql`
    fragment CustomDictsTable on CustomDictionary {
      id
      name
      color
      words
    }
  `,
}
