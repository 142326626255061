/* eslint-disable max-lines */
import React, {useCallback, useMemo, useState} from "react";
import {clamp} from "lodash";
import {useTranslation} from "react-i18next";
import {useFormState} from "../../../hooks/useFormState";
import {
  ChoiceUserInputAction,
  ChoiceUserInputActionInput,
  ChoiceUserInputBranchInput,
  ChoiceUserInputBranchState,
  LessonScenarioActivationMethod,
  Scalars,
  Scenario,
  UserInputActionInput
} from "../../../schema";
import {gql} from "@apollo/client";
import {INPUT_PHRASE_MAX_LENGTH} from "../../../settings";
import {supportedPhraseTags} from "../../Phrase";

import PhrasesEdit from "../PhrasesEdit";
import LimitedTextarea from "../LimitedTextarea";
import Button from "../../../ui/button";
import WithTooltip from "../../../ui/tooltip";
import {HelpCircleIcon} from "../../../ui/icons";
import Link from "../../../ui/link";
import Select from "../../../ui/select";
import Input from "../../../ui/input";

import classes from "./forms.module.css";

const MIN = 0
const MAX = 100
const STEP = 1

type props = {
  action: ChoiceUserInputAction,
  branchId: Scalars["ID"],
  scenarios?: Scenario[],
  customScoreSupport?: boolean,
  customParametersSupport?: boolean,
  customParameters?: Scalars["String"][],
  onSubmit?: (data: ChoiceUserInputActionInput) => void,
  testId?: string
}

type formState = {
  state: NonNullable<ChoiceUserInputBranchInput["state"]>,
  customScore: NonNullable<ChoiceUserInputBranchInput["customScore"]>,
  customParameter: NonNullable<ChoiceUserInputBranchInput["customParameter"]>,
  expectedText: NonNullable<UserInputActionInput["expectedText"]>,
  analogTexts: NonNullable<UserInputActionInput["analogTexts"]>
  targetScenarioId: NonNullable<ChoiceUserInputBranchInput["targetScenarioId"]>,
}


export default function ChoiceUserInputActionEditForm(
  {action, branchId, scenarios, customScoreSupport, customParametersSupport, customParameters, onSubmit, testId}: props
) {
  const {t} = useTranslation();

  const branch = useMemo(() => {
    return action.branches.find(b => b.id === branchId)!;
  }, [action, branchId]);

  const formState = useFormState<formState>({
    initialValues: {
      state: branch.state ?? ChoiceUserInputBranchState.CORRECT,
      customScore: branch.customScore ?? 0,
      customParameter: branch.customParameter ?? "",
      expectedText: branch.userInput.rawExpectedText ?? "",
      analogTexts: branch.userInput.analogTexts,
      targetScenarioId: branch.targetScenarioId!,
    },
    preventDefault: true,
    onSubmit: (data) => {
      onSubmit && onSubmit({
        branches: action.branches.map(({id}) => {
          if (id === branchId) {
            return {
              id: id,
              state: data.state,
              customScore: data.customScore,
              customParameter: data.customParameter,
              targetScenarioId: data.targetScenarioId,
              expectedText: data.expectedText,
              analogTexts: data.analogTexts
            }
          }

          return {id}
        }),
      })
    }
  })

  const [analogTextsVisibility, setAnalogTextsVisibility] = useState(branch.userInput.analogTexts.length !== 0)
  const showAnalogTextsArea = useCallback(() => setAnalogTextsVisibility(true), [])

  const updatePhrases = useCallback((analogTexts: string[]) => {
    formState.setValues({analogTexts})
  }, [formState])

  const analogTextsTextareaProps = useMemo(() => ({
    maxLength: INPUT_PHRASE_MAX_LENGTH,
    rows: 4,
    autoComplete: "off",
    placeholder: t("components.ActionEditForm.analogTexts.placeholder")
  }), [t])

  const onScorePlus = useCallback(() => {
    formState.setValues({customScore: clamp(+formState.values.customScore! + STEP, MIN, MAX)})
  }, [formState])

  const onScoreMinus = useCallback(() => {
    formState.setValues({customScore: clamp(+formState.values.customScore! - STEP, MIN, MAX)})
  }, [formState]);


  return (
    <form data-testid={`ChoiceUserInputActionEditForm:${testId}`} method='post' onSubmit={formState.submitHandler}>

      <div className={classes.row}>
        {customParametersSupport && (
          <div className={classes.row}>
            <label
              className={classes.label}
              htmlFor="customParameter"
            >
              {t("components.ActionEditForm.choiceBranchParameter.title")}
              {" "}
              <WithTooltip
                className={classes.help} as='span'
                helpText={t("components.ActionEditForm.choiceBranchParameter.help")}
              >
                <HelpCircleIcon/>
              </WithTooltip>
            </label>
            <Select name="customParameter" value={formState.values.customParameter} onChange={formState.changeHandler}>
                <option value={""}>
                  {t("components.ActionEditForm.choiceBranchParameter.no")}
                </option>
              {customParameters?.map(parameter => (
                <option value={parameter}>
                  {parameter}
                </option>
              ))}
            </Select>
          </div>
        )}
        {customScoreSupport ? (
          <div className={classes.row}>
            <label
              className={classes.label}
              htmlFor="customScore"
            >
              {t("components.ActionEditForm.choiceBranchScore.title")}
              {" "}
              <WithTooltip
                className={classes.help} as='span'
                helpText={t("components.ActionEditForm.choiceBranchScore.help")}
              >
                <HelpCircleIcon/>
              </WithTooltip>
            </label>
            <Button className={classes.changeBtn} color='secondary' type='button' onClick={onScoreMinus}>-</Button>
            <Input
              style={{width: "70px", height: "40px", margin: "0 6px"}}
              required
              type='number'
              name='customScore'
              value={formState.values.customScore}
              onChange={formState.changeHandler}
            />
            <Button className={classes.changeBtn} color='secondary' type='button' onClick={onScorePlus}>+</Button>
          </div>
        ) : (
          <div className={classes.row}>
            <label
              className={classes.label}
              htmlFor="expectedText"
            >
              {t("components.ActionEditForm.choiceBranchState.title")}
              {" "}
              <WithTooltip
                className={classes.help} as='span'
                helpText={t("components.ActionEditForm.choiceBranchState.help")}
              >
                <HelpCircleIcon/>
              </WithTooltip>
            </label>
            <Select name="state" value={formState.values.state} onChange={formState.changeHandler}>
              <option value={ChoiceUserInputBranchState.CORRECT}>
                {t("types.ChoiceUserInputBranchState.CORRECT")}
              </option>
              <option value={ChoiceUserInputBranchState.INCORRECT}>
                {t("types.ChoiceUserInputBranchState.INCORRECT")}
              </option>
              <option value={ChoiceUserInputBranchState.INTERRUPT}>
                {t("types.ChoiceUserInputBranchState.INTERRUPT")}
              </option>
            </Select>
          </div>
        )}
      </div>

      <div className={classes.row}>
        <label
          className={classes.label}
          htmlFor="expectedText"
        >
          {t("components.ActionEditForm.choiceBranchTargetScenario.title")}
          {" "}
          <WithTooltip
            className={classes.help} as='span'
            helpText={t("components.ActionEditForm.choiceBranchTargetScenario.help")}
          >
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        {scenarios && scenarios.length > 1 ? (
          <Select name="targetScenarioId" value={formState.values.targetScenarioId} onChange={formState.changeHandler}>
            <option
              key={"none"}
              value={""}
            >{t("components.ActionEditForm.choiceBranchTargetScenario.no")}</option>
            {scenarios.map((scenario) => (
              !scenario.isMain && scenario.activationMethod === LessonScenarioActivationMethod.USER_INPUT && (
                <option
                  key={scenario.id}
                  value={scenario.id}
                >{scenario.name}</option>
              )
          ))}
          </Select>
        ) : t("components.ActionEditForm.choiceBranchTargetScenario.single")}
      </div>

      <div className={classes.row}>
        <label
          className={classes.label}
          htmlFor="expectedText"
        >
          {t("components.ActionEditForm.expectedText.title")}
          {" "}
          <WithTooltip
            className={classes.help} as='span'
            helpText={t("components.ActionEditForm.expectedText.help")}
          >
            <HelpCircleIcon/>
          </WithTooltip>
        </label>
        <LimitedTextarea
          maxLength={INPUT_PHRASE_MAX_LENGTH}
          filters={supportedPhraseTags}
          id="expectedText"
          name="expectedText"
          rows={4}
          value={formState.values.expectedText!}
          onChange={formState.changeHandler}
          placeholder={t("components.ActionEditForm.expectedText.placeholder")}
        />
      </div>

      {!analogTextsVisibility && (
        <div className={classes.row}>
          <Link onClick={showAnalogTextsArea}>
            {t("components.ActionEditForm.analogTexts.showArea")}
          </Link>
        </div>
      )}

      {analogTextsVisibility && (
        <>
          <div className={classes.row}>
            <label className={classes.label} htmlFor="analogTexts">
              {t("components.ActionEditForm.analogTexts.title")}{" "}
              <WithTooltip className={classes.help} as='span'
                helpText={t("components.ActionEditForm.analogTexts.help")}
              ><HelpCircleIcon/></WithTooltip>
            </label>
            <PhrasesEdit
              value={formState.values.analogTexts}
              onValueChange={updatePhrases}
              textareaProps={analogTextsTextareaProps}
            />
          </div>
        </>
      )}

      <div className={classes.row}>
        <Button>{t("common.save")}</Button>
      </div>
    </form>
  )
}


ChoiceUserInputActionEditForm.fragments = {
  root: gql`
    fragment ChoiceUserInputActionEditForm on ChoiceUserInputAction {
      id
      branches {
        state
        customScore
        customParameter
        targetScenarioId

        userInput {
          id
          expectedText
          rawExpectedText
          hintText
          analogTexts
        }

        avatarReaction {
          id
          text
          audio
          isCustomAudio
          onlyFirstLevel
          emotion
          gesture
          media
          mediaType
          mediaViewRequired
        }

        systemReaction {
          id
          text
          audio
          isCustomAudio
          onlyFirstLevel
          media
          mediaType
          mediaViewRequired
        }
      }
    }
  `
}
