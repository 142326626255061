/* eslint-disable max-lines */
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";

import {formatDate} from "../../time";
import {Card, LineChart, Title} from "@tremor/react";
import ReactDocumentTitle from "../../components/ReactDocumentTitle";
import Layout, {Header, Main} from "../../components/Layout";
import Container from "../../ui/container";
import Breadcrumbs from "../../ui/breadcrumbs";
import NavigationMenu from "../../components/service/NavigationMenu";

import {QueryGetServiceCommonStatsArgs} from "../../schema";
import query, {QueryData} from "./index.graphql";
import classes from "./index.module.css";


export default function ServiceScene() {
  const {t, i18n} = useTranslation();

  const {data, loading: fetching} = useQuery<QueryData, QueryGetServiceCommonStatsArgs>(query, {
    variables: {
      daysAmount: 7
    }
  });
  const {stats} = data ?? {};
  const loading = !data && fetching;

  const chartData = useMemo(() => {
    if (!stats) {
      return [];
    }
    let data = stats.map(stat => {
      return {
        "date": formatDate(stat.date, i18n.language),
        "count": stat.dialogsCount
      }
    })

    return data;
  }, [stats, i18n.language])

  return (
    <>
      <ReactDocumentTitle title={t("service.main.title")}/>
      <Layout>
        <Header/>
        <Main>
          <Container className={classes.main}>
            <Breadcrumbs>
              <span>{t("breadcrumbs.service.main")}</span>
            </Breadcrumbs>
            <div className={classes.header}>
              <h1 className={classes.h1}>{t("service.main.h1")}</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentMain}>
                <Card>
                  <Title>{t("service.main.dialogsCount")}</Title>
                  {!loading && (
                    <LineChart
                      className="mt-6"
                      data={chartData}
                      index="date"
                      categories={["count"]}
                      colors={["violet"]}
                      yAxisWidth={40}
                      showLegend={false}
                    />
                  )}
                </Card>
              </div>
              <div className={classes.contentAside}>
                <NavigationMenu/>
              </div>
            </div>
          </Container>
        </Main>
      </Layout>
    </>
  )
}
