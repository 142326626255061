import {gql} from "@apollo/client";
import {ServiceCommonStats} from "../../schema";

export type QueryData = {
  stats: ServiceCommonStats[]
}

const query = gql`
  query GetServiceCommonStats($daysAmount: Int!) {
    stats: getServiceCommonStats(daysAmount: $daysAmount) {
      date
      dialogsCount
    }
  }
`;

export default query;
