import {ComponentProps, useMemo} from "react";
import {useFormState} from "../../../hooks/useFormState";
import {useTranslation} from "react-i18next";
import {Device} from "../../../schema";

import Input from "../../../ui/input";
import Button from "../../../ui/button";

import classes from "./RenameDeviceModal.module.css";

export type QueryData = {
  device: Pick<Device, "name">
  devices?: Pick<Device, "name">[]
}

export type ContentProps = Omit<ComponentProps<"form">, "children" | "onSubmit"> & QueryData & {
  onRenameDevice?: (name: string) => void
}

function RenameDeviceModalHeader() {
  const {t} = useTranslation();

  return <span>{t("components.RenameDeviceModal.title")}</span>
}

 function RenameDeviceModalContent({device, devices, onRenameDevice, ...props}: ContentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    deviceName: string,
  }>({
    initialValues: {
      deviceName: device.name ?? "",
    },

    preventDefault: true,
    onSubmit: ({deviceName}) => {
      onRenameDevice && onRenameDevice(deviceName);
    }
  });

  const nameChanged = formState.values.deviceName !== device.name;

  const haveDeviceWithSameName = useMemo(() => {
    if (!devices) {
      return undefined;
    }
    return devices.some(({name}) => formState.values.deviceName === name)
  }, [devices, formState.values.deviceName]);

  const submitDisabledByName = haveDeviceWithSameName || !nameChanged;

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler} {...props}>
      <p className={classes.helpText}>{t("components.RenameGroupModal.helpText", {name: device.name})}</p>
      <Input
        className={classes.input}
        name="deviceName"
        required
        value={formState.values.deviceName}
        placeholder={t("components.RenameGroupModal.placeholder")}
        onChange={formState.changeHandler}
      />

      {nameChanged && haveDeviceWithSameName && (
        <p className={classes.warning}>{t("components.RenameGroupModal.warning.sameName")}</p>
      )}

      <div className={classes.bottom}>
        <Button type="submit" color="success" disabledColor="secondary"
          disabled={submitDisabledByName}>
          {t("components.RenameGroupModal.submit")}
        </Button>
      </div>
    </form>
  )
}

const RenameDeviceModal = {
  Header: RenameDeviceModalHeader,
  Content: RenameDeviceModalContent,
}

export default RenameDeviceModal;
